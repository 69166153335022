import styled from 'styled-components';

export const Container = styled.div`
  .sidebar-wrapper {
    min-width: 149px;
    height: 100vh;
    background: #262262;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
  }

  .content-wrapper {
    width: calc(100vw - 149px);
    margin-left: 149px;
    position: relative;
  }
`;
