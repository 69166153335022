import {
  GET_WALLET_MERCHANT_IS_LOADING,
  GET_WALLET_MERCHANT_SUCCESS,
  GET_WALLET_DRIVER_IS_LOADING,
  GET_WALLET_DRIVER_SUCCESS,
  GET_WALLET_BALANCE_MERCHANT_IS_LOADING,
  GET_WALLET_BALANCE_MERCHANT_SUCCESS,
  COLLECTION_WALLET_BALANCE_IS_LOADING,
  COLLECTION_WALLET_BALANCE_SUCCESS,
  ORDER_TRANSACTION_LOADING,
  ORDER_TRANSACTION_SUCCESS,
  GET_TOTAL_ORDERS_SUCCESS,
  GET_TOTAL_ORDERS_LOADING,
  LOAD_ORDER_DETAILS_SUCCESS,
  LOAD_ORDER_DETAILS_SUCCESS_IS_LOADING,
  PRODUCT_SOLD,
  PRODUCT_SOLD_ISLOADING,
  FINANCE_METRICS,
  PRODUCT_PAYOUT_METRICS,
  COST_OF_ORDER_CREATED,
  ORDER_COUNT,
  GET_TASK_TRANSACTION,
  GET_TASK_TRANSACTION_IS_LOADING,
  FETCH_USER_WALLET_DETAILS,
  FETCH_USER_WALLET_DETAILS_IS_LOADING,
  FETCH_USER_WALLET_DETAILS_ERROR,
  FETCH_USER_TRANSACTION,
  FETCH_USER_TRANSACTION_IS_LOADING,
  FETCH_USER_TRANSACTION_ERROR,
  GET_DRIVER_PAYOUT,
  GET_DRIVER_PAYOUT_IS_LOADING,
  // GET_DRIVER_PAYOUT_ERROR,
  DRIVER_RIDE_HISTORY,
  DRIVER_RIDE_HISTORY_IS_LOADING,
  DRIVER_RIDE_HISTORY_ERROR,
  DRIVER_RIDE_COUNT,
  // DRIVER_RIDE_COUNT_IS_LOADING,
  DRIVER_RIDE_COUNT_ERROR,
  MERCHANT_ORDER_TRANSACTION,
  MERCHANT_ORDER_TRANSACTION_IS_LOADING,
  MERCHANT_ORDER_TRANSACTION_ERROR,
  MERCHANT_SALES,
  MERCHANT_SALES_IS_LOADING,
  MERCHANT_SALES_ERROR,
  MERCHANT_SUB_TOTAL_COST_ORDER,
  MERCHANT_SUB_TOTAL_COST_ORDER_IS_LOADING,
  MERCHANT_SUB_TOTAL_COST_ORDER_ERROR,
  MERCHANT_COST_OF_SALES,
  MERCHANT_COST_OF_SALES_IS_LOADING,
  MERCHANT_COST_OF_SALES_ERROR,
  MERCHANT_ORDER_COST_OF_SALES,
  MERCHANT_ORDER_TOTAL_DELIVERY_COST,
  MERCHANT_ORDER_COUNT,
  SELLER_PAYOUT,
  SELLER_COMMISSION,
  SELLER_SALES_HISTORY,
  SELLER_SALES_HISTORY_IS_LOADING,
  SELLER_SALES_HISTORY_ERROR,
  GET_FINANCE_TABS_COUNT
} from 'store/actions/types';

const initialState = {
  merchantWallets: [],
  driverWallets: [],
  driverWalletsLoading: false,
  merchantWalletLoading: false,
  walletBalance: {},
  walletBalanceLoading: false,
  collectionWallet: {},
  collctionWalletLoading: false,
  orderTransactions: [],
  orderTransactionsLoading: false,
  totalOrders: {},
  totalOrdersLoading: false,
  orderDetails: {},
  orderDetailsIsLoading: false,
  allProductsSold: [],
  getAllProductsIsLoading: false,
  commissionMetrics: {},
  productMetrics: {},
  costOfOrder: {},
  orderCount: {},
  rideTransactions: {},
  rideTransactionIsLoading: false,
  userWalletDetails: {},
  userWalletDetailsIsLoading: false,
  userWalletDetailsError: null,
  userTransactions: null,
  userTransactionsIsLoading: false,
  userTransactionsError: null,
  driverRideCount: 0,
  driverRideCountIsLoading: false,
  driverRideCountError: null,
  driverRideHistory: null,
  driverRideHistoryIsLoading: false,
  driverRideHistoryError: null,
  driverPayout: 0,
  driverPayoutIsLoading: false,
  driverPayoutError: null,
  merchantOrderTransactionData: null,
  merchantOrderTransactionIsLoading: false,
  merchantOrderTransactionError: null,
  merchantSales: null,
  merchantSalesIsLoading: false,
  merchantSalesError: null,
  merchantOrderSubTotal: null,
  merchantOrderSubTotalIsLoading: false,
  merchantOrderSubTotalError: null,
  merchantTotalCostOfSaleSeller: null,
  merchantTotalCostOfSaleSellerIsLoading: false,
  merchantTotalCostOfSaleSellerError: null,
  merchantOrderTotalSalesCost: null,
  merchantOrderTotalDeliveryCost: null,
  merchantOrderCount: null,
  merchantSellerPayout: null,
  merchantSellerCommission: null,
  merchantSalesHistory: null,
  merchantSalesHistoryIsLoading: false,
  merchantSalesHistoryError: null,
  tabsCount: {}
};

const financeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WALLET_MERCHANT_SUCCESS:
      return {
        ...state,
        merchantWallets: action.data,
        merchantWalletLoading: false
      };
    case GET_WALLET_MERCHANT_IS_LOADING:
      return {
        ...state,
        merchantWalletLoading: true
      };
    case GET_WALLET_DRIVER_SUCCESS:
      return {
        ...state,
        driverWallets: action.driverData,
        driverWalletsLoading: false
      };
    case GET_WALLET_DRIVER_IS_LOADING:
      return {
        ...state,
        driverWalletsLoading: true
      };
    case GET_WALLET_BALANCE_MERCHANT_SUCCESS:
      return {
        ...state,
        walletBalance: action.merchantWalletData,
        walletBalanceLoading: false
      };
    case GET_WALLET_BALANCE_MERCHANT_IS_LOADING:
      return {
        ...state,
        walletBalanceLoading: true
      };
    case COLLECTION_WALLET_BALANCE_IS_LOADING:
      return {
        ...state,
        collctionWalletLoading: true
      };
    case COLLECTION_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        collectionWallet: action.walletData,
        collctionWalletLoading: false
      };
    case ORDER_TRANSACTION_SUCCESS:
      return {
        ...state,
        orderTransactions: action.orderTrxData,
        orderTransactionsLoading: false
      };
    case ORDER_TRANSACTION_LOADING:
      return {
        ...state,
        orderTransactionsLoading: true
      };
    case GET_TOTAL_ORDERS_LOADING:
      return {
        ...state,
        totalOrdersLoading: true
      };
    case GET_TOTAL_ORDERS_SUCCESS:
      return {
        ...state,
        totalOrdersLoading: false,
        totalOrders: action.dt
      };
    case LOAD_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: action.orderDt,
        orderDetailsIsLoading: false
      };
    case LOAD_ORDER_DETAILS_SUCCESS_IS_LOADING:
      return {
        ...state,
        orderDetailsIsLoading: true
      };
    case PRODUCT_SOLD:
      return {
        ...state,
        allProductsSold: action.soldProducts,
        getAllProductsIsLoading: false
      };
    case PRODUCT_SOLD_ISLOADING:
      return {
        ...state,
        getAllProductsIsLoading: true
      };
    case FINANCE_METRICS:
      return {
        ...state,
        commissionMetrics: action.commissionMetrics
      };
    case PRODUCT_PAYOUT_METRICS:
      return {
        ...state,
        productMetrics: action.productMetrics
      };
    case COST_OF_ORDER_CREATED:
      return {
        ...state,
        costOfOrder: action.orderCost
      };
    case ORDER_COUNT:
      return {
        ...state,
        orderCount: action.orderCount
      };
    case GET_TASK_TRANSACTION:
      return {
        ...state,
        rideTransactions: action.rideTransaction,
        rideTransactionIsLoading: false
      };
    case GET_TASK_TRANSACTION_IS_LOADING:
      return {
        ...state,
        rideTransactionIsLoading: true
      };
    case FETCH_USER_WALLET_DETAILS:
      return {
        ...state,
        userWalletDetailsIsLoading: false,
        userWalletDetails: action.userWalletDetails
      };
    case FETCH_USER_WALLET_DETAILS_IS_LOADING:
      return {
        ...state,
        userWalletDetailsIsLoading: true
      };
    case FETCH_USER_WALLET_DETAILS_ERROR:
      return {
        ...state,
        userWalletDetailsIsLoading: false
      };
    case FETCH_USER_TRANSACTION:
      return {
        ...state,
        userTransactionsIsLoading: false,
        userTransactions: action.userTransaction
      };
    case FETCH_USER_TRANSACTION_IS_LOADING:
      return {
        ...state,
        userTransactionsIsLoading: true
      };
    case FETCH_USER_TRANSACTION_ERROR:
      return {
        ...state,
        userTransactionsIsLoading: false,
        userTransactionsError: action.userTransactionError
      };
    case GET_DRIVER_PAYOUT:
      return {
        ...state,
        driverPayout: action.driverPayout,
        driverPayoutIsLoading: false,
        driverPayoutError: null
      };
    case GET_DRIVER_PAYOUT_IS_LOADING:
      return {
        ...state,
        driverPayoutIsLoading: true,
        driverPayoutError: null
      };
    case DRIVER_RIDE_COUNT_ERROR:
      return {
        ...state,
        driverPayoutIsLoading: false,
        driverPayoutError: action.driverPayoutError
      };
    case DRIVER_RIDE_HISTORY:
      return {
        ...state,
        driverRideHistory: action.rideHistory,
        driverRideHistoryIsLoading: false,
        driverRideHistoryError: null
      };
    case DRIVER_RIDE_HISTORY_IS_LOADING:
      return {
        ...state,
        driverRideHistoryIsLoading: true,
        driverRideHistoryError: null
      };
    case DRIVER_RIDE_HISTORY_ERROR:
      return {
        ...state,
        driverRideHistoryIsLoading: false,
        driverRideHistoryError: action.rideHistoryError
      };
    case DRIVER_RIDE_COUNT:
      return {
        ...state,
        driverRideCount: action.rideCount,
        driverRideCountIsLoading: false,
        driverRideCountError: null
      };
    case MERCHANT_ORDER_TRANSACTION:
      return {
        ...state,
        merchantOrderTransactionData: action.orderTransaction,
        merchantOrderTransactionIsLoading: false,
        merchantOrderTransactionError: null
      };
    case MERCHANT_ORDER_TRANSACTION_IS_LOADING:
      return {
        ...state,
        merchantOrderTransactionIsLoading: true
      };
    case MERCHANT_ORDER_TRANSACTION_ERROR:
      return {
        ...state,
        merchantOrderTransactionIsLoading: false,
        merchantOrderTransactionError: action.orderTransactionError
      };
    case MERCHANT_SALES:
      return {
        ...state,
        merchantSales: action.merchantSales,
        merchantSalesIsLoading: false
      };
    case MERCHANT_SALES_IS_LOADING:
      return {
        ...state,
        merchantSalesIsLoading: true
      };
    case MERCHANT_SALES_ERROR:
      return {
        ...state,
        merchantSalesIsLoading: false,
        merchantSalesError: action.merchantSalesError
      };
    case MERCHANT_SUB_TOTAL_COST_ORDER:
      return {
        ...state,
        merchantOrderSubTotal: action.orderSubTotalCost,
        merchantOrderSubTotalIsLoading: false,
        merchantOrderSubTotalError: null
      };
    case MERCHANT_SUB_TOTAL_COST_ORDER_IS_LOADING:
      return {
        ...state,
        merchantOrderSubTotalIsLoading: true
      };
    case MERCHANT_SUB_TOTAL_COST_ORDER_ERROR:
      return {
        ...state,
        merchantOrderSubTotalIsLoading: false,
        merchantOrderSubTotalError: action.orderSubTotalCostError
      };
    case MERCHANT_COST_OF_SALES:
      return {
        ...state,
        merchantTotalCostOfSaleSeller: action.merchantTotalCost,
        merchantTotalCostOfSaleSellerIsLoading: false
      };
    case MERCHANT_COST_OF_SALES_IS_LOADING:
      return {
        ...state,
        merchantTotalCostOfSaleSellerIsLoading: true
      };
    case MERCHANT_COST_OF_SALES_ERROR:
      return {
        ...state,
        merchantTotalCostOfSaleSellerIsLoading: false,
        merchantTotalCostOfSaleSellerError: action.merchantTotalCostError
      };
    case MERCHANT_ORDER_COST_OF_SALES:
      return {
        ...state,
        merchantOrderTotalSalesCost: action.merchantOrderTotalCost
      };
    case MERCHANT_ORDER_TOTAL_DELIVERY_COST:
      return {
        ...state,
        merchantOrderTotalDeliveryCost: action.orderTotalDeliveryCost
      };
    case MERCHANT_ORDER_COUNT:
      return {
        ...state,
        merchantOrderCount: action.orderCount
      };
    case SELLER_PAYOUT:
      return {
        ...state,
        merchantSellerPayout: action.sellerPayout
      };
    case SELLER_COMMISSION:
      return {
        ...state,
        merchantSellerCommission: action.sellerCommission
      };
    case SELLER_SALES_HISTORY:
      return {
        ...state,
        merchantSalesHistory: action.sellerHistory,
        merchantSalesHistoryIsLoading: false
      };
    case SELLER_SALES_HISTORY_IS_LOADING:
      return {
        ...state,
        merchantSalesHistoryIsLoading: true
      };
    case SELLER_SALES_HISTORY_ERROR:
      return {
        ...state,
        merchantSalesHistoryError: action.sellerHistoryError,
        merchantSalesHistoryIsLoading: false
      };
    case GET_FINANCE_TABS_COUNT: {
      return {
        ...state,
        tabsCount: {
          ...state.tabsCount,
          [action.payload.name]: action.payload.value
        }
      };
    }
    default:
      return state;
  }
};

export default financeReducer;
