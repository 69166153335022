import AddImageCard from 'components/AddImageCard';
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createSubcategory, exit } from 'store/actions/products';
import DotsLoader from 'components/Loaders/DotsLoader';
import { Link, Router, useLocation, useHistory } from 'react-router-dom';
import ToggleButton from 'components/ToggleButton';

const CreateSubcategory = () => {
  const [openModal, setOpenModal] = useState(false);
  const [property, setProperty] = useState({ name: '' });
  const [propertyError, setPropertyError] = useState('');
  let history = useHistory();
  let location = useLocation();
  let formData = new FormData();
  const dispatch = useDispatch();
  const { categoryId, creatingCategory } = useSelector(
    state => state.productsReducer
  );
  const [data, setData] = useState({
    categoryName: '',
    image: null,
    properties: [],
    isVisibleToAll: false
  });

  const handleNameChange = e => {
    setData(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      };
    });
  };
  const handleProperty = e => {
    setPropertyError('');
    setProperty({ name: e.target.value });
  };

  const handleVisibilityToggle = val => {
    setData(prev => {
      return {
        ...prev,
        isVisibleToAll: val
      };
    });
  };

  const categoryNameKeyUp = e => {
    const r = /[^a-zA-Z0-9-]/g;

    setData(prev => {
      return {
        ...prev,
        categoryName: e.target.value.replace(r, '')
      };
    });
  };

  const propertyNameKeyDown = e => {
    const r = /[^a-zA-Z0-9-]/g;

    setProperty({ name: e.target.value.replace(r, '') });
  };

  const setImage = img => {
    setData(prev => {
      return {
        ...prev,
        image: img
      };
    });
  };

  const addProperty = () => {
    if (property.name === '') {
      setPropertyError('Property name cannot be empty');
      return;
    }
    setData(prev => {
      if (
        prev.properties.some(
          prop => prop.name.toLowerCase() === property.name.toLowerCase()
        )
      ) {
        setPropertyError('Property name already exist');

        return {
          ...prev
        };
      }
      return {
        ...prev,
        properties: [...prev.properties, property]
      };
    });
    setProperty({ name: '' });
  };

  const handleSubmit = async () => {
    formData.append('categoryName', data.categoryName);
    formData.append('image', data.image);
    formData.append('parentId', categoryId);
    formData.append('properties', JSON.stringify(data.properties));
    formData.append('isVisibleToAll', data.isVisibleToAll);

    await dispatch(createSubcategory(formData));
    history.replace('/products/subcategories');
  };

  return (
    <Subcategory>
      <div className="category-success-box">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 24 24"
            style={{ fill: '#262262', transform: ';msFilter:' }}
          >
            <path
              d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 
                        12 2zm-1.999 14.413-3.713-3.705L7.7 11.292l2.299 2.295 5.294-5.294 1.414 
                        1.414-6.706 6.706z"
            ></path>
          </svg>
        </span>
        <p>CATEGORY ADDED SUCCESSFULLY</p>
        <div>
          <button onClick={() => setOpenModal(true)}>Add Subcategory</button>
        </div>
        <Link to="/admin/products" onClick={() => dispatch(exit())}>
          <p>EXIT</p>
        </Link>
      </div>
      {openModal && (
        <div className="subcategory-form">
          <div className="subcategory-wrapper">
            <span onClick={() => setOpenModal(false)} className="close-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ fill: '#262262', transform: ';msFilter:;' }}
              >
                <path
                  d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 
                4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
                ></path>
              </svg>
            </span>
            <h3>Add Sub Category</h3>
            <div className="field-wrap subcategory-name">
              <label>Sub Category:</label>
              <input
                value={data.categoryName}
                onChange={handleNameChange}
                name="categoryName"
                type="text"
                placeholder="Subcategory Name"
                onKeyUp={categoryNameKeyUp}
              />
            </div>
            <div className="field-wrap">
              <div className="add-properties">
                <p>Add Property:</p>
                <div className="properties-input">
                  <input
                    value={property.name}
                    type="text"
                    placeholder="Property"
                    onKeyDown={propertyNameKeyDown}
                    onChange={handleProperty}
                  />
                  <button onClick={addProperty}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      style={{ fill: 'rgba(0, 0, 0, 1)' }}
                    >
                      <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
                    </svg>
                  </button>
                </div>
              </div>

              <div className="properties-wrap">
                <div className="properties-list">
                  <p className="description-text">Properties:</p>
                  <div className="properties">
                    {data.properties.map((property, ind) => (
                      <p key={ind} className="property">
                        {property?.name}
                      </p>
                    ))}
                  </div>
                </div>
                <p className="property-error">{propertyError}</p>
              </div>
            </div>
            <div className="field-wrap">
              <p>Upload Image</p>
              <div className="img-card">
                <AddImageCard readImgToParent={setImage} />
              </div>
            </div>
            <section className="visibility-section">
              <div className="visibility-wrapper">
                <div className="visibility-text">
                  <h4>Visibility Status</h4>
                  <p>
                    By Clicking the toggle ON, you are making this product
                    visible to all user
                  </p>
                </div>

                <ToggleButton toggleValue={handleVisibilityToggle} />
              </div>
            </section>
            <div className="subcat-btn">
              <button onClick={handleSubmit} disabled={creatingCategory}>
                {creatingCategory && <DotsLoader />}
                <span className="button-text">
                  {creatingCategory ? 'Creating...' : 'Add Sub Category'}
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </Subcategory>
  );
};

const Subcategory = styled.div`
  .category-success-box {
    width: 500px;
    height: 300px;
    background-color: #fff;
    margin: 50px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    border-radius: 4px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);

    p {
      margin-top: 30px;
    }

    div {
      margin-top: 30px;
    }
    button {
      padding: 12px 20px;
      border: none;
      background-color: #262262;
      color: #fff;
      border-radius: 4px;
      font-size: 18px;
    }
  }

  .subcategory-form {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);

    .subcategory-wrapper {
      width: 600px;
      height: 700px;
      background-color: #fff;
      margin: 10px auto;
      border-radius: 6px;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
      position: relative;
      overflow-y: scroll;

      h3 {
        padding-left: 30px;
        padding-top: 20px;
        color: #767575;
      }

      .field-wrap {
        border-bottom: 1px solid #c4c4c4;
        padding: 20px 30px;
        padding-bottom: 10px;
      }
      .add-properties {
        display: flex;
        align-items: center;

        p {
          width: 110px;
        }

        .properties-input {
          flex: 1;
          display: flex;
          align-items: center;

          input {
            flex: 1;
            padding: 15px 10px;
            border: 1px solid #e4e4e4;
            border-radius: 4px;
          }

          button {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 1px solid #e4e4e4;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            cursor: pointer;
            background: #fff;
          }
        }
      }

      .properties-wrap {
        .properties-list {
          display: flex;
          align-items: center;
          padding-top: 10px;

          .description-text {
            width: 110px;
          }
          .properties {
            flex: 1;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 5px;

            .property {
              padding: 5px;
              background-color: #e4e4e4;
              border-radius: 4px;
              text-align: center;
            }
          }
        }

        .property-error {
          font-size: 12px;
          color: red;
          margin-top: 15px;
          height: 20px;
          text-align: center;
        }
      }

      .img-card {
        display: inline-block;
        margin-left: 100px;
        margin-top: 15px;
      }
      .subcategory-name {
        display: flex;
        align-items: center;
        padding-right: 70px;
        padding-top: 10px;

        label {
          width: 110px;
          margin-bottom: 0;
        }
        input {
          flex: 1;
          padding: 15px 10px;
          border: 1px solid #e4e4e4;
          border-radius: 4px;
        }
      }

      .visibility-section {
        padding: 20px 30px;
        .visibility-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .visibility-text {
          max-width: 300px;

          h4 {
            font-size: 20px;
          }
          p {
            font-size: 12px;
          }
        }
      }

      .subcat-btn {
        padding: 20px 0;
        display: flex;
        justify-content: center;

        button {
          background-color: #262262;
          border: none;
          color: #fff;
          padding: 10px 20px;
          border-radius: 4px;
          display: flex;
          align-items: center;
        }
        button:disabled {
          cursor: not-allowed;
          background-color: #262262d8;
        }
      }

      .close-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }
`;

CreateSubcategory.propTypes = {
  parentId: PropTypes.string
};

export default CreateSubcategory;
