import toastr from 'toastr';
import { axios, _catchAxiosError } from 'services/axios';
import { removeUserToken } from 'store/actions/auth/login';
import { GET_CURRENT_ADMIN_USER } from 'store/actions/types';
import { BASE_URL } from 'utils/constants';
import { isLoading } from 'store/actions/auth/login';

export const logOut = props => async dispatch => {
  // localStorage.removeItem('token');
  // localStorage.removeItem('currentUser');
  // localStorage.removeItem('activeTab');
  // localStorage.removeItem('activeSubTab');
  // localStorage.removeItem('storeId');
  // localStorage.removeItem('adminDetails');
  // localStorage.removeItem('specificDate');
  // localStorage.removeItem('startDate');
  // localStorage.removeItem('endDate');
  // localStorage.removeItem('paginationSetValue');
  localStorage.clear();
  dispatch(removeUserToken());
  toastr.success('GoodBye! come again soon.');
  if (props) {
    props.history.push('/login');
  }
};

const currentAdminDetails = adminDetails => ({
  type: GET_CURRENT_ADMIN_USER,
  adminDetails
});

export const getCurrentAdminDetails = token => async dispatch => {
  try {
    dispatch(isLoading());
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.ADMIN}/admin`
    });
    const { data } = response.data;
    dispatch(currentAdminDetails(data));
    return response;
  } catch (error) {
    _catchAxiosError(error);
    return error;
  }
};
