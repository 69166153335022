import {
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
  GET_ORDERS_LOADING,
  GET_SINGLE_ORDER,
  GET_SINGLE_ORDER_IS_LOADING,
  GET_TABS_TOTAL
} from '../../actions/types';

const initialState = {
  getAllOrdersData: [],
  getAllOrdersLoading: false,
  getAllOrdersError: null,
  orderDetails: null,
  orderDetailsIsLoading: false,
  tabsTotal: null
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        getAllOrdersData: action.ordersData,
        getAllOrdersLoading: false,
        getAllOrdersError: null
      };
    case GET_ORDERS_ERROR:
      return {
        ...state,
        getAllOrdersData: [],
        getAllOrdersLoading: false,
        getAllOrdersError: action.ordersError
      };
    case GET_ORDERS_LOADING:
      return {
        ...state,
        getAllOrdersData: [],
        getAllOrdersLoading: true,
        getAllOrdersError: null
      };
    case GET_SINGLE_ORDER:
      return {
        ...state,
        orderDetails: action.orderDetails,
        orderDetailsIsLoading: false
      };
    case GET_SINGLE_ORDER_IS_LOADING:
      return {
        ...state,
        orderDetailsIsLoading: true
      };
    case GET_TABS_TOTAL: {
      return {
        ...state,
        tabsTotal: {
          ...state.tabsTotal,
          [action.payload.name]: action.payload.value
        }
      };
    }
    default:
      return state;
  }
};

export default ordersReducer;
