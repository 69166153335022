import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 280px;
  height: 100vh;
  background: #fafafa;

  .top-box {
    padding-top: 30px;
    padding-left: 20px;
    height: 148px;
    border-bottom: 0.5px solid #c4c4c4;
  }
  .second-box {
    padding-top: 33px;
    padding-left: 20px;
    height: 192px;
    border-bottom: 0.5px solid #c4c4c4;
    padding-right: 20px;
  }

  .price-flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .packed-flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .vehicle-flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .order-id {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #413d45;
    margin-bottom: 15px;
  }

  .status {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #413d45;
    margin-bottom: 14px;
  }

  .status-data {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #413d45;
    margin-left: 15px;
  }

  .order-date {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #413d45;
  }

  .date {
    margin-left: 20px;
    font-size: 13px;
  }

  .time {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: #413d45;
    margin-left: 10px;
  }

  .order-progress-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #262262;
    margin-left: 20px;
    margin-top: 10px;
  }

  .progress-line {
    height: 280px;
    margin-left: 52px;
    border-left: 1px solid #262262;
    margin-top: 25px;
    position: relative;
    margin-bottom: 50px;
  }

  .inner-circle {
    width: 18px;
    height: 18px;
    left: 189px;
    top: 483px;
    background: #262262;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
  .active-inner-circle {
    width: 18px;
    height: 18px;
    left: 189px;
    top: 483px;
    background: #d6d6d6;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }

  .outer-circle {
    width: 30px;
    height: 30px;
    left: 183px;
    top: 477px;
    background: #c4c4c4;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pending-flex {
    margin-left: -15px;
    margin-top: -15px;
    height: 90px;
    display: flex;
  }
  .confirmed-flex {
    margin-left: -15px;
    height: 100px;
    display: flex;
  }
  .transit-flex {
    margin-left: -15px;
    height: 90px;
    display: flex;
  }
  .delivered-flex {
    margin-left: -15px;
    height: 100px;
    display: flex;
  }

  .status-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #262262;
    margin-bottom: 5px;
  }

  .status-content {
    margin-top: 5px;
    margin-left: 10px;
  }

  .status-date {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #1a171e;
    margin-bottom: 5px;
  }

  .status-time {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: #5c5761;
  }
`;
