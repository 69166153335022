/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Filter from './Filter';
import isEmpty from 'is-empty';
import '../../assets/styles/tab2.css';
import LoaderSmall from '../Loaders/LoaderSmall';
import { Link } from 'react-router-dom';
import { ReactComponent as TableIcon } from 'assets/svg/tableIcon.svg';
import { ReactComponent as SatelliteIcon } from 'assets/svg/satellite.svg';
import { currentTime, now } from 'helper';

/**
 *
 * Tab Reusable Component for the codebase
 *
 * @property {Array} tabs an array of tabs of strings
 * @property {Function} toggleActiveTab a function that toggles between active tabs
 * @property {Array} activeTab is the array of tabs to be displayed
 * @property {Array} activeSubTab is the array of sub tabs to be displayed under the active tabs
 * @property {boolean} button a boolean to check if to display the export button or not
 * @property {boolean} addProductBtn a boolean to check if to display the export button or not bas on the product module
 * @property {boolean} listCount a boolean to show tab count base on an api call
 * @property {boolean} filter a boolean to show the filter button
 * @property {Array} csvData array containing the exported doc in csv
 * @property {Array} filterItems array of strings of items to be added in the filter component
 * @property {Array} subFilterItems array of strings of items to be added in the filter component base on the filter items chosen
 * @property {boolean} dualView a boolean state that either shows the map view or not
 * @property {Function} onSetView is a function that set the view back to tables
 * @returns jsx
 */
const Tab2 = props => {
  const [Data, setData] = useState([['null']]);
  const allowedTabs = [
    'Request from Admin',
    'Order Transactions',
    'Ride Transactions',
    'Categories',
    'Brands',
    'Products in stores'
  ];

  const {
    tabs,
    toggleActiveTab,
    activeTab,
    button,
    listCount,
    filter,
    filterItems,
    subFilterItems,
    activeSubTab,
    dualView,
    onSetView,
    csvData,
    addProductBtn,
    setDateFilters
  } = props;

  useEffect(() => {
    if (csvData) {
      let d = csvData.map(data => data.replace(/\s/g, '%20'));
      setData(d.join('%0A'));
    }
  }, [csvData]);

  return (
    <div className="items-container">
      <div className="items-wrapper">
        <div className="left-items tabs">
          {tabs
            .filter(item => !item.isRoot)
            .map((item, index) => (
              <div
                className="tab-item"
                key={index}
                onClick={() => toggleActiveTab(item.text)}
              >
                <div className="tabText-wrapper">
                  <span
                    className={`tab-text ${
                      activeTab === item.text ? 'active' : ''
                    }`}
                  >
                    {item.text}
                  </span>
                </div>
                {(listCount || allowedTabs.includes(item.text)) && (
                  <div className="tabTotal-wrapper">
                    <span
                      className={`tabTotal ${
                        activeTab === item.text ? 'active' : ''
                      }`}
                    >
                      {item.total === undefined ? <LoaderSmall /> : item.total}
                    </span>
                  </div>
                )}
              </div>
            ))}
        </div>

        {button && (
          <div className="right-items">
            {!addProductBtn && (
              <div className="export-btn">
                <a
                  href={'data:attachment/csv,' + Data}
                  target="_blank"
                  rel="noreferrer"
                  download={`${activeTab}${
                    activeSubTab === undefined || '' ? '' : `-${activeSubTab}`
                  }${currentTime()}.csv`}
                  className="download-csv"
                >
                  Export
                </a>
              </div>
            )}

            {addProductBtn && (
              <Link className="add-product-btn" to="/products/create">
                Add Product
              </Link>
            )}
            {filter && (
              <div className="filter-icon">
                <Filter
                  dropDownItems={filterItems}
                  subFilterItems={subFilterItems}
                  activeSubTab={activeSubTab}
                  setDateFilters={setDateFilters}
                  activeTab={activeTab}
                />
              </div>
            )}
          </div>
        )}

        {dualView && (
          <div className="right-items">
            <div className="table-icon">
              <TableIcon
                style={{
                  height: 20,
                  width: 20,
                  cursor: 'pointer'
                }}
                onClick={() => onSetView('table')}
              />
            </div>

            <div className="satelite-icon">
              <SatelliteIcon
                style={{
                  height: 20,
                  width: 20,
                  cursor: 'pointer'
                }}
                onClick={() => onSetView('map')}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Tab2.propTypes = {
  tabs: PropTypes.array,
  toggleActiveTab: PropTypes.func,
  activeTab: PropTypes.string,
  button: PropTypes.bool,
  listCount: PropTypes.bool,
  filterItems: PropTypes.array,
  filter: PropTypes.bool,
  subFilterItems: PropTypes.object,
  tabItemActive: PropTypes.string,
  activeSubTab: PropTypes.string,
  storeData: PropTypes.array,
  adminStoreRequestData: PropTypes.array,
  dualView: PropTypes.bool,
  onSetView: PropTypes.func,
  total: PropTypes.number,
  tableHeader: PropTypes.array,
  csvData: PropTypes.array,
  addProductBtn: PropTypes.bool,
  setDateFilters: PropTypes.func
};

const mapStateToProps = state => ({
  storeData: isEmpty(state.storeReducer.stores.data)
    ? []
    : state.storeReducer.stores.data,
  adminStoreRequestData: isEmpty(state.storeReducer.storeAdminRequests)
    ? []
    : state.storeReducer.storeAdminRequests.data
});

export default connect(mapStateToProps, null)(Tab2);
