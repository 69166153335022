import {
  INVITE_USER_FUFILLED,
  INVITE_USER_REJECTED,
  INVITE_USER_REQUEST,
  RETREIVE_ADMIN_USER_FUFILLED,
  RETREIVE_ADMIN_USER_REJECTED,
  RETREIVE_ADMIN_USER_REQUEST
} from 'store/actions/types';

const initialState = {
  isAuthenticated: false,
  invited: false,
  invitedUser: null,
  error: null,
  retrieved: false,
  adminData: {},
  isLoading: false
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVITE_USER_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case INVITE_USER_FUFILLED:
      return {
        ...state,
        invited: true,
        invitedUser: action.payload,
        isLoading: false
      };
    case INVITE_USER_REJECTED:
      return {
        ...state,
        invited: false,
        error: action.error,
        isLoading: false
      };
    case RETREIVE_ADMIN_USER_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case RETREIVE_ADMIN_USER_FUFILLED:
      return {
        ...state,
        retrieved: true,
        adminData: action.data,
        isLoading: false
      };
    case RETREIVE_ADMIN_USER_REJECTED:
      return {
        ...state,
        retrieved: false,
        error: action.error,
        isLoading: false
      };
    default:
      return state;
  }
};

export default settingsReducer;
