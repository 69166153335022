import OrderProgressData from 'components/custom/OrderProgressData/OrderProgessData';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Tab2 from 'components/Common/Tab2';
import './TaskOverview.css';
import { fetchTaskDetails } from 'store/actions/delivery/delivery';
import styled from 'styled-components';

const TaskOverview = () => {
  const [activeTab, setActiveTab] = useState('Task Details');
  const [activeTab2, setActiveTab2] = useState('Ratings');
  const [collapse, setCollapse] = useState(false);
  const [collapse2, setCollapse2] = useState(false);
  const { taskId } = useParams();
  const dispatch = useDispatch();
  const { pickupDetails, dropoffDetails, taskDetails } = useSelector(
    state => state.deliveryReducer.taskDetails
  );

  useEffect(() => {
    dispatch(fetchTaskDetails(taskId));
  }, []);

  //const stores = pickupDetails? pickupDetails.findTask.orderId.stores : []

  const retrievePickupDetails = () => {
    //returns the store and pickup status
    const stores = pickupDetails ? pickupDetails.findTask.orderId.stores : [];
    const status = pickupDetails
      ? pickupDetails.findTask.status
      : 'Not Available';
    return {
      stores,
      status
    };
  };

  const retrieveDropoffDetails = () => {
    const products = dropoffDetails ? dropoffDetails.findTask.products : [];
    const status = dropoffDetails
      ? dropoffDetails.findTask.status
      : 'Not Available';
    const buyerDetails = dropoffDetails
      ? dropoffDetails.findTask.buyerDetail
      : {};
    return {
      products,
      status,
      buyerDetails
    };
  };

  const retrieveDriverDetails = () => {
    const driver = taskDetails ? taskDetails.data.driverDetails : {};

    return driver;
  };

  const tabs = [
    {
      text: 'Task Details',
      total: null
    }
  ];
  const tabs2 = [
    {
      text: 'Ratings',
      total: null
    },
    {
      text: 'Comments',
      total: null
    }
  ];
  return (
    <div className="task-overview">
      <div className="task-overview-wrapper">
        <div className="progress-data">
          <div className="progress-data-wrapper">
            <div className="progress-status progress-item">
              <p className="task-id">
                Task ID: <span>27346282883</span>
              </p>
              <p className="status">
                Status: <span>PENDING</span>
              </p>
              <p className="date">
                Date: <span className="day">25th July 2020</span>{' '}
                <span className="time">9:20:22 AM</span>
              </p>
            </div>

            <div className="progress-item progress-items-details">
              <div className="progress-detail-flex">
                <p>No of items:</p>
                <p>10</p>
              </div>
              <div className="progress-detail-flex">
                <p>Vehicle type:</p>
                <p>Van</p>
              </div>
              <div className="progress-detail-flex">
                <p>Commission:</p>
                <p>10,000</p>
              </div>
              <div className="progress-detail-flex">
                <p>Weight of items:</p>
                <p>500g</p>
              </div>
              <div className="progress-detail-flex">
                <p>Order ID:</p>
                <p>1012323</p>
              </div>
            </div>
            <TaskProgress>
              <h4>Task Progress</h4>
              <ul className="stepper-wrapper">
                <li className="stepper-item">
                  <p className="step-name">Pending</p>
                  <p className="step-date">23 Jul 2019</p>
                  <p className="step-time">2:00:00PM</p>
                </li>
                <li className="stepper-item stepper-item active">
                  <p className="step-name">Confirmed</p>
                  <p className="step-date">23 Jul 2019</p>
                  <p className="step-time">2:00:00PM</p>
                </li>
                <li className="stepper-item">
                  <p className="step-name">In-Transit</p>
                  <p className="step-date">23 Jul 2019</p>
                  <p className="step-time">2:00:00PM</p>
                </li>
                <li className="stepper-item completed">
                  <p className="step-name">Completed</p>
                  <p className="step-date">23 Jul 2019</p>
                  <p className="step-time">2:00:00PM</p>
                </li>
              </ul>
            </TaskProgress>
            {/* <div className="progress-item task-progress">
              <h4>Task Progress</h4>
              <div className="pending task-progress-item">
                <p>Pending</p>
                <p>23 July 2019</p>
                <p>2:00:00 PM</p>
              </div>
            </div> */}
          </div>
        </div>

        <div className="task-overview-content">
          <div className="task-content-wrapper">
            <div className="users">
              <div className="user-card">
                <div className="delivery-representative">
                  <div className="card-type">
                    <span className="card-logo">
                      <svg
                        width="13"
                        height="18"
                        viewBox="0 0 13 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.49935 12.2173C5.88017 12.2173 5.38372 12.2609 4.96014 12.3257C3.21412 12.5924 1.74935 14.4461 1.74935 16.3478C1.74935 16.804 1.39491 17.1738 0.957682 17.1738C0.520457 17.1738 0.166016 16.804 0.166016 16.3478C0.166016 14.2057 1.45242 12.1016 3.3003 11.1571C1.8829 10.1097 0.957682 8.38495 0.957682 6.43471C0.957682 3.24106 3.43877 0.6521 6.49935 0.6521C9.55993 0.6521 12.041 3.24106 12.041 6.43471C12.041 8.38495 11.1158 10.1097 9.6984 11.1571C11.5463 12.1016 12.8327 14.2057 12.8327 16.3478C12.8327 16.804 12.4782 17.1738 12.041 17.1738C11.6038 17.1738 11.2493 16.804 11.2493 16.3478C11.2493 14.4461 9.78457 12.5924 8.03855 12.3257C7.61498 12.2609 7.11853 12.2173 6.49935 12.2173ZM6.49935 10.5651C4.31322 10.5651 2.54102 8.71588 2.54102 6.43471C2.54102 4.15353 4.31322 2.30427 6.49935 2.30427C8.68547 2.30427 10.4577 4.15353 10.4577 6.43471C10.4577 8.71588 8.68547 10.5651 6.49935 10.5651Z"
                          fill="#DB8130"
                        />
                      </svg>
                    </span>
                    <p className="owner-title">DELIVERY REP</p>
                  </div>
                  <div className="owner-name">
                    {retrieveDriverDetails()?.fullName}
                  </div>
                  <div className="owner-address">
                    Km 42, lekki-epe expressway. Ilaje busstop. Ajah
                  </div>
                  <div className="owner-phone">
                    {retrieveDriverDetails()?.mobile}
                  </div>
                  <div className="owner-email">
                    {retrieveDriverDetails()?.email}
                  </div>
                </div>
              </div>
              <div className="user-card">
                <div className="delivery-representative">
                  <div className="card-type">
                    <span className="card-logo stores">
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.33203 2.95825H2.16536V2.16659H5.33203V2.95825ZM6.91537 5.33325V4.54159H10.082V5.33325C10.082 6.2077 9.37315 6.91659 8.4987 6.91659C7.62425 6.91659 6.91537 6.2077 6.91537 5.33325ZM5.33203 4.54159V5.33325C5.33203 6.2077 4.62315 6.91659 3.7487 6.91659C2.87425 6.91659 2.16536 6.2077 2.16536 5.33325V4.54159H5.33203ZM8.4987 8.49992C7.5529 8.49992 6.70395 8.08528 6.1237 7.42786C5.54345 8.08528 4.6945 8.49992 3.7487 8.49992C3.47534 8.49992 3.21006 8.46528 2.95703 8.40016V14.8333H6.1237V11.6666C6.1237 10.3549 7.18702 9.29159 8.4987 9.29159C9.81038 9.29159 10.8737 10.3549 10.8737 11.6666V14.8333H14.0404V8.40016C13.7873 8.46528 13.5221 8.49992 13.2487 8.49992C12.3029 8.49992 11.4539 8.08528 10.8737 7.42786C10.2935 8.08528 9.4445 8.49992 8.4987 8.49992ZM10.082 16.4166H14.0404C14.9148 16.4166 15.6237 15.7077 15.6237 14.8333V7.70825C15.6237 7.62482 15.6108 7.54439 15.5869 7.46887C16.1015 6.90579 16.4154 6.15618 16.4154 5.33325V3.74992V2.16659C16.4154 1.29213 15.7065 0.583252 14.832 0.583252H10.082H6.91537H2.16536C1.29091 0.583252 0.582031 1.29213 0.582031 2.16659V3.74992V5.33325C0.582031 6.15618 0.895933 6.90579 1.41053 7.46887C1.38661 7.54439 1.3737 7.62482 1.3737 7.70825V14.8333C1.3737 15.7077 2.08258 16.4166 2.95703 16.4166H6.91537H10.082ZM9.29037 14.8333V11.6666C9.29037 11.2294 8.93592 10.8749 8.4987 10.8749C8.06147 10.8749 7.70703 11.2294 7.70703 11.6666V14.8333H9.29037ZM14.832 2.95825V2.16659H11.6654V2.95825H14.832ZM11.6654 4.54159V5.33325C11.6654 6.2077 12.3742 6.91659 13.2487 6.91659C14.1231 6.91659 14.832 6.2077 14.832 5.33325V4.54159H11.6654ZM10.082 2.95825H6.91537V2.16659L10.082 2.16659V2.95825Z"
                          fill="#262262"
                        />
                      </svg>
                    </span>
                    <p className="owner-title">STORES</p>
                  </div>
                  <div className="owner-name">JOHN APPLEASED</div>
                  <div className="owner-address">
                    Km 42, lekki-epe expressway. Ilaje busstop. Ajah
                  </div>
                  <div className="owner-phone">08023973465</div>
                  <div className="owner-email">johnappleased@gmail.com</div>
                </div>
              </div>
              <div className="user-card">
                <div className="delivery-representative">
                  <div className="card-type">
                    <span className="card-logo">
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.86494 0.826643C10.1657 1.14397 10.1523 1.64504 9.83498 1.94583L9.64949 2.12165L14.5765 6.7918C14.5819 6.79677 14.5872 6.80181 14.5924 6.80692L16.1683 8.30064C16.4856 8.60142 16.4991 9.1025 16.1983 9.41982C15.8975 9.73715 15.3964 9.75056 15.0791 9.44978L14.5852 8.98162L13.5027 15.7537C13.4413 16.1378 13.1099 16.4204 12.7209 16.4204H4.27648C3.88749 16.4204 3.55613 16.1378 3.49474 15.7537L2.41222 8.98162L1.91832 9.44978C1.60099 9.75056 1.09991 9.73715 0.79913 9.41982C0.498347 9.1025 0.511758 8.60142 0.829084 8.30064L2.40504 6.80684C2.41023 6.80178 2.41549 6.7968 2.42083 6.79188L8.74575 0.796689C9.06308 0.495906 9.56415 0.509317 9.86494 0.826643ZM13.1927 7.66175L8.4987 3.21245L3.80468 7.66175L4.95165 14.8371H12.0458L13.1927 7.66175ZM5.72787 10.0008C5.72787 8.5047 6.94069 7.29187 8.43679 7.29187H8.56061C10.0567 7.29187 11.2695 8.5047 11.2695 10.0008C11.2695 11.4969 10.0567 12.7097 8.56061 12.7097H8.43679C6.94069 12.7097 5.72787 11.4969 5.72787 10.0008ZM9.6862 10.0008C9.6862 9.37915 9.18225 8.8752 8.56061 8.8752H8.43679C7.81515 8.8752 7.3112 9.37915 7.3112 10.0008C7.3112 10.6224 7.81515 11.1264 8.43679 11.1264H8.56061C9.18225 11.1264 9.6862 10.6224 9.6862 10.0008Z"
                          fill="#DB8130"
                        />
                      </svg>
                    </span>
                    <p className="owner-title">DROP OFF LOCATION</p>
                  </div>
                  <div className="owner-name">
                    {retrieveDropoffDetails().buyerDetails.fullName}
                  </div>
                  <div className="owner-address">
                    {
                      retrieveDropoffDetails().buyerDetails.deliveryAddress
                        ?.address
                    }
                  </div>
                  <div className="owner-phone">
                    {retrieveDropoffDetails().buyerDetails.mobile}
                  </div>
                  <div className="owner-email">email@gmail.com</div>
                </div>
              </div>
            </div>
            <div className="tab-wrap">
              <Tab2
                tabs={tabs}
                activeTab={activeTab}
                toggleActiveTab={setActiveTab}
              />
            </div>

            <div className="pickup-details-wrapper">
              <h4>PICKUP DETAILS</h4>
              <div className="details">
                <div className="details-wrap">
                  <div className="details-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Stores</th>
                          <th>Item(s)</th>
                          <th>SKU</th>
                          <th>Quantity</th>
                          <th>Picked Time</th>
                          <th>Status</th>
                        </tr>
                      </thead>

                      <tbody className="pickup-body">
                        {retrievePickupDetails().stores.map((item, index) => {
                          const products = item.products ? item.products : [];
                          return (
                            <tr key={index}>
                              <td className="store-name">
                                <div>
                                  {collapse && (
                                    <span onClick={() => setCollapse(false)}>
                                      <svg
                                        width="12"
                                        height="8"
                                        viewBox="0 0 12 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M1.41 7.40991L6 2.82991L10.59 7.40991L12 5.99991L6 -8.86917e-05L0 5.99991L1.41 7.40991Z"
                                          fill="#413D45"
                                        />
                                      </svg>
                                    </span>
                                  )}
                                  {!collapse && (
                                    <span onClick={() => setCollapse(true)}>
                                      <svg
                                        width="12"
                                        height="8"
                                        viewBox="0 0 12 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M10.59 0.590088L6 5.17009L1.41 0.590088L0 2.00009L6 8.00009L12 2.00009L10.59 0.590088Z"
                                          fill="#413D45"
                                        />
                                      </svg>
                                    </span>
                                  )}
                                </div>
                                <span>{item.storeName}</span>
                              </td>
                              <td>
                                {products.map((prod, prod_ind) => (
                                  <p
                                    className={`product-name item-show ${
                                      collapse === true ? 'collapse-items' : ''
                                    }`}
                                    key={prod_ind}
                                  >
                                    {prod.productName}
                                    <span
                                      onClick={() => setCollapse(true)}
                                      className="and-more"
                                    >
                                      {products.length > 1 && collapse === false
                                        ? ' and more'
                                        : ''}
                                    </span>
                                  </p>
                                ))}
                              </td>
                              <td>
                                {products.map((prod, prod_ind) => (
                                  <p
                                    className={`item-show ${
                                      collapse === true ? 'collapse-items' : ''
                                    }`}
                                    key={prod_ind}
                                  >
                                    {prod.productId}
                                  </p>
                                ))}
                              </td>
                              <td>
                                {products.map((prod, prod_ind) => (
                                  <p
                                    className={`item-show ${
                                      collapse === true ? 'collapse-items' : ''
                                    }`}
                                    key={prod_ind}
                                  >
                                    {prod.quantity}
                                  </p>
                                ))}
                              </td>
                              <td className="pickup-time">
                                <span>23 July 2019</span>
                                <span>2:00:00 PM</span>
                              </td>
                              <td>{retrievePickupDetails().status}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="dropoff-details">
              <div className="dropoff-wrap">
                <h4>DROPOFF DETAILS</h4>

                <div className="dropoff-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Item(s)</th>
                        <th></th>
                        <th>Quantity</th>
                        <th>Dropped Time</th>
                        <th>Status</th>
                      </tr>
                    </thead>

                    <tbody className="dropoff-body">
                      {
                        <tr>
                          <td className="store-name">
                            <div>
                              {collapse2 && (
                                <span onClick={() => setCollapse2(false)}>
                                  <svg
                                    width="12"
                                    height="8"
                                    viewBox="0 0 12 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M1.41 7.40991L6 2.82991L10.59 7.40991L12 5.99991L6 -8.86917e-05L0 5.99991L1.41 7.40991Z"
                                      fill="#413D45"
                                    />
                                  </svg>
                                </span>
                              )}
                              {!collapse2 && (
                                <span onClick={() => setCollapse2(true)}>
                                  <svg
                                    width="12"
                                    height="8"
                                    viewBox="0 0 12 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M10.59 0.590088L6 5.17009L1.41 0.590088L0 2.00009L6 8.00009L12 2.00009L10.59 0.590088Z"
                                      fill="#413D45"
                                    />
                                  </svg>
                                </span>
                              )}
                            </div>
                            <span>
                              {retrieveDropoffDetails().buyerDetails.fullName}
                            </span>
                          </td>
                          <td>
                            {retrieveDropoffDetails().products.map(
                              (item, index) => (
                                <p
                                  key={index}
                                  className={`product-name item-show ${
                                    collapse2 === true ? 'collapse-items' : ''
                                  }`}
                                >
                                  {item.productName}
                                </p>
                              )
                            )}
                          </td>
                          <td></td>
                          <td>
                            {retrieveDropoffDetails().products.map(
                              (item, index) => (
                                <p
                                  key={index}
                                  className={`item-show ${
                                    collapse2 === true ? 'collapse-items' : ''
                                  }`}
                                >
                                  {item.quantity}
                                </p>
                              )
                            )}
                          </td>
                          <td className="dropoff-time">
                            <span>23 July 2019</span>
                            <span>2:00:00 PM</span>
                          </td>
                          <td>{retrieveDropoffDetails().status}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="ratings-comments">
              <Tab2
                tabs={tabs2}
                activeTab={activeTab2}
                toggleActiveTab={setActiveTab2}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TaskProgress = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-left: 1.5rem;
  padding-top: 1rem;

  h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #262262;
  }

  .stepper-item {
    position: relative;
    min-height: 100px;
    padding-left: 0.5rem;
    list-style: none;
  }

  .stepper-item::before {
    content: '';
    position: absolute;
    left: -1.6rem;
    top: 34px;
    height: 83%;
    width: 1px;
    border-left: 1px solid #262262;
  }

  .stepper-item::after {
    content: '';
    position: absolute;
    top: 10px;
    left: -2.2rem;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: transparent;
    color: #fff;
    font-weight: 400;
    font-size: 13px;
    line-height: 2rem;
    border: 1px solid #262226;
    background: #262262;
    box-shadow: 0px 0px 0 5px rgba(196, 196, 196, 0.9);
  }

  .stepper-item:last-child::before {
    border: none;
  }

  .completed::after {
    background: #d6d6d6;
    border: 1px solid #d6d6d6;
  }

  .step-name {
    padding-top: 8px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';
    color: #262262;
  }

  .step-date {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #1a171e;
  }

  .step-time {
    font-family: Poppins;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    color: #413d45;
  }
`;

export default TaskOverview;
