import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import clear from 'assets/images/clear.svg';
import search from 'assets/images/search.svg';
import expand_more from 'assets/expand_more.png';

const StyledModal = styled.ul`
  background-color: #ffffff;
  position: absolute;
  top: 20%;
  right: -3%;
  width: 201px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  z-index: 2;
  transition: 0.1s 1s;

  li {
    position: relative;
    padding: 1em 2em;
    &:not(:last-child) {
      border-bottom: 1px #e0e0e0 solid;
    }
    &:hover > ul {
      display: inherit;
    }
    a:after {
      position: absolute;
      content: url(${expand_more});
      right: 15%;
    }
    a:only-child:after {
      content: '';
    }
  }

  ul {
    display: none;
    padding: 0;
    list-style: none;
    left: 201px;
    top: 0;
    position: absolute;
    background: #ffffff;
    /* width: 201px; */
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    z-index: 2;
    transition: 0s;

    .search {
      border-bottom: 2px solid #8d8d8d;
      padding: 1em 1.5em;
      input {
        border: none;
        outline: none;
      }
    }
  }
`;

export const Modal = ({ isModalOpen, setIsModalOpen, ...others }) => {
  return (
    isModalOpen && (
      <StyledModal>
        <li>
          <span>Edit</span>
        </li>
        <li>
          <span>Rename</span>
        </li>
        <li>
          <span>Move to</span>
          <ul>
            <div className="flex search">
              <div>
                <img src={search} alt="search" />
              </div>
              <input placeholder="Search" />
              <div className="pointer">
                <img src={clear} alt="clear" />
              </div>
            </div>
            <li>Electronics</li>
            <li>Baby Product</li>
            <li>Baby Product</li>
            <li>Phones & Tablets</li>
            <li>Computers</li>
            <li>Games</li>
          </ul>
        </li>
        <li>Delete</li>
      </StyledModal>
    )
  );
};

Modal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func
};
