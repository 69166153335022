/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
import toastr from 'toastr';
import { axios, _catchAxiosError } from 'services/axios';
import { BASE_URL } from 'utils/constants';
import {
  GET_WALLET_MERCHANT_IS_LOADING,
  GET_WALLET_MERCHANT_SUCCESS,
  GET_WALLET_DRIVER_IS_LOADING,
  GET_WALLET_DRIVER_SUCCESS,
  GET_WALLET_BALANCE_MERCHANT_IS_LOADING,
  GET_WALLET_BALANCE_MERCHANT_SUCCESS,
  COLLECTION_WALLET_BALANCE_IS_LOADING,
  COLLECTION_WALLET_BALANCE_SUCCESS,
  ORDER_TRANSACTION_LOADING,
  ORDER_TRANSACTION_SUCCESS,
  GET_TOTAL_ORDERS_SUCCESS,
  GET_TOTAL_ORDERS_LOADING,
  LOAD_ORDER_DETAILS_SUCCESS,
  LOAD_ORDER_DETAILS_SUCCESS_IS_LOADING,
  PRODUCT_SOLD,
  PRODUCT_SOLD_ISLOADING,
  FINANCE_METRICS,
  PRODUCT_PAYOUT_METRICS,
  COST_OF_ORDER_CREATED,
  ORDER_COUNT,
  GET_TASK_TRANSACTION,
  GET_TASK_TRANSACTION_IS_LOADING,
  FETCH_USER_WALLET_DETAILS,
  FETCH_USER_WALLET_DETAILS_IS_LOADING,
  FETCH_USER_WALLET_DETAILS_ERROR,
  FETCH_USER_TRANSACTION,
  FETCH_USER_TRANSACTION_IS_LOADING,
  FETCH_USER_TRANSACTION_ERROR,
  GET_DRIVER_PAYOUT,
  GET_DRIVER_PAYOUT_IS_LOADING,
  GET_DRIVER_PAYOUT_ERROR,
  DRIVER_RIDE_HISTORY,
  DRIVER_RIDE_HISTORY_IS_LOADING,
  DRIVER_RIDE_HISTORY_ERROR,
  DRIVER_RIDE_COUNT,
  DRIVER_RIDE_COUNT_IS_LOADING,
  DRIVER_RIDE_COUNT_ERROR,
  MERCHANT_ORDER_TRANSACTION,
  MERCHANT_ORDER_TRANSACTION_IS_LOADING,
  MERCHANT_ORDER_TRANSACTION_ERROR,
  MERCHANT_SALES,
  MERCHANT_SALES_IS_LOADING,
  MERCHANT_SALES_ERROR,
  MERCHANT_SUB_TOTAL_COST_ORDER,
  MERCHANT_SUB_TOTAL_COST_ORDER_IS_LOADING,
  MERCHANT_SUB_TOTAL_COST_ORDER_ERROR,
  MERCHANT_COST_OF_SALES,
  MERCHANT_COST_OF_SALES_IS_LOADING,
  MERCHANT_COST_OF_SALES_ERROR,
  MERCHANT_ORDER_COST_OF_SALES,
  MERCHANT_ORDER_TOTAL_DELIVERY_COST,
  MERCHANT_ORDER_COUNT,
  SELLER_PAYOUT,
  SELLER_COMMISSION,
  SELLER_SALES_HISTORY,
  SELLER_SALES_HISTORY_IS_LOADING,
  SELLER_SALES_HISTORY_ERROR,
  GET_FINANCE_TABS_COUNT
} from '../types';

export const loadOrderDetails = orderDt => ({
  type: LOAD_ORDER_DETAILS_SUCCESS,
  orderDt
});

export const totalOrders = dt => ({
  type: GET_TOTAL_ORDERS_SUCCESS,
  dt
});

export const totalOrdersLoading = () => ({
  type: GET_TOTAL_ORDERS_LOADING
});

export const orderTransactions = orderTrxData => ({
  type: ORDER_TRANSACTION_SUCCESS,
  orderTrxData
});

export const orderTransactionsLoading = () => ({
  type: ORDER_TRANSACTION_LOADING
});

export const getCollectionWallet = walletData => ({
  type: COLLECTION_WALLET_BALANCE_SUCCESS,
  walletData
});

export const collectionWalletLoading = () => ({
  type: COLLECTION_WALLET_BALANCE_IS_LOADING
});

export const getmerchantsWallet = data => ({
  type: GET_WALLET_MERCHANT_SUCCESS,
  data
});

export const getmerchantsWalletLoading = () => ({
  type: GET_WALLET_MERCHANT_IS_LOADING
});

export const getdriversWallet = driverData => ({
  type: GET_WALLET_DRIVER_SUCCESS,
  driverData
});

export const getdriverWalletLoading = () => ({
  type: GET_WALLET_DRIVER_IS_LOADING
});

export const merchantWalletBalance = merchantWalletData => ({
  type: GET_WALLET_BALANCE_MERCHANT_SUCCESS,
  merchantWalletData
});

export const merchantWalletBalanceLoading = () => ({
  type: GET_WALLET_BALANCE_MERCHANT_IS_LOADING
});

export const orderDetailsIsLoading = () => ({
  type: LOAD_ORDER_DETAILS_SUCCESS_IS_LOADING
});

export const getAllProductsSold = soldProducts => ({
  type: PRODUCT_SOLD,
  soldProducts
});

export const getAllProductsIsLoading = () => ({
  type: PRODUCT_SOLD_ISLOADING
});

export const getFinanceMetrics = commissionMetrics => ({
  type: FINANCE_METRICS,
  commissionMetrics
});

export const getSoldProductMetrics = productMetrics => ({
  type: PRODUCT_PAYOUT_METRICS,
  productMetrics
});

export const getCostOfOrderCreated = orderCost => ({
  type: COST_OF_ORDER_CREATED,
  orderCost
});

export const fetchOrderCount = orderCount => ({
  type: ORDER_COUNT,
  orderCount
});

export const fetchTaskTransaction = rideTransaction => ({
  type: GET_TASK_TRANSACTION,
  rideTransaction
});

export const fetchRideTransactionIsLoading = () => ({
  type: GET_TASK_TRANSACTION_IS_LOADING
});

export const fetchUserWalletDetails = userWalletDetails => ({
  type: FETCH_USER_WALLET_DETAILS,
  userWalletDetails
});

export const fetchUserWalletDetailsIsLoading = () => ({
  type: FETCH_USER_WALLET_DETAILS_IS_LOADING
});

const fetchUserWalletDetailsError = userWalletDetailsError => ({
  type: FETCH_USER_WALLET_DETAILS_ERROR,
  userWalletDetailsError
});

export const fetchUserTransactions = userTransaction => ({
  type: FETCH_USER_TRANSACTION,
  userTransaction
});

export const fetchUserTransactionsIsLoading = () => ({
  type: FETCH_USER_TRANSACTION_IS_LOADING
});

export const fetchUserTransactionsError = userTransactionError => ({
  type: FETCH_USER_TRANSACTION_ERROR,
  userTransactionError
});

export const fetchDriverPayout = driverPayout => ({
  type: GET_DRIVER_PAYOUT,
  driverPayout
});

export const fetchDriverPayoutIsLoading = () => ({
  type: GET_DRIVER_PAYOUT_IS_LOADING
});

export const fetchDriverPayoutError = driverPayoutError => ({
  type: GET_DRIVER_PAYOUT_ERROR,
  driverPayoutError
});

export const fetchDriverRideHistory = rideHistory => ({
  type: DRIVER_RIDE_HISTORY,
  rideHistory
});

export const fetchDriverRideHistoryIsLoading = () => ({
  type: DRIVER_RIDE_HISTORY_IS_LOADING
});

export const fetchDriverRideHistoryError = rideHistoryError => ({
  type: DRIVER_RIDE_HISTORY_ERROR,
  rideHistoryError
});

export const fetchDriverRideCount = rideCount => ({
  type: DRIVER_RIDE_COUNT,
  rideCount
});

export const fetchDriverRideCountIsLoading = () => ({
  type: DRIVER_RIDE_COUNT_IS_LOADING
});

export const fetchDriverRideCountError = rideCountError => ({
  type: DRIVER_RIDE_COUNT_ERROR,
  rideCountError
});

export const fetchMerchantOrderTransaction = orderTransaction => ({
  type: MERCHANT_ORDER_TRANSACTION,
  orderTransaction
});

export const fetchMerchantOrderTransactionIsLoading = () => ({
  type: MERCHANT_ORDER_TRANSACTION_IS_LOADING
});

export const fetchMerchantOrderTransactionError = orderTransactionError => ({
  type: MERCHANT_ORDER_TRANSACTION_ERROR,
  orderTransactionError
});

export const fetchMerchantSales = merchantSales => ({
  type: MERCHANT_SALES,
  merchantSales
});

export const fetchMerchantSalesIsLoading = () => ({
  type: MERCHANT_SALES_IS_LOADING
});

export const fetchMerchantSalesError = merchantSalesError => ({
  type: MERCHANT_SALES_ERROR,
  merchantSalesError
});

export const fetchMerchantSubTotalCostOrder = orderSubTotalCost => ({
  type: MERCHANT_SUB_TOTAL_COST_ORDER,
  orderSubTotalCost
});

export const fetchMerchantSubTotalCostOrderIsLoading = () => ({
  type: MERCHANT_SUB_TOTAL_COST_ORDER_IS_LOADING
});

export const fetchMerchantSubTotalCostOrderError = orderSubTotalCostError => ({
  type: MERCHANT_SUB_TOTAL_COST_ORDER_ERROR,
  orderSubTotalCostError
});

export const merchantTotalSalesCost = merchantTotalCost => ({
  type: MERCHANT_COST_OF_SALES,
  merchantTotalCost
});

export const merchantTotalSalesCostIsLoading = () => ({
  type: MERCHANT_COST_OF_SALES_IS_LOADING
});

export const merchantTotalSalesCostError = merchantTotalCostError => ({
  type: MERCHANT_COST_OF_SALES_ERROR,
  merchantTotalCostError
});

export const merchantOrderTotalSalesCost = merchantOrderTotalCost => ({
  type: MERCHANT_ORDER_COST_OF_SALES,
  merchantOrderTotalCost
});

export const merchantOrderTotalDeliveryCost = orderTotalDeliveryCost => ({
  type: MERCHANT_ORDER_TOTAL_DELIVERY_COST,
  orderTotalDeliveryCost
});

export const merchantOrderCount = orderCount => ({
  type: MERCHANT_ORDER_COUNT,
  orderCount
});

export const sellerPayout = sellerPayout => ({
  type: SELLER_PAYOUT,
  sellerPayout
});

const sellerCommission = sellerCommission => ({
  type: SELLER_COMMISSION,
  sellerCommission
});

export const sellerSalesHistory = sellerHistory => ({
  type: SELLER_SALES_HISTORY,
  sellerHistory
});

export const sellerSalesHistoryIsLoading = () => ({
  type: SELLER_SALES_HISTORY_IS_LOADING
});

export const sellerSalesHistoryError = sellerHistoryError => ({
  type: SELLER_SALES_HISTORY_ERROR,
  sellerHistoryError
});

export const fetchTabsCount = () => async dispatch => {
  try {
    const {
      data: {
        meta: { total: merchants }
      }
    } = await axios.get(
      `${BASE_URL.TRANSACTION}/dashboard/user/wallet?userType=MERCHANT`
    );

    dispatch({
      type: GET_FINANCE_TABS_COUNT,
      payload: {
        name: 'merchants',
        value: merchants
      }
    });
  } catch (error) {
    dispatch({
      type: GET_FINANCE_TABS_COUNT,
      payload: {
        name: 'merchants',
        value: 0
      }
    });
  }

  try {
    const {
      data: {
        meta: { total: drivers }
      }
    } = await axios.get(
      `${BASE_URL.TRANSACTION}/dashboard/user/wallet?userType=DRIVER`
    );

    dispatch({
      type: GET_FINANCE_TABS_COUNT,
      payload: {
        name: 'drivers',
        value: drivers
      }
    });
  } catch (error) {
    dispatch({
      type: GET_FINANCE_TABS_COUNT,
      payload: {
        name: 'drivers',
        value: 0
      }
    });
  }

  try {
    const {
      data: {
        meta: { total: orderTransaction }
      }
    } = await axios.get(`${BASE_URL.TRANSACTION}/dashboard/order/transaction`);

    dispatch({
      type: GET_FINANCE_TABS_COUNT,
      payload: {
        name: 'orderTransaction',
        value: orderTransaction
      }
    });
  } catch (error) {
    dispatch({
      type: GET_FINANCE_TABS_COUNT,
      payload: {
        name: 'orderTransaction',
        value: 0
      }
    });
  }

  try {
    const {
      data: {
        pagination: { total: rideTransaction }
      }
    } = await axios.get(`${BASE_URL.LOGISTICS}/get-task-transactions`);

    dispatch({
      type: GET_FINANCE_TABS_COUNT,
      payload: {
        name: 'rideTransaction',
        value: rideTransaction
      }
    });
  } catch (error) {
    dispatch({
      type: GET_FINANCE_TABS_COUNT,
      payload: {
        name: 'rideTransaction',
        value: 0
      }
    });
  }
};

export const getSellerSalesHistory = payload => async dispatch => {
  const { userId, currentPage, pageLimitCount } = payload;
  dispatch(sellerSalesHistoryIsLoading());
  try {
    const res = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/transactionSaleHistory/${userId}?page=${currentPage}&limit=${pageLimitCount}`
    });
    const { data } = res;
    dispatch(sellerSalesHistory(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, false);
    dispatch(sellerSalesHistoryError(reportError));
    toastr.error(reportError);
    throw error;
  }
};

export const getSellerCommission = merchantId => async dispatch => {
  try {
    const res = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/sellerCommission/${merchantId}`
    });
    const { data } = res;
    dispatch(sellerCommission(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, false);
    toastr.error(reportError);
    throw error;
  }
};

export const getSellerPayout = merchantId => async dispatch => {
  try {
    const res = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/sellerPayout/${merchantId}`
    });
    const { data } = res;
    dispatch(sellerPayout(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, false);
    toastr.error(reportError);
    throw error;
  }
};

export const getMerchantOrderCount = merchantId => async dispatch => {
  try {
    const res = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/userOrderCount/${merchantId}`
    });
    const { data } = res;
    dispatch(merchantOrderCount(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, false);
    toastr.error(reportError);
    throw error;
  }
};

export const getMerchantOrderTotalDeliveryCost = merchantId => async dispatch => {
  try {
    const res = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/userTotalCostOfDelivery/${merchantId}`
    });
    const { data } = res;
    dispatch(merchantOrderTotalDeliveryCost(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, false);
    toastr.error(reportError);
    throw error;
  }
};

export const getMerchantOrderTotalSalesCost = merchantId => async dispatch => {
  try {
    const res = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/userTotalCostOfSales/${merchantId}`
    });
    const { data } = res;
    dispatch(merchantOrderTotalSalesCost(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, false);
    toastr.error(reportError);
    throw error;
  }
};

export const getMerchantTotalSaleCost = merchantId => async dispatch => {
  dispatch(merchantTotalSalesCostIsLoading());
  try {
    const res = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/costOfSales/${merchantId}`
    });
    const { data } = res;
    dispatch(merchantTotalSalesCost(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, false);
    dispatch(merchantTotalSalesCostError(reportError));
    toastr.error(reportError);
    throw error;
  }
};

export const getMerchantOrderSubTotalCost = merchantId => async dispatch => {
  dispatch(fetchMerchantSubTotalCostOrderIsLoading());
  try {
    const res = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/userSubtotalCostOfOrder/${merchantId}`
    });
    const { data } = res;
    dispatch(fetchMerchantSubTotalCostOrder(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, false);
    dispatch(fetchMerchantSubTotalCostOrderError(reportError));
    toastr.error(reportError);
    throw error;
  }
};

export const getMerchantSales = merchantId => async dispatch => {
  dispatch(fetchMerchantSalesIsLoading());
  try {
    const res = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/userSales/${merchantId}`
    });
    const { data } = res;
    dispatch(fetchMerchantSales(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, false);
    dispatch(fetchMerchantSalesError(reportError));
    toastr.error(reportError);
    throw error;
  }
};

export const merchantOrderTransaction = payload => async dispatch => {
  const { userId, currentPage, pageLimitCount } = payload;

  dispatch(fetchMerchantOrderTransactionIsLoading());
  try {
    const res = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/userOrderTransaction/${userId}?page=${currentPage}&limit=${pageLimitCount}`
    });
    const { data } = res;
    dispatch(fetchMerchantOrderTransaction(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, false);
    dispatch(fetchMerchantOrderTransactionError(reportError));
    toastr.error(reportError);
    throw error;
  }
};

export const getDriverRideCount = driverId => async dispatch => {
  dispatch(fetchDriverRideCountIsLoading());
  try {
    const res = await axios({
      method: 'get',
      url: `${BASE_URL.LOGISTICS}/get-driver-ride-count/${driverId}`
    });
    const { data } = res;
    dispatch(fetchDriverRideCount(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, false);
    dispatch(fetchDriverRideCountError(reportError));
    toastr.error(reportError);
    throw error;
  }
};

export const getDriverRideHistory = payload => async dispatch => {
  const { userId, currentPage, pagePerLimit } = payload;
  dispatch(fetchDriverRideHistoryIsLoading());
  try {
    const res = await axios({
      method: 'get',
      url: `${BASE_URL.LOGISTICS}/get-driver-ride-history/${userId}?page=${currentPage}&limit=${pagePerLimit}`
    });
    const { data } = res;
    dispatch(fetchDriverRideHistory(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, false);
    dispatch(fetchDriverRideHistoryError(reportError));
    toastr.error(reportError);
    throw error;
  }
};

export const getDriverPayout = driverId => async dispatch => {
  dispatch(fetchDriverPayoutIsLoading());
  try {
    const res = await axios({
      method: 'get',
      url: `${BASE_URL.LOGISTICS}/get-driver-payout/${driverId}`
    });
    const { data } = res;
    dispatch(fetchDriverPayout(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, false);
    dispatch(fetchDriverPayoutError(reportError));
    toastr.error(reportError);
    throw error;
  }
};

export const getUserTransactions = payload => async dispatch => {
  dispatch(fetchUserTransactionsIsLoading());
  try {
    const { userId, query, currentPage, pageLimitCount } = payload;
    const res = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/user/transactions/${userId}?type=${query}&page=${currentPage}&limit=${pageLimitCount}`
    });
    const { data } = res;
    dispatch(fetchUserTransactions(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, false);
    dispatch(fetchUserTransactionsError(reportError));
    toastr.error(reportError);
    throw error;
  }
};

export const loadOrder = orderDt => async dispatch => {
  try {
    dispatch(orderDetailsIsLoading());
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.ORDER}/view_order?orderId=${orderDt}`
    });
    dispatch(loadOrderDetails(response.data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, false);
    toastr.error(reportError);
    throw error;
  }
};

export const getTotalOrders = (onError = false) => async dispatch => {
  try {
    dispatch(totalOrdersLoading());
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/costofordercreated`
    });
    dispatch(totalOrders(response.data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const getAllwalletsForMerchants = (
  currentPage,
  pageLimitCount,
  dateFilters,
  onError = false
) => async dispatch => {
  const { specificDate, startDate, endDate } = dateFilters;

  let params = {
    page: currentPage,
    limit: pageLimitCount,
    userType: 'MERCHANT',
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate })
  };

  try {
    dispatch(getmerchantsWalletLoading());
    const response = await axios.get(
      `${BASE_URL.TRANSACTION}/dashboard/user/wallet`,
      { params }
    );
    dispatch(getmerchantsWallet(response.data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const getAllwalletsForDrivers = (
  currentPage,
  pageLimitCount,
  dateFilters,
  onError = false
) => async dispatch => {
  const { specificDate, startDate, endDate } = dateFilters;

  let params = {
    page: currentPage,
    limit: pageLimitCount,
    userType: 'DRIVER',
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate })
  };

  try {
    dispatch(getdriverWalletLoading());
    const response = await axios.get(
      `${BASE_URL.TRANSACTION}/dashboard/user/wallet`,
      { params }
    );
    dispatch(getdriversWallet(response.data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const getAllwalletBalance = (
  data,
  onError = false
) => async dispatch => {
  try {
    dispatch(merchantWalletBalanceLoading());
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/sum/user/available/balance?userType=${data}`
    });
    dispatch(merchantWalletBalance(response.data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const getCollectionWallets = (onError = false) => async dispatch => {
  try {
    dispatch(collectionWalletLoading());
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/collectionwallet`
    });
    dispatch(getCollectionWallet(response.data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const getOrderTransactions = (
  currentPage,
  limit,
  dateFilters,
  onError = false
) => async dispatch => {
  const { specificDate, startDate, endDate } = dateFilters;
  let params = {
    page: currentPage,
    limit: limit,
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate })
  };
  try {
    dispatch(orderTransactionsLoading());
    const response = await axios.get(
      `${BASE_URL.TRANSACTION}/dashboard/order/transaction`,
      { params }
    );
    dispatch(orderTransactions(response.data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const getProductSold = (
  currentPage,
  limit,
  dateFilters,
  onError = false
) => async dispatch => {
  const { specificDate, startDate, endDate } = dateFilters;
  let params = {
    page: currentPage,
    limit,
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate })
  };
  try {
    dispatch(getAllProductsIsLoading());
    const response = await axios.get(`${BASE_URL.TRANSACTION}/products-sold`, {
      params
    });
    const { data } = response;
    dispatch(getAllProductsSold(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const getFinanceMetricsForCommission = (
  onError = false
) => async dispatch => {
  try {
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/fianace-metrics`
    });
    const { data } = response.data;
    dispatch(getFinanceMetrics(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const getTotalProductPayout = (onError = false) => async dispatch => {
  try {
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.ORDER}/gets-aggregate-of-product`
    });
    const { data } = response.data;
    dispatch(getSoldProductMetrics(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const costOfOrderCreated = (onError = false) => async dispatch => {
  try {
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/costofordercreated`
    });
    const { data } = response;
    dispatch(getCostOfOrderCreated(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const getOrderCount = (onError = false) => async dispatch => {
  try {
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/order/count`
    });
    const { data } = response;
    dispatch(fetchOrderCount(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const getRideTransactions = (
  currentPage,
  limit,
  dateFilters,
  onError = false
) => async dispatch => {
  dispatch(fetchRideTransactionIsLoading());

  const { specificDate, startDate, endDate } = dateFilters;

  let params = {
    page: currentPage,
    limit,
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate }),
    type: 'filter'
  };
  try {
    const response = await axios.get(
      `${BASE_URL.LOGISTICS}/get-task-transactions`,
      { params }
    );
    const { data } = response;
    dispatch(fetchTaskTransaction(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const getUserWalletDetails = (
  userId,
  onError = false
) => async dispatch => {
  dispatch(fetchUserWalletDetailsIsLoading());
  try {
    const res = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/user/wallet/details?userId=${userId}`
    });
    const { data } = res;
    dispatch(fetchUserWalletDetails(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    fetchUserWalletDetailsError(reportError);
    toastr.error(reportError);
    throw error;
  }
};

export const freezeUser = userId => async dispatch => {
  try {
    const response = await axios({
      method: 'put',
      url: `${BASE_URL.TRANSACTION}/user/wallet/freeze`,
      data: { userId }
    });

    console.log(response);
  } catch (error) {
    console.log(error);
  }
};
export const unfreezeUser = userId => async dispatch => {
  try {
    const response = await axios({
      method: 'put',
      url: `${BASE_URL.TRANSACTION}/user/wallet/unfreeze`,
      data: { userId }
    });

    console.log(response);
  } catch (error) {
    console.log(error);
  }
};
