import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const AddImageCard = ({ readImgToParent }) => {
  const [active, setActive] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [text, setText] = useState('Drag Image File here');
  const inputRef = useRef();

  const showFile = file => {
    let fileType = file.type;
    let validExtension = ['image/jpeg', 'image/jpg', 'image/png'];

    if (validExtension.includes(fileType)) {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        let fileUrltemp = fileReader.result;
        setFileUrl(fileUrltemp);
      };
      fileReader.readAsDataURL(file);
    } else {
      setActive(false);
      alert('File not an image file');
    }
  };

  const handleDragOver = e => {
    e.preventDefault();
    setText('Release File to upload');
    setActive(true);
  };
  const handleDragLeave = () => {
    setActive(false);
    setText('Drag File here');
  };
  const handleDrop = e => {
    e.preventDefault();
    let file;
    file = e.dataTransfer.files[0];
    showFile(file);
    if (readImgToParent !== undefined) {
      readImgToParent(file);
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };
  const handleFileChange = e => {
    let files = e.target.files[0];

    showFile(files);
    if (readImgToParent !== undefined) {
      readImgToParent(files);
    }
  };
  return (
    <Container
      active={active}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={e => handleDrop(e)}
    >
      <input ref={inputRef} onChange={handleFileChange} type="file" hidden />
      {fileUrl !== '' && (
        <>
          <div className="icons-holder">
            <span onClick={handleClick} className="edit-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.33463 2.71998L2.66797 9.11998V11.52H5.16797L11.8346 5.11998L9.33463 2.71998ZM4.61464 10.24H4.0013V9.65118L9.33464 4.53118L9.94797 5.11998L4.61464 10.24ZM13.808 3.22563C14.068 2.97603 14.068 2.57283 13.808 2.32323L12.248 0.825628C11.988 0.576028 11.568 0.576028 11.308 0.825628L10.0013 2.08003L12.5013 4.48003L13.808 3.22563Z"
                  fill="#000"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 13.4399H16V15.9999H0V13.4399Z"
                  fill="#000"
                />
              </svg>
            </span>

            <span onClick={() => setFileUrl('')} className="trashIcon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.99803 4.6666V4.1666H3.49803V4.6666H3.99803ZM6.33136 1.99927V1.49927H6.12397L5.97746 1.64607L6.33136 1.99927ZM5.66536 2.6666V3.1666H5.87276L6.01927 3.0198L5.66536 2.6666ZM3.33203 3.99993H2.83203V4.49993H3.33203V3.99993ZM12.6654 3.99993V4.49993H13.1654V3.99993H12.6654ZM10.332 2.6666L9.97848 3.02015L10.1249 3.1666H10.332V2.6666ZM11.998 4.1666H3.99803V5.1666H11.998V4.1666ZM3.49803 4.6666V12.6666H4.49803V4.6666H3.49803ZM3.49803 12.6666C3.49803 13.6789 4.31938 14.4999 5.33203 14.4999V13.4999C4.87134 13.4999 4.49803 13.1263 4.49803 12.6666H3.49803ZM5.33203 14.4999H10.6647V13.4999H5.33203V14.4999ZM10.6647 14.4999C11.6785 14.4999 12.498 13.6784 12.498 12.6666H11.498C11.498 13.1268 11.1256 13.4999 10.6647 13.4999V14.4999ZM12.498 12.6666V4.6666H11.498V12.6666H12.498ZM9.6647 1.49927H6.33136V2.49927H9.6647V1.49927ZM5.97746 1.64607L5.31146 2.3134L6.01927 3.0198L6.68527 2.35247L5.97746 1.64607ZM5.66536 2.1666H3.99803V3.1666H5.66536V2.1666ZM3.99803 2.1666C3.35456 2.1666 2.83203 2.68913 2.83203 3.3326H3.83203C3.83203 3.24141 3.90684 3.1666 3.99803 3.1666V2.1666ZM2.83203 3.3326V3.99993H3.83203V3.3326H2.83203ZM3.33203 4.49993H12.6654V3.49993H3.33203V4.49993ZM13.1654 3.99993V3.3326H12.1654V3.99993H13.1654ZM13.1654 3.3326C13.1654 2.68847 12.6422 2.1666 11.998 2.1666V3.1666C12.0912 3.1666 12.1654 3.24206 12.1654 3.3326H13.1654ZM11.998 2.1666H10.332V3.1666H11.998V2.1666ZM10.6856 2.31305L10.0183 1.64571L9.31114 2.35282L9.97848 3.02015L10.6856 2.31305Z"
                  fill="#000"
                />
              </svg>
            </span>
          </div>

          <img src={fileUrl} alt="" />
        </>
      )}

      {fileUrl === '' && (
        <div className="card-wrapper">
          <h6>{text}</h6>
          <span>OR</span>
          <button onClick={handleClick} className="browse-btn">
            Browse
          </button>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 250px;
  height: 250px;
  background-color: #f0f0f0;
  position: relative;
  border: ${({ active }) =>
    active === true ? '1px solid red' : '1px solid #d6d6d6'};
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 0;
  }
  .icons-holder {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 8px 6px 0;

    span {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    }
  }

  .card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    position: relative;

    h6 {
      margin-bottom: 0.2rem;
    }
    span {
      margin-bottom: 0.5rem;
      font-weight: 600;
    }
  }
  .browse-btn {
    padding: 5px 15px;
    color: #fff;
    background-color: #666666;
    border: none;
    border-radius: 5px;
  }
`;
AddImageCard.propTypes = {
  readImgToParent: PropTypes.func
};
export default AddImageCard;
