import React from 'react';
import './OrderDetails.css';

const ActionMenu = () => {
  return (
    <div className="action-menu">
      <ul>
        <li>Unresolved</li>
        <li>Work In Progress</li>
        <li>Closed</li>
      </ul>
    </div>
  );
};

export default ActionMenu;
