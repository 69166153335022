import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Wrapper } from './style';
import { formatMoney } from 'helper';

function OrderProgressData(props) {
  const { orderDetails } = props;
  const getPickUpProducts =
    orderDetails === null
      ? []
      : orderDetails.products.filter(i => Object.keys(i)[0]);

  return (
    <>
      <Wrapper className="order-progress-wrap">
        <div className="top-box">
          <div className="order-id">
            Order ID:{' '}
            {orderDetails === null ? 'N/A' : orderDetails._id.substring(0, 10)}
          </div>
          <div className="status">
            Status:{' '}
            <span className="status-data">
              {orderDetails === null ? 'N/A' : orderDetails.orderStatus}
            </span>
          </div>
          <div className="order-date">
            Date:
            <span className="date">
              {orderDetails === null
                ? 'N/A'
                : moment(orderDetails.updatedAt).format('LL')}
            </span>
            <span className="time">
              {orderDetails === null
                ? 'N/A'
                : moment(orderDetails.updatedAt).format('LT')}
            </span>
          </div>
        </div>
        <div className="second-box">
          <div className="price-flex">
            <div>Price:</div>
            <div>
              {orderDetails === null
                ? '0.00'
                : formatMoney(orderDetails.totalCost)}
            </div>
          </div>
          <div className="packed-flex">
            <div>Packed Item:</div>
            <div>
              {orderDetails === null
                ? 'N/A'
                : `${getPickUpProducts.length} / ${orderDetails.products.length}`}
            </div>
          </div>
          <div className="vehicle-flex">
            <div>Vehicle type:</div>
            <div>
              {orderDetails === null ||
              orderDetails.driver === undefined ||
              orderDetails.driver.vehicleType === undefined
                ? '***'
                : orderDetails.driver.vehicleType.vehicle}
            </div>
          </div>
        </div>

        <div className="progress-box">
          <div className="order-progress-title">Order Progress</div>

          <div className="progress-line">
            <div className="pending-flex">
              <div className="outer-circle">
                <div className="inner-circle"></div>
              </div>
              <div className="status-content">
                <div className="status-title">Pending</div>
                <div className="status-date">23 Jul 2019</div>
                <div className="status-time">2:00:00PM</div>
              </div>
            </div>
            <div className="confirmed-flex">
              <div className="outer-circle">
                <div className="inner-circle"></div>
              </div>

              <div className="status-content">
                <div className="status-title">Confirmed</div>
                <div className="status-date">23 Jul 2019</div>
                <div className="status-time">2:00:00PM</div>
              </div>
            </div>
            <div className="transit-flex">
              <div className="outer-circle">
                <div className="active-inner-circle"></div>
              </div>

              <div className="status-content">
                <div className="status-title">In-Transit</div>
                <div className="status-date">23 Jul 2019</div>
                <div className="status-time">2:00:00PM</div>
              </div>
            </div>
            <div className="delivered-flex">
              <div className="outer-circle">
                <div className="inner-circle"></div>
              </div>

              <div className="status-content">
                <div className="status-title">Delivered</div>
                <div className="status-date">23 Jul 2019</div>
                <div className="status-time">2:00:00PM</div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

OrderProgressData.propTypes = {
  orderDetails: PropTypes.any
};

export default OrderProgressData;
