import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import './OrderDetails.css';

import { Wrapper } from './style';

import Header from '../../components/custom/DetailHeader/Header';
import OrderProgressData from 'components/custom/OrderProgressData/OrderProgessData';
import OrderDetailTable from 'components/custom/OrderDetailTable/OrderDetailTable';
import { fetchOrderDetails } from 'store/actions/orders/orders';
import isEmpty from 'is-empty';

import ActionMenu from './ActionMenu';
import comments from './commentsDummyData';
import { formatMoney } from 'helper';

const OrderDetails = props => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [menu, toggleMenu] = useState(false);

  useEffect(() => {
    // code to run on component mount

    // dispatch merchant on component did mount
    dispatch(fetchOrderDetails(orderId));
  }, [dispatch, orderId]);

  const { orderDetails } = props;
  const orderProducts = orderDetails === null ? [] : orderDetails.products;
  const subTotalCost =
    orderDetails === null ? 'N/A' : orderDetails.subTotalCost;
  const orderStatus = orderDetails === null ? 'N/A' : orderDetails.orderStatus;
  const orderDeliveryFee =
    orderDetails === null ? 'N/A' : orderDetails.deliveryFee;
  const totalCost = orderDetails === null ? 'N/A' : orderDetails.totalCost;
  const orderDate = orderDetails === null ? 'N/A' : orderDetails.updatedAt;
  console.log(orderDetails);

  return (
    <Wrapper>
      <div className="content-wrapper-order-detail">
        <Header />
        <div className="main-contents-flex">
          <OrderProgressData orderDetails={orderDetails} />

          <div className="main-content">
            <div className="user-data-wrap">
              <div className="user-wrapper">
                <div className="user-type">
                  <span className="user-type-logo buyer-logo">
                    <svg
                      width="13"
                      height="18"
                      viewBox="0 0 13 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.49935 12.2173C5.88017 12.2173 5.38372 12.2609 4.96014 12.3257C3.21412 12.5924 1.74935 14.4461 1.74935 16.3478C1.74935 16.804 1.39491 17.1738 0.957682 17.1738C0.520457 17.1738 0.166016 16.804 0.166016 16.3478C0.166016 14.2057 1.45242 12.1016 3.3003 11.1571C1.8829 10.1097 0.957682 8.38495 0.957682 6.43471C0.957682 3.24106 3.43877 0.6521 6.49935 0.6521C9.55993 0.6521 12.041 3.24106 12.041 6.43471C12.041 8.38495 11.1158 10.1097 9.6984 11.1571C11.5463 12.1016 12.8327 14.2057 12.8327 16.3478C12.8327 16.804 12.4782 17.1738 12.041 17.1738C11.6038 17.1738 11.2493 16.804 11.2493 16.3478C11.2493 14.4461 9.78457 12.5924 8.03855 12.3257C7.61498 12.2609 7.11853 12.2173 6.49935 12.2173ZM6.49935 10.5651C4.31322 10.5651 2.54102 8.71588 2.54102 6.43471C2.54102 4.15353 4.31322 2.30427 6.49935 2.30427C8.68547 2.30427 10.4577 4.15353 10.4577 6.43471C10.4577 8.71588 8.68547 10.5651 6.49935 10.5651Z"
                        fill="#DB8130"
                      />
                    </svg>
                  </span>
                  <span className="user-title">BUYER</span>
                </div>

                <div className="user-name">
                  {orderDetails === null
                    ? 'N/A'
                    : orderDetails.buyerId.fullName}
                </div>
                <div className="user-address">
                  {orderDetails === null
                    ? 'N/A'
                    : orderDetails.buyerId.deliveryAddress.address}
                </div>
                <div className="user-mobile">
                  {orderDetails === null ? 'N/A' : orderDetails.buyerId.mobile}
                </div>
                <div className="user-email">
                  {orderDetails === null ? 'N/A' : orderDetails.buyerId.email}
                </div>
              </div>
              <div className="user-wrapper">
                <div className="user-type store-user-type">
                  <div className="store-user">
                    <span className="user-type-logo store-logo">
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.33398 3.95825H3.16732V3.16659H6.33398V3.95825ZM7.91732 6.33325V5.54159H11.084V6.33325C11.084 7.2077 10.3751 7.91659 9.50065 7.91659C8.6262 7.91659 7.91732 7.2077 7.91732 6.33325ZM6.33398 5.54159V6.33325C6.33398 7.2077 5.6251 7.91659 4.75065 7.91659C3.8762 7.91659 3.16732 7.2077 3.16732 6.33325V5.54159H6.33398ZM9.50065 9.49992C8.55485 9.49992 7.7059 9.08528 7.12565 8.42786C6.5454 9.08528 5.69645 9.49992 4.75065 9.49992C4.47729 9.49992 4.21202 9.46528 3.95898 9.40016V15.8333H7.12565V12.6666C7.12565 11.3549 8.18898 10.2916 9.50065 10.2916C10.8123 10.2916 11.8757 11.3549 11.8757 12.6666V15.8333H15.0423V9.40016C14.7893 9.46528 14.524 9.49992 14.2507 9.49992C13.3049 9.49992 12.4559 9.08528 11.8757 8.42786C11.2954 9.08528 10.4465 9.49992 9.50065 9.49992ZM11.084 17.4166H15.0423C15.9168 17.4166 16.6257 16.7077 16.6257 15.8333V8.70825C16.6257 8.62482 16.6127 8.54439 16.5888 8.46887C17.1034 7.90579 17.4173 7.15618 17.4173 6.33325V4.74992V3.16659C17.4173 2.29213 16.7084 1.58325 15.834 1.58325H11.084H7.91732H3.16732C2.29287 1.58325 1.58398 2.29213 1.58398 3.16659V4.74992V6.33325C1.58398 7.15618 1.89789 7.90579 2.41249 8.46887C2.38856 8.54439 2.37565 8.62482 2.37565 8.70825V15.8333C2.37565 16.7077 3.08453 17.4166 3.95898 17.4166H7.91732H11.084ZM10.2923 15.8333V12.6666C10.2923 12.2294 9.93788 11.8749 9.50065 11.8749C9.06343 11.8749 8.70899 12.2294 8.70899 12.6666V15.8333H10.2923ZM15.834 3.95825V3.16659H12.6673V3.95825H15.834ZM12.6673 5.54159V6.33325C12.6673 7.2077 13.3762 7.91659 14.2507 7.91659C15.1251 7.91659 15.834 7.2077 15.834 6.33325V5.54159H12.6673ZM11.084 3.95825H7.91732V3.16659L11.084 3.16659V3.95825Z"
                          fill="#262262"
                        />
                      </svg>
                    </span>
                    <span className="user-title">STORES</span>
                  </div>
                  <span className="count">{orderProducts.length}</span>
                </div>
                <div>
                  {orderProducts.map((item, index) => {
                    let storeName = Object.keys(item)[0];
                    return (
                      <p className="store" key={index}>
                        {storeName}
                      </p>
                    );
                  })}
                </div>
              </div>

              <div className="user-wrapper">
                <div className="user-type">
                  <span className="user-type-logo rep-logo">
                    <svg
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 1.58333C0 0.708883 0.708882 0 1.58333 0H10.2917C13.3522 0 15.8333 2.48109 15.8333 5.54167V10.9814C15.8333 11.4749 15.4333 11.875 14.9398 11.875H14.2219C14.0299 13.2178 12.8751 14.25 11.4792 14.25C10.0833 14.25 8.92846 13.2178 8.73639 11.875H6.30527C6.1132 13.2178 4.9584 14.25 3.5625 14.25C2.41559 14.25 1.43143 13.5532 1.0103 12.5598C0.419092 12.3302 0 11.7557 0 11.0833V7.91667V6.33333V1.58333ZM6.06669 10.2917H8.97498C9.24871 9.71546 9.71546 9.24871 10.2917 8.97498V7.91667H1.58333V9.53999C2.08625 9.02677 2.78719 8.70833 3.5625 8.70833C4.66782 8.70833 5.62198 9.35554 6.06669 10.2917ZM10.2917 11.4792C10.2917 12.135 10.8233 12.6667 11.4792 12.6667C12.135 12.6667 12.6667 12.135 12.6667 11.4792C12.6667 10.8233 12.135 10.2917 11.4792 10.2917C10.8233 10.2917 10.2917 10.8233 10.2917 11.4792ZM1.58333 6.33333H10.2917L10.2917 1.58333L1.58333 1.58333V6.33333ZM11.875 8.73639C12.809 8.86999 13.5927 9.46936 13.9834 10.2917H14.25V6.33333H11.875V8.73639ZM11.875 4.75H14.1708C13.9119 3.47437 13.0395 2.42149 11.875 1.9127V4.75ZM3.5625 10.2917C2.90666 10.2917 2.375 10.8233 2.375 11.4792C2.375 12.135 2.90666 12.6667 3.5625 12.6667C4.21834 12.6667 4.75 12.135 4.75 11.4792C4.75 10.8233 4.21834 10.2917 3.5625 10.2917Z"
                        fill="#262262"
                      />
                    </svg>
                  </span>
                  <span className="user-title">DELIVERY REP</span>
                </div>
                <div className="user-name">
                  <p>
                    {orderDetails === null ||
                    orderDetails.driver === undefined ||
                    orderDetails.driver.vehicleType === undefined
                      ? 'Awaiting Delivery Rep'
                      : orderDetails.driver.deliveryRep.fullName}
                  </p>
                </div>
                <div className="user-address">
                  km 24, Lekki-Epe Expressway Ilaje Bus-stop. Ajah
                </div>
                <div className="user-mobile">
                  {orderDetails === null ||
                  orderDetails.driver === undefined ||
                  orderDetails.driver.vehicleType === undefined
                    ? '*******'
                    : orderDetails.driver.deliveryRep.mobile}
                </div>
                <div className="user-email">
                  <p>
                    {orderDetails === null ||
                    orderDetails.driver === undefined ||
                    orderDetails.driver.vehicleType === undefined
                      ? ''
                      : orderDetails.driver.deliveryRep.email}
                  </p>
                </div>
              </div>
            </div>

            <OrderDetailTable
              orderProducts={orderProducts}
              orderStatus={orderStatus}
              updateAt={orderDate}
            />

            <div className="order-total-container">
              <div className="order-total-wrapper">
                <div className="order-charge">
                  <p>Order Charge:</p>
                  <p>{formatMoney(subTotalCost)}</p>
                </div>
                <div className="delivery-charge">
                  <p>Delivery Charge:</p>
                  <p>{formatMoney(orderDeliveryFee)}</p>
                </div>
                <div className="total-charge">
                  <p>Total Charge:</p>
                  <p>{formatMoney(totalCost)}</p>
                </div>
              </div>
            </div>

            {/* <section className="comments">
              <div className="all-comments-container">
                <h3 className="comments-header">Comments</h3>

                <div className="comments-wrapper">
                  <div className="actions">
                    <button className="open-button">OPEN</button>
                    <div onClick={() => toggleMenu(!menu)}>
                      <svg
                        className="comment-action-svg"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  {menu && <ActionMenu />}
                  {comments.map((data, index) => (
                    <div className="comment-container" key={index}>
                      <div className="comment-details">
                        <p>{data.name}</p>
                        <p>{data.dateTime.day}</p>
                        <p>{data.dateTime.time}</p>
                      </div>

                      <div className="comment">
                        <p>{data.comment}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section> */}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

OrderDetails.propTypes = {
  orders: PropTypes.any,
  getAllMerchantIsLoading: PropTypes.bool,
  location: PropTypes.object,
  orderDetails: PropTypes.any
};

const mapStateToProps = state => ({
  orders: state.ordersReducer.getAllOrdersData,
  ordersLoading: state.userReducer.getAllOrdersLoading,
  orderDetails: isEmpty(state.ordersReducer.orderDetails)
    ? null
    : state.ordersReducer.orderDetails.data
});

export default connect(mapStateToProps, null)(OrderDetails);
