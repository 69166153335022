import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { Wrapper } from './style';
import ArrowDown from '../../../assets/arrow-down.svg';
import ArrowUp from '../../../assets/arrow-up.svg';
import PayPause from 'components/Common/PayPause';

class OrderDetailTable extends Component {
  state = {
    open: false,
    selected: {}
  };

  selectItem = el => {
    var selected = this.state.selected;
    selected[el] = !selected[el];
    this.setState({ selected: selected });
  };

  render() {
    const { orderProducts, orderStatus, updateAt } = this.props;

    return (
      <>
        <Wrapper>
          <div className="table-title">Items and Order details</div>

          <div className="table">
            <div className="table-header">
              <div className="header-title">Stores</div>
              <div className="header-title">Product(s)</div>
              <div className="header-title">Quantity</div>
              <div className="header-title">Price</div>
              <div className="header-title">Status</div>
              <div className="header-title">Time</div>
            </div>

            {orderProducts.map((item, index) => {
              const storeName = Object.keys(item)[0];
              return (
                <div className="table-row" key={index}>
                  <div className="table-body">
                    <div className="body-item dropdown">
                      <span
                        className="dropdown-btn"
                        onClick={() => this.selectItem(item[storeName].storeId)}
                        id={index}
                      >
                        <img
                          src={
                            this.state.selected[item[storeName].storeId]
                              ? ArrowUp
                              : ArrowDown
                          }
                          alt="arrow-down"
                        />
                      </span>
                      <div>{storeName}</div>
                    </div>
                    <div className="body-item">
                      {item[storeName].data.length > 1
                        ? `${item[storeName].data.length} products`
                        : `${item[storeName].data.length} product`}
                    </div>
                    <div className="body-item">
                      {item[storeName].productQuantity}
                    </div>
                    <div className="body-item">
                      <span>&#8358;</span>
                      {item[storeName].price.toLocaleString()}
                    </div>
                    <div className="body-item">{orderStatus}</div>
                    <div className="body-item">
                      <div className="table-date">
                        {moment(updateAt).format('LL')}
                      </div>
                      <div className="small-time">
                        {moment(updateAt).format('hh:mm:ss')}
                      </div>
                    </div>
                  </div>

                  {this.state.selected[item[storeName].storeId] && (
                    <div className="body-anchor">
                      {item[storeName].data &&
                        item[storeName].data.map((store, index) => (
                          <div key={index} className="anchor-row">
                            <div className="body-item">
                              {store._id.substring(0, 10)}
                            </div>
                            <div className="body-item">{store.productName}</div>
                            <div className="body-item">{store.quantity}</div>
                            <div className="body-item">
                              <span>&#8358;</span>
                              {store.price.toLocaleString()}
                            </div>
                            <div className="body-item">
                              {store.itemConflict === false &&
                              store.itemAccepted === true
                                ? 'Complete'
                                : 'Incomplete'}
                            </div>
                            <div className="body-item">
                              {' '}
                              <PayPause
                                genericProductId={store.genericProduct?._id}
                                saleId={store?._id}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Wrapper>
      </>
    );
  }
}

OrderDetailTable.propTypes = {
  data: PropTypes.array,
  orderProducts: PropTypes.array,
  subTotalCost: PropTypes.string,
  orderStatus: PropTypes.string,
  updateAt: PropTypes.string
};

export default OrderDetailTable;
