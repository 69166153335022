import { lazy } from 'react';
// import Dashbaord from '../assets/images/dasboard-icons/dashboard.svg';
import Order from '../assets/order.svg';
import Store from '../assets/store.svg';
import Product from '../assets/product.svg';
import Finance from '../assets/finance.svg';
import Delivery from '../assets/delivery.svg';
import User from '../assets/user.svg';
import Settings from '../assets/images/dasboard-icons/settings.svg';
import PrivateRoutes from 'services/privateRoutes';
import TaskOverview from 'pages/logistics/taskManagement/TaskOverview';
import CreateCategory from 'pages/products/Categories/CreateCategory';
import CreateProduct from 'pages/products/CreateProduct/CreateProduct';
import SubCategories from 'pages/products/Categories/SubCategories';

// core components/views for Admin layout

const SettingsPage = lazy(() => import('../pages/settings/Settings'));
const OrdersPage = lazy(() => import('../pages/order/Order'));
const Stores = lazy(() => import('../pages/stores/Stores'));
const ProductsPage = lazy(() => import('../pages/products'));
const DeliveryPage = lazy(() => import('../pages/logistics/Logistics'));
const FinancePage = lazy(() => import('../pages/finance/finance'));
const UsersPage = lazy(() => import('../pages/users/Users'));
// const DashboardPage = lazy(() => import('../pages/dashboard/Dashboard'));
const OrderDetail = lazy(() => import('../pages/OrderDetails/OrderDetails'));
const StoreProductsPage = lazy(() => import('../pages/stores/StoreProducts'));
const StoreWalletPage = lazy(() => import('pages/stores/StoreWalletPage'));
const DriverDetailsPage = lazy(() =>
  import('pages/logistics/driverDetails/DriverDetails')
);
const UserAccountDetails = lazy(() =>
  import('pages/finance/UserAccount/UserAccountDetails/MainPage')
);

const dashboardRoutes = [
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   icon: Dashbaord,
  //   component: PrivateRoutes(DashboardPage),
  //   layout: '/admin',
  //   base: 'true',
  // },
  {
    path: '/orders',
    name: 'Orders',
    icon: Order,
    component: PrivateRoutes(OrdersPage),
    layout: '/admin',
    userDepartment: 'order',
    role: 'admin'
  },
  {
    path: '/stores',
    name: 'Stores',
    icon: Store,
    component: PrivateRoutes(Stores),
    layout: '/admin',
    userDepartment: 'store',
    role: 'admin'
  },
  {
    path: '/products',
    name: 'Products',
    icon: Product,
    component: ProductsPage,
    layout: '/admin'
  },
  {
    path: '/delivery',
    name: 'Delivery',
    icon: Delivery,
    component: PrivateRoutes(DeliveryPage),
    layout: '/admin',
    userDepartment: 'logistic',
    role: 'admin'
  },
  {
    path: '/finance',
    name: 'Finance',
    icon: Finance,
    component: PrivateRoutes(FinancePage),
    layout: '/admin',
    userDepartment: 'finance',
    role: 'admin'
  },
  {
    path: '/users',
    name: 'Users',
    icon: User,
    component: PrivateRoutes(UsersPage),
    layout: '/admin',
    base: true
  },
  {
    path: '/settings',
    name: 'Settings',
    icon: Settings,
    component: PrivateRoutes(SettingsPage),
    layout: '/admin'
  },
  { redirect: true, path: '/', to: '/admin/users', navbarName: 'Redirect' }
];

export const moduleRoutes = [
  {
    path: '/:storeId/products',
    component: PrivateRoutes(StoreProductsPage),
    layout: '/store'
  },
  {
    path: '/:userId/wallet',
    component: PrivateRoutes(StoreWalletPage),
    layout: '/store'
  },
  {
    path: '/:orderId/order-details',
    component: PrivateRoutes(OrderDetail),
    layout: '/order'
  },
  {
    path: '/:deliveryRepId/driver-details',
    component: PrivateRoutes(DriverDetailsPage),
    layout: '/delivery'
  },
  {
    path: '/:taskId/task-details',
    component: PrivateRoutes(TaskOverview),
    layout: '/delivery'
  },
  {
    path: '/:userId/finance-details',
    component: PrivateRoutes(UserAccountDetails),
    layout: '/finance'
  },
  {
    path: '/categories/create',
    component: PrivateRoutes(CreateCategory),
    layout: '/products'
  },
  {
    path: '/:categoryId/subcategories',
    component: PrivateRoutes(SubCategories),
    layout: '/products'
  },
  {
    path: '/create',
    component: PrivateRoutes(CreateProduct),
    layout: '/products'
  }
];

export default dashboardRoutes;
