import AddImageCard from 'components/AddImageCard';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCategory } from 'store/actions/products';
import styled from 'styled-components';
import DotsLoader from 'components/Loaders/DotsLoader';
import CreateSubcategory from './CreateSubcategory';
import { CREATE_CATEGORY_ERROR } from 'store/actions/types';
import ToggleButton from 'components/ToggleButton';

const CreateCategory = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const { creatingCategory, errorMessage, categoryCreated } = useSelector(
    state => state.productsReducer
  );

  const [data, setData] = useState({
    categoryName: '',
    commission: 0,
    image: null,
    properties: [],
    isVisibleToAll: false
  });
  const [property, setProperty] = useState({ name: '' });
  const [propertyError, setPropertyError] = useState('');
  let formData = new FormData();

  const handleChange = e => {
    setData(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      };
    });
  };

  const handleVisibilityToggle = val => {
    setData(prev => {
      return {
        ...prev,
        isVisibleToAll: val
      };
    });
  };

  const categoryNameKeyDown = e => {
    const r = /[^a-zA-Z0-9-]/g;

    setData(prev => {
      return {
        ...prev,
        categoryName: e.target.value.replace(r, '')
      };
    });
  };
  const propertyNameKeyDown = e => {
    const r = /[^a-zA-Z0-9-]/g;

    setProperty({ name: e.target.value.replace(r, '') });
  };

  const handleProperty = e => {
    setPropertyError('');
    setProperty({ name: e.target.value });
  };
  const addProperty = () => {
    if (property.name === '') {
      setPropertyError('Property name cannot be empty');
      return;
    }
    setData(prev => {
      if (
        prev.properties.some(
          prop => prop.name.toLowerCase() === property.name.toLowerCase()
        )
      ) {
        setPropertyError('Property name already exist');

        return {
          ...prev
        };
      }
      return {
        ...prev,
        properties: [...prev.properties, property]
      };
    });
    setProperty({ name: '' });
  };
  const handleSubmit = async () => {
    formData.append('categoryName', data.categoryName);
    formData.append('commission', data.commission);
    formData.append('image', data.image);
    formData.append('properties', JSON.stringify(data.properties));
    formData.append('isVisibleToAll', data.isVisibleToAll);
    await dispatch(createCategory(formData));

    setData({
      categoryName: '',
      commission: 0,
      image: null,
      properties: []
    });
  };

  const setImage = img => {
    setData(prev => {
      return {
        ...prev,
        image: img
      };
    });
  };

  const closeModal = () => {
    setOpenModal(false);
    setPropertyError('');
  };

  useEffect(() => {
    dispatch({
      type: CREATE_CATEGORY_ERROR,
      payload: ''
    });
  }, []);

  return (
    <CreateCategoryContainer>
      {!categoryCreated && (
        <div className="create-category-wrapper">
          <section className="category-name">
            <h4>Create Category</h4>
            <div className="name-wrapper">
              <p>Category:</p>
              <input
                name="categoryName"
                value={data.categoryName}
                onChange={handleChange}
                type="text"
                placeholder="Category Name"
                onKeyUp={categoryNameKeyDown}
              />
            </div>
          </section>
          <section className="add-properties">
            <div className="add-properties-text">
              <h5>Add Properties</h5>
              <p>
                These are the options related to this category e.g size, color,
                memory size
              </p>
            </div>
            <div className="properties">
              {data.properties.map((property, ind) => (
                <p className="property-item" key={ind}>
                  <span>
                    <svg
                      width="12"
                      height="16"
                      viewBox="0 0 12 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.75 0.5H2.25C1.00736 0.5 0 
                    1.50736 0 2.75V13.25C0 14.4926 1.00736 15.5 2.25 15.5H9.75C10.9926 15.5 12 
                    14.4926 12 13.25V2.75C12 1.50736 10.9926 0.5 9.75 0.5ZM1.5 2.75C1.5 2.33579 
                    1.83579 2 2.25 2H3.75V2.75C3.75 3.16421 4.08579 3.5 4.5 3.5C4.91421 3.5 5.25 
                    3.16421 5.25 2.75V2H9.75C10.1642 2 10.5 2.33579 10.5 2.75V13.25C10.5 13.6642 
                    10.1642 14 9.75 14H2.25C1.83579 14 1.5 13.6642 1.5 13.25V2.75ZM4.5 7.25C4.08579 
                    7.25 3.75 6.91421 3.75 6.5V5.75C3.75 5.33579 4.08579 5 4.5 5C4.91421 5 5.25 
                    5.33579 5.25 5.75V6.5C5.25 6.91421 4.91421 7.25 4.5 7.25ZM3.75 10.25C3.75 10.6642 
                    4.08579 11 4.5 11C4.91421 11 5.25 10.6642 5.25 10.25V9.5C5.25 9.08579 4.91421 
                    8.75 4.5 8.75C4.08579 8.75 3.75 9.08579 3.75 9.5V10.25Z"
                        fill="#413D45"
                      />
                    </svg>
                  </span>
                  <span>{property?.name}</span>
                </p>
              ))}
            </div>
            <div onClick={() => setOpenModal(true)} className="new-prop-link">
              <div>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.89576 1.08632C7.89576 0.624434 7.52132 0.25 
                    7.05944 0.25C6.59755 0.25 6.22312 0.624433 6.22312 1.08632L6.22311 6.10424L1.08632 
                    6.10424C0.624434 6.10424 0.25 6.47868 0.25 6.94056C0.25 7.40245 0.624434 7.77688 1.08632 
                    7.77689H6.22311L6.22311 12.9137C6.22311 13.3756 6.59755 13.75 7.05943 13.75C7.52132 13.75 
                    7.89576 13.3756 7.89575 12.9137L7.89576 7.77689H12.9137C13.3756 7.77689 13.75 7.40245 13.75 
                    6.94057C13.75 6.47868 13.3756 6.10425 12.9137 6.10425L7.89576 6.10424L7.89576 1.08632Z"
                    fill="#413D45"
                  />
                </svg>
              </div>
              <p>New Properties</p>
            </div>
            <div
              className={`add-properties-modal ${openModal ? 'openModal' : ''}`}
            >
              <div className="add-properties-box">
                <span onClick={closeModal} className="close-modal-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style={{ fill: '#262262', transform: ';msFilter:;' }}
                  >
                    <path
                      d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 
                4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
                    ></path>
                  </svg>
                </span>
                <p>Property Name</p>
                <input
                  value={property.name}
                  onChange={handleProperty}
                  type="text"
                  placeholder="Property"
                  onKeyDown={propertyNameKeyDown}
                />
                <div onClick={addProperty} className="add-property-button">
                  <button>ADD PROPERTY</button>
                </div>
                <p className="property-error">{propertyError}</p>
              </div>
            </div>
          </section>
          <section className="category-commission">
            <div className="category-commission-wrap">
              <div className="category-commission-item-left">
                <h6 className="item-head">Category Commission</h6>
                <p className="item-desc">
                  This is the commission to be charged on all the products in
                  this category.
                </p>
              </div>

              <div className="category-commission-item-right">
                <p className="item-symbol">%</p>
                <input
                  name="commission"
                  value={data.commission}
                  onChange={handleChange}
                  type="number"
                />
              </div>
            </div>
          </section>
          <section className="upload-img-section">
            <div className="upload-wrapper">
              <h4>Upload Image</h4>
              <div className="drag-area">
                <AddImageCard readImgToParent={setImage} />
              </div>
            </div>
          </section>
          <section className="visibility-section">
            <div className="visibility-wrapper">
              <div className="visibility-text">
                <h4>Visibility Status</h4>
                <p>
                  By Clicking the toggle ON, you are making this product visible
                  to all user
                </p>
              </div>

              <ToggleButton toggleValue={handleVisibilityToggle} />
            </div>
          </section>
          <section className="submit-button">
            <div className="submit-button-wrapper">
              <button onClick={handleSubmit} disabled={creatingCategory}>
                {creatingCategory && <DotsLoader />}
                <span className="button-text">
                  {creatingCategory ? 'Creating...' : 'Create'}
                </span>
              </button>
            </div>
            <div className="errorMessage">{errorMessage}</div>
          </section>
        </div>
      )}
      {categoryCreated && <CreateSubcategory />}
    </CreateCategoryContainer>
  );
};

const CreateCategoryContainer = styled.div`
  height: calc(100vh - 86px);
  background-color: #e5e5e5;
  overflow: auto;
  color: #413d45;
  font-size: 14px;
  padding-bottom: 10px;

  p {
    margin: 0;
  }

  .create-category-wrapper {
    width: 700px;

    background-color: #fff;
    margin: 10px auto 0;
    border-radius: 8px;

    section {
    }
    section:not(:last-child) {
      border-bottom: 1px solid #c4c4c4;
    }

    .category-name {
      padding: 20px 30px;
      h4 {
        color: #262262;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 30px;
      }
      .name-wrapper {
        display: flex;
        align-tems: center;
      }
      p {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
      }
      input {
        display: inline-block;
        width: 350px;
        margin-left: 50px;
        padding: 8px;
        margin-left: 70px;
        border: 1px solid #a1a4b1;
        border-radius: 6px;
      }
    }

    .add-properties {
      padding-bottom: 20px;

      .add-properties-text {
        padding: 20px 30px 0;

        font-size: 12px;
      }
      .properties {
        padding: 0 30px;
        margin-bottom: 15px;

        .property-item {
          display: flex;
          align-items: center;
          margin-top: 12px;

          span:last-child {
            margin-left: 16px;
          }
        }
      }
      .new-prop-link {
        display: flex;
        align-items: center;
        background-color: #f5f5f5;
        padding: 8px 30px;
        font-size: 10px;
        cursor: pointer;

        p {
          margin-left: 12px;
          color: #b2b1b1;
          font-weight: 500;
        }
      }

      .add-properties-modal {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 99;
        display: none;
        justify-content: center;
        align-items: center;

        .add-properties-box {
          width: 400px;
          height: 250px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;

          .close-modal-icon {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #fff;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -8px;
            right: -8px;
            cursor: pointer;
          }

          p {
            margin-bottom: 10px;
            width: 70%;
            text-align: left;
            font-size: 18px;
          }

          input {
            padding: 8px;
            width: 70%;
          }
        }
        .add-property-button {
          padding-top: 15px;
          width: 70%;

          button {
            padding: 5px 25px;
            border: none;
            background-color: #262262;
            color: #fff;
            font-weight: 600;
            border-radius: 4px;
            width: 100%;
          }
        }
        p.property-error {
          font-size: 12px;
          color: red;
          margin-top: 15px;
          height: 20px;
          text-align: center;
        }
      }
      .add-properties-modal.openModal {
        display: flex;
      }
    }
    .category-commission {
      padding: 20px 30px;

      .category-commission-wrap {
        display: flex;
        align-items: center;

        .category-commission-item-left {
          max-width: 250px;

          h6 {
            color: #1a171e;
            font-size: 15px;
            font-weight: 500;
          }
          p {
            color: #666666;
            font-size: 13px;
          }
        }
        .category-commission-item-right {
          display: flex;
          height: 48px;
          width: 170px;
          border: 1px solid #a1a4b1;
          border-radius: 8px;
          overflow: hidden;
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
          margin-left: 70px;

          p {
            padding: 6px 13px;
            background-color: #f0f0f0;
            margin-bottom: 0;
            border-right: 1px solid #a1a4b1;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          input {
            border: none;
            font-size: 1.5rem;
          }
          input:focus {
            outline: none;
          }
        }
      }
    }

    .upload-img-section {
      padding: 20px 30px;
      .upload-wrapper {
        h4 {
          font-size: 15px;
          margin-bottom: 20px;
        }
        .drag-area {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .visibility-section {
      padding: 20px 30px;
      .visibility-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .visibility-text {
        max-width: 300px;

        h4 {
          font-size: 20px;
        }
        p {
          font-size: 12px;
        }
      }
    }

    .submit-button {
      padding: 20px 30px;
      .errorMessage {
        color: red;
        text-align: center;
        margin-bottom: 5px;
      }
      .submit-button-wrapper {
        display: flex;
        justify-content: center;
        button {
          padding: 6px 0;
          border: none;
          background-color: #262262;
          font-size: 18px;
          color: #fff;
          border-radius: 5px;
          width: 180px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        button:disabled {
          cursor: not-allowed;
          background-color: #262262d8;
        }
      }
    }
  }
`;

export default CreateCategory;
