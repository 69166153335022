import React from 'react';
import './Loader.css';

const LoaderSmall = () => {
  return (
    <div className="dots-container">
      <div className="dot dot-1"></div>
      <div className="dot dot-2"></div>
      <div className="dot dot-3"></div>
    </div>
  );
};

export default LoaderSmall;
