import styled from 'styled-components';

export const Wrapper = styled.div`
  .table-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    color: #262262;
    margin-bottom: 20px;
  }

  .table-header {
    width: 100%;
    height: 48px;
    background: #f0f0f0;
    display: grid;
    grid-template-columns: 2fr 3fr 1fr 1fr 1fr 1fr;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .header-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    color: #413d45;
  }

  .table-body {
    width: 100%;
    height: 48px;
    display: grid;
    grid-template-columns: 2fr 3fr 1fr 1fr 1fr 1fr;
    border-bottom: 1px solid #d6d6d6;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 13px;
  }

  .body-item {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    align-items: center;
    color: #413d45;
  }

  .table-date {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    align-items: center;
    color: #413d45;
  }

  .small-time {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 21px;
    align-items: center;
    color: #413d45;
  }

  .dropdown {
    display: flex;
  }

  .dropdown-btn {
    margin-right: 7px;
    cursor: pointer;
  }

  .anchor-row {
    width: 100%;
    height: 75px;
    display: grid;
    display: grid;
    grid-template-columns: 2fr 3fr 1fr 1fr 1fr 1fr;
    border-bottom: 1px solid #d6d6d6;
    align-items: center;
    padding-left: 25px;
    padding-right: 20px;
    background: #f3f3f3;
  }

  .action-btn {
    border: none;
    background: #f3f3f3;
    width: 18px;
    height: 18px;
    outline: none;
    position: relative;
  }
  .pop-up {
    position: absolute;
    width: 150px;
    padding: 10px;
    background-color: #fff;
    left: -5px;
    z-index: 100;
  }
  .pop-up p {
    transition: all 0.1s ease-in-out;
  }
  .pop-up p:hover {
    background: #262262;
    color: #fff;
    font-weight: 600;
  }

  .more-icon {
    height: 100%;
  }
`;
