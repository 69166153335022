/* eslint-disable no-console */
import { axios } from 'services/axios';
import { BASE_URL } from 'utils/constants';
import {
  GET_CATEGORIES_ERROR,
  GET_PRODUCTS_CATEGORIES,
  CREATING_CATEGORY,
  CREATE_CATEGORY_ERROR,
  CATEGORY_CREATED,
  SUB_CATEGORY_CREATED,
  GET_BRANDS,
  GET_BRANDS_ERROR,
  CREATING_PRODUCT,
  PRODUCT_CREATED,
  CREATING_PRODUCT_ERROR,
  CREATING_BRAND,
  CREATING_BRAND_ERROR,
  BRAND_CREATED,
  GET_GENERIC_PRODUCTS,
  GET_GENERIC_PRODUCTS_ERROR,
  GET_PRODUCTS_IN_STORE,
  GET_PRODUCTS_IN_STORE_ERROR,
  GET_PRODUCT_TAB_COUNT,
  GET_PARENT_CATEGORIES,
  PRODUCTS_LOADING,
  GET_PRODUCTS_SUBCATEGORIES,
  GET_REQUESTS_TO_ADMIN
} from '../types';
import toastr from 'toastr';

export const fetchTabsCount = () => async dispatch => {
  try {
    const {
      data: {
        meta: { total }
      }
    } = await axios.get(`${BASE_URL.PRODUCT}/v1/categories/all`);
    dispatch({
      type: GET_PRODUCT_TAB_COUNT,
      payload: {
        name: 'categoriesCount',
        value: total
      }
    });
    console.log(total);
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_TAB_COUNT,
      payload: {
        name: 'categoriesCount',
        value: 0
      }
    });
  }

  try {
    const {
      data: {
        meta: { total }
      }
    } = await axios.get(`${BASE_URL.PRODUCT}/v1/brands/all?limit=20&skip=0`);
    dispatch({
      type: GET_PRODUCT_TAB_COUNT,
      payload: {
        name: 'brandsCount',
        value: total
      }
    });
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_TAB_COUNT,
      payload: {
        name: 'brandsCount',
        value: 0
      }
    });
  }

  try {
    const {
      data: {
        meta: { total }
      }
    } = await axios.get(
      `${BASE_URL.PRODUCT}/v1/storeproducts/all/products?limit=10&skip=0`
    );
    dispatch({
      type: GET_PRODUCT_TAB_COUNT,
      payload: {
        name: 'storeProductsCount',
        value: total
      }
    });
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_TAB_COUNT,
      payload: {
        name: 'storeProductsCount',
        value: 0
      }
    });
  }
  try {
    const {
      data: {
        meta: { total }
      }
    } = await axios.get(
      `${BASE_URL.PRODUCT}/v1/admin/all/genericproducts?page=1&limit=10&skip=0`
    );
    dispatch({
      type: GET_PRODUCT_TAB_COUNT,
      payload: {
        name: 'genericProductsCount',
        value: total
      }
    });
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_TAB_COUNT,
      payload: {
        name: 'genericProductsCount',
        value: 0
      }
    });
  }
  try {
    const {
      data: {
        meta: { total }
      }
    } = await axios.get(
      `${BASE_URL.PRODUCT}/v1/all/status/genericproducts?page=1&limit=10&skip=0&status=APPROVED`
    );
    dispatch({
      type: GET_PRODUCT_TAB_COUNT,
      payload: {
        name: 'activeProductsCount',
        value: total
      }
    });
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_TAB_COUNT,
      payload: {
        name: 'activeProductsCount',
        value: 0
      }
    });
  }

  try {
    const {
      data: {
        meta: { total }
      }
    } = await axios.get(
      `${BASE_URL.PRODUCT}/v1/all/status/genericproducts?page=1&limit=10&skip=0&status=DISAPPROVED`
    );
    dispatch({
      type: GET_PRODUCT_TAB_COUNT,
      payload: {
        name: 'disabledProductsCount',
        value: total
      }
    });
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_TAB_COUNT,
      payload: {
        name: 'disabledProductsCount',
        value: 0
      }
    });
  }
};

const loading = status => ({
  type: PRODUCTS_LOADING,
  payload: status
});

export const fetchProductsCategories = (
  limit,
  page,
  dateFilter
) => async dispatch => {
  const { specificDate, startDate, endDate } = dateFilter;
  let params = {
    page,
    limit,
    skip: 0,
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate })
  };

  dispatch(loading(true));
  try {
    const { data } = await axios.get(
      `${BASE_URL.PRODUCT}/v1/all/parentcategories`,
      {
        params
      }
    );
    dispatch({
      type: GET_PRODUCTS_CATEGORIES,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: GET_CATEGORIES_ERROR,
      payload: 'There was an error fetching categories'
    });
  } finally {
    dispatch(loading(false));
  }
};
export const fetchParentProductsCategories = () => async dispatch => {
  try {
    const { data } = await axios.get(
      `${BASE_URL.PRODUCT}/v1/categories/all/getall`
    );
    //console.log(data);
    dispatch({
      type: GET_PARENT_CATEGORIES,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: GET_CATEGORIES_ERROR,
      payload: 'There was an error fetching Parent categories'
    });
  } finally {
    dispatch(loading(false));
  }
};

export const fetchBrands = (limit, page, dateFilter = {}) => async dispatch => {
  console.log('@@@: ', dateFilter);
  const { specificDate, startDate, endDate } = dateFilter;
  let params = {
    page,
    limit,
    skip: 0,
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate })
  };
  dispatch(loading(true));
  try {
    const { data } = await axios.get(`${BASE_URL.PRODUCT}/v1/brands/all`, {
      params
    });
    dispatch({
      type: GET_BRANDS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: GET_BRANDS_ERROR,
      payload: 'There was an error fetching brands'
    });
  } finally {
    dispatch(loading(false));
  }
};

export const fetchSubCategories = id => async dispatch => {
  try {
    const { data } = await axios.get(
      `${BASE_URL.PRODUCT}/v1/subcategories/${id}`
    );
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const createCategory = formData => async dispatch => {
  try {
    dispatch({
      type: CREATING_CATEGORY,
      payload: true
    });
    dispatch({
      type: CREATE_CATEGORY_ERROR,
      payload: ''
    });
    const { data } = await axios({
      method: 'post',
      url: `${BASE_URL.PRODUCT}/v1/categories/add`,
      data: formData
    });
    dispatch({
      type: CREATING_CATEGORY,
      payload: false
    });
    dispatch({
      type: CATEGORY_CREATED,
      payload: true,
      categoryId: data.data.categoryId
    });
  } catch (error) {
    dispatch({
      type: CREATING_CATEGORY,
      payload: false
    });
    dispatch({
      type: CATEGORY_CREATED,
      payload: false,
      categoryId: null
    });
    if (error.response) {
      const { data } = error.response;
      dispatch({
        type: CREATE_CATEGORY_ERROR,
        payload: data?.message
      });
    } else {
      dispatch({
        type: CREATE_CATEGORY_ERROR,
        payload: 'Network Error'
      });
    }
  }
};

export const approveCategory = categoryId => async dispatch => {
  try {
    const { data } = await axios({
      method: 'patch',
      url: `${BASE_URL.PRODUCT}/v1/categories/status/update/${categoryId}`,
      data: { comment: 'status changed', status: 'ACTIVE' }
    });
    console.log(data);
  } catch (error) {
    console.log(error);
  }
};
export const disApproveCategory = categoryId => async dispatch => {
  try {
    const { data } = await axios({
      method: 'patch',
      url: `${BASE_URL.PRODUCT}/v1/categories/status/update/${categoryId}`,
      data: { comment: 'status changed', status: 'BLOCK' }
    });
    console.log(data);
  } catch (error) {
    if (error.response) {
      console.log(error.response);
    } else {
      console.log(error);
    }
  }
};

export const exit = () => dispatch => {
  dispatch({
    type: CATEGORY_CREATED,
    payload: false,
    categoryId: null
  });
};

export const createSubcategory = formData => async dispatch => {
  try {
    dispatch({
      type: CREATING_CATEGORY,
      payload: true
    });

    const { data } = await axios({
      method: 'post',
      url: `${BASE_URL.PRODUCT}/v1/subcategories/add`,
      data: formData
    });

    dispatch({
      type: CREATING_CATEGORY,
      payload: false
    });

    dispatch({
      type: SUB_CATEGORY_CREATED
    });
  } catch (error) {
    dispatch({
      type: CREATING_CATEGORY,
      payload: false
    });

    if (error.response) {
      const { data } = error.response;
      console.log(data);
      dispatch({
        type: CREATING_CATEGORY,
        payload: false
      });
    } else {
      dispatch({
        type: CREATING_PRODUCT_ERROR,
        payload: 'Network Error'
      });
    }
  }
};

export const createProduct = formData => async dispatch => {
  try {
    dispatch({
      type: CREATING_PRODUCT_ERROR,
      payload: ''
    });
    dispatch({
      type: CREATING_PRODUCT
    });
    const { data } = await axios({
      method: 'post',
      url: `${BASE_URL.PRODUCT}/v1/product/add`,
      data: formData
    });
    dispatch({ type: PRODUCT_CREATED });
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      console.log(data);
      dispatch({
        type: CREATING_PRODUCT_ERROR,
        payload: data.message
      });
    } else {
      dispatch({
        type: CREATING_PRODUCT_ERROR,
        payload: 'Network Error'
      });
    }
  }
};

export const edit_Brand = (brandId, payload) => async dispatch => {
  try {
    const { data } = await axios({
      method: 'put',
      url: `${BASE_URL.PRODUCT}/v1/brands/update/${brandId}`,
      data: payload
    });

    toastr.success('brand edited successfully');
  } catch (error) {
    console.log(error);
  }
};

export const createBrand = brandName => async dispatch => {
  try {
    dispatch({ type: CREATING_BRAND });
    const { data } = await axios({
      method: 'post',
      url: `${BASE_URL.PRODUCT}/v1/brands/add`,
      data: brandName
    });
    dispatch({ type: BRAND_CREATED, payload: data });
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      dispatch({ type: CREATING_BRAND_ERROR, payload: data.message });
    } else {
      dispatch({ type: CREATING_BRAND_ERROR, payload: 'Network Error' });
    }
  }
};

export const fetchGenericProducts = (
  limit,
  page,
  status,
  dateFilter = {}
) => async dispatch => {
  const { specificDate, startDate, endDate } = dateFilter;
  let params = {
    page,
    limit,
    skip: 0,
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate }),
    ...(status !== '' && { status })
  };
  dispatch(loading(true));
  try {
    if (!status) {
      const { data } = await axios.get(
        `${BASE_URL.PRODUCT}/v1/admin/all/genericproducts`,
        {
          params
        }
      );
      dispatch({
        type: GET_GENERIC_PRODUCTS,
        payload: data
      });
    } else {
      const { data } = await axios.get(
        `${BASE_URL.PRODUCT}/v1/all/status/genericproducts`,
        {
          params
        }
      );
      dispatch({
        type: GET_GENERIC_PRODUCTS,
        payload: data
      });
    }
  } catch (error) {
    dispatch({
      type: GET_GENERIC_PRODUCTS_ERROR,
      payload: 'An error occured when fetching generic products'
    });
  } finally {
    dispatch(loading(false));
  }
};

export const fetchProductsInStore = (limit, page) => async dispatch => {
  dispatch(loading(true));
  const params = {
    limit,
    page,
    skip: 0
  };
  try {
    const { data } = await axios(
      `${BASE_URL.PRODUCT}/v1/storeproducts/all/products`,
      {
        params
      }
    );
    dispatch({
      type: GET_PRODUCTS_IN_STORE,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: GET_PRODUCTS_IN_STORE_ERROR,
      payload: 'An error occured when fetching products in stores'
    });
  } finally {
    dispatch(loading(false));
  }
};

export const productsByAdmin = (
  limit,
  page,
  status,
  dateFilter
) => async dispatch => {
  const { specificDate, startDate, endDate } = dateFilter;
  let params = {
    page,
    limit,
    status,
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate })
  };
  try {
    const { data } = await axios.get(
      `${BASE_URL.PRODUCT}/v1/all/status/genericproducts`,
      {
        params
      }
    );
    return data;
  } catch (err) {
    if (err.response) {
      console.log(err.response);
    } else {
      console.log('network error');
    }
  }
};
export const productsByMerchants = (
  limit,
  page,
  status,
  dateFilter
) => async dispatch => {
  const { specificDate, startDate, endDate } = dateFilter;
  let params = {
    limit,
    page,
    status,
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate })
  };
  try {
    const { data } = await axios.get(
      `${BASE_URL.PRODUCT}/v1/brandproducts/all/status`,
      {
        params
      }
    );
    return data;
  } catch (err) {
    if (err.response) {
      console.log(err.response);
    } else {
      console.log('network error');
    }
  }
};

export const approveProduct = id => async dispatch => {
  console.log('Id>>>', id);
  try {
    const { data } = await axios({
      method: 'put',
      url: `${BASE_URL.PRODUCT}/v1/genericproducts/status/product/${id}`,
      data: {
        status: 'APPROVED',
        comment: 'product approved'
      }
    });
    console.log(data);
  } catch (err) {
    if (err.response) {
      console.log(err.response);
    } else {
      console.log('network error');
    }
  }
};
export const disApproveProduct = id => async dispatch => {
  try {
    const { data } = await axios({
      method: 'put',
      url: `${BASE_URL.PRODUCT}/v1/genericproducts/status/product/${id}`,
      data: {
        status: 'DISAPPROVED',
        comment: 'product disapproved'
      }
    });
    console.log(data);
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      return new Error(err);
    } else {
      console.log('network error');
    }
  }
};

export const fetchProductsSubCategories = categoryId => async dispatch => {
  try {
    const {
      data: { data }
    } = await axios(`${BASE_URL.PRODUCT}/v1/subcategories/${categoryId}`);

    dispatch({
      type: GET_PRODUCTS_SUBCATEGORIES,
      payload: data
    });
  } catch (error) {
    if (error.response) {
      console.log(error.response);
    } else {
      console.log('network error');
    }
  }
};

export const approveBrandProduct = (
  productId,
  brandId,
  brand
) => async dispatch => {
  console.log('BrandProdID>>>:', productId, brandId, brand);
  try {
    const { data } = await axios({
      method: 'put',
      url: `${BASE_URL.PRODUCT}/v1/brandproducts/status/update/${productId}`,
      data: {
        status: 'APPROVED',
        comment: 'product approved',
        brandId: brandId,
        brandName: brand
      }
    });
    console.log(data);
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      // toastr.error('We could not approve at the moment.');
      // return new Error(err);
    } else {
      console.log('network error');
    }
  }
};
export const disapproveBrandProduct = (
  productId,
  brandId,
  brand
) => async dispatch => {
  console.log({ productId });
  try {
    const { data } = await axios({
      method: 'put',
      url: `${BASE_URL.PRODUCT}/v1/brandproducts/status/update/${productId}`,
      data: {
        status: 'DISAPPROVED',
        comment: 'product disapproved',
        brandId,
        brandName: brand
      }
    });
    console.log(data);
  } catch (err) {
    if (err.response) {
      console.log(err.response);
      toastr.error('We could not disapprove at the moment.');
      return new Error(err);
    } else {
      console.log('network error');
    }
  }
};

export const getRequestsToAdmin = (limit, page) => async dispatch => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${BASE_URL.PRODUCT}/v1/admin/request`,
      params: {
        limit,
        page
      }
    });
    return data;
  } catch (err) {
    if (err.response) {
      console.log(err.response);
    } else {
      console.log('network error');
    }
  }
};
