import React from 'react';
import defaultImg from 'assets/images/subcat.png';
import more from 'assets/images/more-light.svg';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from './Modal';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledSubCategory = styled.li`
  width: 183px;
  height: 160px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 1;
  }
  .more {
    position: absolute;
    top: 7%;
    right: 7%;
    cursor: pointer;
    z-index: 1;
  }
  .overlay {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    border-radius: 5px;
  }
  .subcat-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

export const SubCategory = ({ subCat, ...others }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <StyledSubCategory>
      <div className="more" onClick={() => setIsOpen(!isOpen)}>
        <img src={more} alt="more" />
      </div>
      <Link
        to={`/products/${subCat.categoryId}/subcategories`}
        className="subcat"
      >
        {/* <p>{subCat.categoryName}</p> */}
        <p>{subCat.categoryId}</p>
        <div className="overlay"></div>
        <div className="subcat-img">
          <img src={subCat?.image || defaultImg} alt="subcat" />
        </div>
        <Modal
          className="modal"
          isModalOpen={isOpen}
          setIsModalOpen={setIsOpen}
        />
      </Link>
    </StyledSubCategory>
  );
};
SubCategory.propTypes = {
  subCat: PropTypes.object
};
