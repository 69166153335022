import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 86px;

  .main-menu-wrap {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-flex {
    display: flex;
    align-items: center;
  }

  .user-name {
    margin-left: 20px;
    display: flex;
  }

  .expand {
    cursor: pointer;
  }
  .dropDown {
    background: white;
    border: none;
  }
  .logOutIcon {
    height: 50px;
    font-family: 'Poppins'
  }
  .storeHeaderNameContainer {
    width: 80%;
    border-right: 1px solid #c4c4c4;
    position: relative
  }
  .storeHeaderText {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */


    /* Functional Color/Primary_Color */

    color: #413D45;
    opacity: 0.7;
  }
  .storeNameHeaderText {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */


    /* Functional Color/Secondary_color */

    color: #262262;
  }
`;
