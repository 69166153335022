import isEmpty from 'is-empty';
import {
  GET_STORES,
  GET_STORES_IS_LOADING,
  GET_ADMIN_STORE_REQUEST,
  GET_ADMIN_STORE_REQUEST_IS_LOADING,
  GET_STORE_PRODUCTS,
  GET_STORE_PRODUCTS_IS_LOADING,
  GET_SINGLE_STORE_DETAILS,
  GET_SINGLE_STORE_DETAILS_IS_LOADING,
  GET_SINGLE_STORE_TRANSACTIONS,
  GET_SINGLE_STORE_TRANSACTIONS_IS_LOADING,
  GET_AVAILABLE_BALANCE,
  GET_AVAILABLE_BALANCE_IS_LOADING,
  ACT_ON_STORE,
  ACT_ON_STORE_ERROR,
  ACT_ON_STORE_IS_LOADING,
  REMOVE_ACT_ON_STORE_ERROR,
  GET_STORE_TABS_COUNT
} from 'store/actions/types';

const initialState = {
  stores: {},
  getStoresIsLoading: false,
  storeAdminRequests: {},
  getAdminStoreRequestIsLoading: false,
  storeProducts: {},
  getStoreProductIsLoading: false,
  singleStoreDetails: {},
  singleStoreDetailsIsLoading: false,
  storeTransactions: {},
  storeTransactionsIsLoading: false,
  storeAvailableBalance: {},
  storeAvailableBalanceIsLoading: false,
  actOnStoreSuccess: false,
  actOnStorePayload: {},
  actOnStoreError: null,
  actOnStoreIsLoading: false,
  storeTabsCount: {}
};

const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STORES:
      return {
        ...state,
        stores: action.storeData,
        getStoresIsLoading: false
      };
    case GET_STORES_IS_LOADING:
      return {
        ...state,
        getStoresIsLoading: true
      };
    case GET_ADMIN_STORE_REQUEST:
      return {
        ...state,
        storeAdminRequests: action.storeAdminRequestData,
        getAdminStoreRequestIsLoading: false
      };
    case GET_ADMIN_STORE_REQUEST_IS_LOADING:
      return {
        ...state,
        getAdminStoreRequestIsLoading: true
      };
    case GET_STORE_PRODUCTS:
      return {
        ...state,
        storeProducts: action.storeProductData,
        getStoreProductIsLoading: false
      };
    case GET_STORE_PRODUCTS_IS_LOADING:
      return {
        ...state,
        getStoreProductIsLoading: true
      };
    case GET_SINGLE_STORE_DETAILS:
      return {
        ...state,
        singleStoreDetails: action.singleStoreData,
        singleStoreDetailsIsLoading: false
      };
    case GET_SINGLE_STORE_DETAILS_IS_LOADING:
      return {
        ...state,
        singleStoreDetailsIsLoading: true
      };
    case GET_SINGLE_STORE_TRANSACTIONS:
      return {
        ...state,
        storeTransactions: action.storeTransactionData,
        storeTransactionsIsLoading: false
      };
    case GET_SINGLE_STORE_TRANSACTIONS_IS_LOADING:
      return {
        ...state,
        storeTransactionsIsLoading: true
      };
    case GET_AVAILABLE_BALANCE:
      return {
        ...state,
        storeAvailableBalance: action.availableBalance,
        storeAvailableBalanceIsLoading: false
      };
    case GET_AVAILABLE_BALANCE_IS_LOADING:
      return {
        ...state,
        storeAvailableBalanceIsLoading: true
      };
    case ACT_ON_STORE:
      return {
        ...state,
        actOnStorePayload: action.actOnStoreData,
        actOnStoreSuccess: !isEmpty(action.actOnStoreData),
        actOnStoreIsLoading: false
      };
    case ACT_ON_STORE_ERROR:
      return {
        ...state,
        actOnStoreError: action.actOnStoreError,
        actOnStoreIsLoading: false,
        actOnStorePayload: {}
      };
    case ACT_ON_STORE_IS_LOADING:
      return {
        ...state,
        actOnStoreIsLoading: true
      };
    case REMOVE_ACT_ON_STORE_ERROR:
      return {
        ...state,
        actOnStoreError: null
      };
    case GET_STORE_TABS_COUNT: {
      return {
        ...state,
        storeTabsCount: {
          ...state.storeTabsCount,
          [action.payload.name]: action.payload.value
        }
      };
    }
    default:
      return state;
  }
};

export default storeReducer;
