import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchParentProductsCategories,
  fetchSubCategories
} from 'store/actions/products';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Choosecategory = ({ setCategoryId, setCategoryProps, setSubCatIds }) => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [subcategories, setSubcategories] = useState({
    level1: [],
    level2: [],
    level3: [],
    level4: [],
    level5: [],
    level6: [],
    level7: []
  });
  const [active, setActive] = useState({
    level1: null,
    level2: null,
    level3: null,
    level4: null,
    level5: null,
    level6: null,
    level7: null,
    level8: null
  });
  const { parentCategories } = useSelector(state => state.productsReducer);
  const dispatch = useDispatch();

  const activeCategorySetter = (id, level) => {
    setActive(prev => {
      switch (level) {
        case 'level1':
          return {
            level1: id,
            level2: null,
            level3: null,
            level4: null,
            level5: null,
            level6: null,
            level7: null,
            level8: null
          };
        case 'level2':
          return {
            ...prev,
            level2: id,
            level3: null,
            level4: null,
            level5: null,
            level6: null,
            level7: null,
            level8: null
          };

        case 'level3':
          return {
            ...prev,
            level3: id,
            level4: null,
            level5: null,
            level6: null,
            level7: null,
            level8: null
          };

        case 'level4':
          return {
            ...prev,
            level4: id,
            level5: null,
            level6: null,
            level7: null
          };

        case 'level5':
          return {
            ...prev,
            level5: id,
            level6: null,
            level7: null,
            level8: null
          };

        case 'level6':
          return {
            ...prev,
            level6: id,
            level7: null,
            level8: null
          };

        case 'level7':
          return {
            ...prev,
            level7: id,
            level8: null
          };
        case 'level8':
          return {
            ...prev,
            level8: id
          };

        default:
          return {
            ...prev
          };
      }
    });
  };

  const handleClick = async (item, level) => {
    activeCategorySetter(item.categoryId, level);
    setCategoryProps(item.properties === undefined ? [] : item.properties);
    let data = await dispatch(fetchSubCategories(item.categoryId));
    setSubcategories(prev => {
      switch (level) {
        case 'level1':
          return {
            level1: data,
            level2: [],
            level3: [],
            level4: [],
            level5: [],
            level6: [],
            level7: []
          };
        case 'level2':
          return {
            ...prev,
            level2: data,
            level3: [],
            level4: [],
            level5: [],
            level6: [],
            level7: []
          };

        case 'level3':
          return {
            ...prev,
            level3: data,
            level4: [],
            level5: [],
            level6: [],
            level7: []
          };

        case 'level4':
          return {
            ...prev,
            level4: data,
            level5: [],
            level6: [],
            level7: []
          };

        case 'level5':
          return {
            ...prev,
            level5: data,
            level6: [],
            level7: []
          };

        case 'level6':
          return {
            ...prev,
            level6: data,
            level7: []
          };

        case 'level7':
          return {
            ...prev,
            level7: data
          };

        default:
          return {
            ...prev
          };
      }
    });
  };

  useEffect(() => {
    dispatch(fetchParentProductsCategories());
  }, []);

  useEffect(() => {
    setCategoriesList(prev => {
      return parentCategories === null ? prev : parentCategories.data;
    });
  }, [parentCategories]);

  useEffect(() => {
    const values = Object.values(active);
    setCategoryId(values.shift()); //sets The parent category to the first item on the list
    let subCatsList = [];
    values.forEach(id => {
      if (id !== null) {
        subCatsList.push({
          categoryId: id
        });
      }
    });
    setSubCatIds(subCatsList); // Sets the sub categories to the other items on the list
  }, [active]);

  return (
    <ChooseContainer>
      <div className="choose-box-wrapper">
        <h5>Please select a Category for your product</h5>

        <div className="categories-box">
          <div className="categories-box-wrap">
            {categoriesList.length !== 0 && (
              <div className="categories-column">
                {categoriesList.map((item, index) =>
                  item.status === 'ACTIVE' ? (
                    <p
                      onClick={() => handleClick(item, 'level1')}
                      className={`category-item ${
                        active.level1 === item.categoryId ? 'active' : ''
                      }`}
                      key={index}
                    >
                      {item.categoryName}
                    </p>
                  ) : null
                )}
              </div>
            )}

            {subcategories.level1.length !== 0 && (
              <div className="categories-column">
                {subcategories.level1.map((item, index) => (
                  <p
                    onClick={() => handleClick(item, 'level2')}
                    className={`category-item ${
                      active.level2 === item.categoryId ? 'active' : ''
                    }`}
                    key={index}
                  >
                    {item.categoryName}
                  </p>
                ))}
              </div>
            )}

            {subcategories.level2.length !== 0 && (
              <div className="categories-column">
                {subcategories.level2.map((item, index) => (
                  <p
                    onClick={() => handleClick(item, 'level3')}
                    className={`category-item ${
                      active.level3 === item.categoryId ? 'active' : ''
                    }`}
                    key={index}
                  >
                    {item.categoryName}
                  </p>
                ))}
              </div>
            )}

            {subcategories.level3.length !== 0 && (
              <div className="categories-column">
                {subcategories.level3.map((item, index) => (
                  <p
                    onClick={() => handleClick(item, 'level4')}
                    className={`category-item ${
                      active.level4 === item.categoryId ? 'active' : ''
                    }`}
                    key={index}
                  >
                    {item.categoryName}
                  </p>
                ))}
              </div>
            )}
            {subcategories.level4.length !== 0 && (
              <div className="categories-column">
                {subcategories.level4.map((item, index) => (
                  <p
                    onClick={() => handleClick(item, 'level5')}
                    className={`category-item ${
                      active.level5 === item.categoryId ? 'active' : ''
                    }`}
                    key={index}
                  >
                    {item.categoryName}
                  </p>
                ))}
              </div>
            )}
            {subcategories.level5.length !== 0 && (
              <div className="categories-column">
                {subcategories.level5.map((item, index) => (
                  <p
                    onClick={() => handleClick(item, 'level6')}
                    className={`category-item ${
                      active.level6 === item.categoryId ? 'active' : ''
                    }`}
                    key={index}
                  >
                    {item.categoryName}
                  </p>
                ))}
              </div>
            )}
            {subcategories.level6.length !== 0 && (
              <div className="categories-column">
                {subcategories.level6.map((item, index) => (
                  <p
                    onClick={() => handleClick(item, 'level7')}
                    className={`category-item ${
                      active.level7 === item.categoryId ? 'active' : ''
                    }`}
                    key={index}
                  >
                    {item.categoryName}
                  </p>
                ))}
              </div>
            )}
            {subcategories.level7.length !== 0 && (
              <div className="categories-column">
                {subcategories.level7.map((item, index) => (
                  <p
                    onClick={() => handleClick(item, 'level8')}
                    className={`category-item ${
                      active.level8 === item.categoryId ? 'active' : ''
                    }`}
                    key={index}
                  >
                    {item.categoryName}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </ChooseContainer>
  );
};

const ChooseContainer = styled.div`
  background-color: #fff;
  padding: 30px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 700px;
  overflow: hidden;

  .choose-box-wrapper {
    height: 100%;

    padding-bottom: 5px;

    h5 {
      margin-left: 50px;
      margin-bottom: 20px;
    }
  }
  .categories-box {
    max-width: 1050px;
    height: 600px;
    margin-left: 50px;
    box-shadow: 1px 1px 2px #c4c4c4, -1px -1px 2px #c4c4c4;
  }
  .categories-box-wrap {
    height: 100%;
    display: flex;
    overflow-x: auto;
  }
  .categories-column {
    width: 150px;
    height: 100%;
    overflow: scroll;
    padding-bottom: 8px;
    border-right: 1px solid #c4c4c4;
  }
  .category-item {
    padding: 10px 8px;
    font-size: 12px;
    white-space: no-wrap;
    cursor: pointer;
    transition: all 0.3s ease-in;
    line-height: 1.1;
    margin-bottom: 5px;
  }
  .category-item:hover,
  .category-item.active {
    background-color: #262262;
    color: #fff;
  }
  .category-item:first-child {
    margin-top: 0px;
  }
`;

Choosecategory.propTypes = {
  setCategoryId: PropTypes.func,
  setCategoryProps: PropTypes.func,
  setSubCatIds: PropTypes.func
};

export default Choosecategory;
