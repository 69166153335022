import React, { useState } from 'react';
import styled from 'styled-components';
import Choosecategory from './Choosecategory';
import AddProductDetails from './AddProductDetails';
import AddProductImages from './AddProductImages';
import { useDispatch, useSelector } from 'react-redux';
import { createProduct } from 'store/actions/products';
import DotsLoader from 'components/Loaders/DotsLoader';
import { RESET_PRODUCT_CREATED } from 'store/actions/types';
import { Link } from 'react-router-dom';

const CreateProduct = () => {
  const [stepper, setStepper] = useState(1);
  const [categoryId, setCategoryId] = useState('');
  const [subcategoryIds, setSubCatIds] = useState([]);
  const [categoryProperties, setProperties] = useState([]);
  const [productDetail, setProductDetails] = useState({});
  const [image, getProductImages] = useState([]);
  const { creatingProduct, createProductError, productCreated } = useSelector(
    state => state.productsReducer
  );
  const dispatch = useDispatch();

  const increaseStep = () => {
    if (stepper === 3) {
      return;
    }
    setStepper(prev => prev + 1);
  };
  const decreaseStep = () => {
    if (stepper === 1) {
      return;
    }
    setStepper(prev => prev - 1);
  };

  const handleSubmit = () => {
    let formData = new FormData();
    formData.append('categoryId', categoryId);

    for (let key in productDetail) {
      if (key === 'properties') {
        formData.append([key], JSON.stringify(productDetail[key]));
      } else {
        formData.append([key], productDetail[key]);
      }
    }

    image.forEach(item => formData.append('image', item));
    formData.append('subcategoryIds', JSON.stringify(subcategoryIds));

    console.log('subcategoryIds', JSON.stringify(subcategoryIds));

    dispatch(createProduct(formData));
  };

  const createAnotherProduct = () => {
    setStepper(1);
    dispatch({ type: RESET_PRODUCT_CREATED });
  };

  return (
    <CreateProductWrapper>
      <div className="stepper">
        <div className={`marker-container ${stepper >= 1 ? 'active' : ''}`}>
          <div className="marker">
            {stepper > 1 && (
              <svg
                width="15"
                height="11"
                viewBox="0 0 15 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6L5 10L14 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
          <span>Select Category</span>
        </div>
        <div className={`marker-container ${stepper >= 2 ? 'active' : ''}`}>
          <div className="marker">
            {stepper > 2 && (
              <svg
                width="15"
                height="11"
                viewBox="0 0 15 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6L5 10L14 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
          <span>Product Details</span>
        </div>
        <div className={`marker-container ${stepper === 3 ? 'active' : ''}`}>
          <div className="marker">
            {stepper === 3 && (
              <svg
                width="15"
                height="11"
                viewBox="0 0 15 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6L5 10L14 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
          <span>Product Images</span>
        </div>
      </div>
      <section className="create-products-box">
        {stepper === 1 && (
          <Choosecategory
            setCategoryProps={setProperties}
            setCategoryId={setCategoryId}
            setSubCatIds={setSubCatIds}
          />
        )}
        {stepper === 2 && (
          <AddProductDetails
            productInfo={categoryProperties}
            setProductDetails={setProductDetails}
          />
        )}
        {stepper === 3 && (
          <AddProductImages getProductImages={getProductImages} />
        )}
      </section>

      <div className="create-product-btns">
        <div className="error-msg">{createProductError}</div>
        <div className="product-btns-wrapper">
          <button
            onClick={decreaseStep}
            className="cancel-btn"
            disabled={creatingProduct}
          >
            Cancel
          </button>
          {stepper <= 2 && (
            <button onClick={increaseStep} className="select-btn">
              {stepper === 1 ? 'Select' : 'Save and Continue'}
            </button>
          )}
          {stepper === 3 && (
            <button
              onClick={handleSubmit}
              className="select-btn complete-btn"
              disabled={creatingProduct}
            >
              {creatingProduct && <DotsLoader />}
              <span className="button-text">
                {creatingProduct ? 'Creating...' : 'Finish'}
              </span>
            </button>
          )}
        </div>
      </div>

      {productCreated && (
        <div className="product-created-modal">
          <div className="success-msg-box">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 24 24"
                style={{ fill: '#262262', transform: ';msFilter:' }}
              >
                <path
                  d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 
                              12 2zm-1.999 14.413-3.713-3.705L7.7 11.292l2.299 2.295 5.294-5.294 1.414 
                              1.414-6.706 6.706z"
                ></path>
              </svg>
            </span>
            <p>PRODUCT CREATED SUCCESSFULLY</p>
            <div className="modal-btns">
              <button onClick={createAnotherProduct}>
                CREATE ANOTHER PRODUCT
              </button>
              <Link
                to="/admin/products"
                onClick={() => dispatch({ type: RESET_PRODUCT_CREATED })}
              >
                <button>EXIT</button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </CreateProductWrapper>
  );
};

export const CreateProductWrapper = styled.div`
  padding: 50px 80px;
  background: #fafafa;

  .stepper {
    display: flex;
    align-items: center;
  }
  .marker-container {
    padding-top: 10px;
    flex: 1;
    display: flex;
    border-top: 1px solid #c4c4c4;

    span {
      margin-left: 4px;
    }
  }
  .marker-container.active {
    border-top: 5px solid #262262;
  }
  .marker {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #262262;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .marker-container.active .marker {
    background-color: #262262;
  }
  .create-products-box {
    padding-top: 50px;
  }

  .create-product-btns {
    margin-top: 40px;

    .error-msg {
      color: red;
      font-size: 12px;
      margin-bottom: 8px;
      text-align: center;
      height: 15px;
    }

    .product-btns-wrapper {
      display: flex;
      justify-content: center;
    }

    button {
      padding: 15px 40px;
      border: none;
      border-radius: 5px;
    }
    button:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
    .cancel-btn {
      margin-right: 12px;
      background-color: #d6d6d6;
      color: #262262;
    }
    .select-btn {
      background-color: #262262;
      color: #fff;
    }
    .select-btn.complete-btn {
      display: flex;
      align-items: center;
    }
  }

  .product-created-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    transform: translateY(0px, -500px);

    .success-msg-box {
      width: 500px;
      height: 300px;
      background-color: #fff;
      margin: 80px auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        margin-top: 12px;
      }

      .modal-btns {
        button {
          display: block;
          width: 100%;
          border: none;
          color: #fff;
          font-size: 12px;
          padding: 6px 10px;
          font-weight: 700;

          :first-child {
            background-color: #262262;
          }
          :last-child {
            background-color: #db8130;
            margin-top: 10px;
          }
        }
      }
    }
  }
`;

export default CreateProduct;
