import isEmpty from 'is-empty';
import {
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_IS_LOADING,
  DELETE_RESET_PASSWORD_ERROR
} from '../../actions/types';

const initialState = {
  isSuccess: false,
  userData: {},
  error: null,
  isLoading: false
};

const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isSuccess: !isEmpty(action.data),
        isLoading: false,
        userData: action.data,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        isSuccess: false,
        userData: {},
        error: action.error,
        isLoading: false
      };
    case RESET_PASSWORD_IS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case DELETE_RESET_PASSWORD_ERROR:
      return {
        error: null
      };
    default:
      return state;
  }
};

export default resetPasswordReducer;
