import {
  GET_PRODUCTS_CATEGORIES,
  GET_CATEGORIES_ERROR,
  CREATING_CATEGORY,
  CREATE_CATEGORY_ERROR,
  CATEGORY_CREATED,
  SUB_CATEGORY_CREATED,
  GET_BRANDS,
  CREATING_PRODUCT,
  PRODUCT_CREATED,
  CREATING_PRODUCT_ERROR,
  RESET_PRODUCT_CREATED,
  CREATING_BRAND,
  BRAND_CREATED,
  CREATING_BRAND_ERROR,
  ON_CREATE_BRAND_LOAD,
  GET_GENERIC_PRODUCTS,
  GET_GENERIC_PRODUCTS_ERROR,
  GET_PRODUCTS_IN_STORE,
  GET_PRODUCTS_IN_STORE_ERROR,
  GET_PRODUCT_TAB_COUNT,
  GET_PARENT_CATEGORIES,
  PRODUCTS_LOADING,
  GET_PRODUCTS_SUBCATEGORIES,
  GET_REQUESTS_TO_ADMIN
} from '../../actions/types.js';

const initialState = {
  categories: null,
  parentCategories: null,
  subCategories: null,
  dataLoaded: false,
  creatingCategory: false,
  errorMessage: '',
  categoryCreated: false,
  categoryId: null,
  creatingProduct: false,
  createProductError: '',
  productCreated: false,
  brands: null,
  creatingBrand: false,
  brandCreated: false,
  brandPayload: null,
  createBrandError: null,
  genericProducts: null,
  productsInStore: null,
  tabsCount: {},
  requestsToAdmin: null
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case GET_PARENT_CATEGORIES:
      return {
        ...state,
        parentCategories: action.payload
      };
    case GET_CATEGORIES_ERROR:
      return {
        ...state,
        categories: action.payload
      };
    case CREATING_CATEGORY:
      return {
        ...state,
        creatingCategory: action.payload
      };
    case CREATE_CATEGORY_ERROR:
      return {
        ...state,
        errorMessage: action.payload
      };
    case CATEGORY_CREATED: {
      return {
        ...state,
        categoryCreated: action.payload,
        categoryId: action.categoryId
      };
    }
    case SUB_CATEGORY_CREATED: {
      return {
        ...state,
        categoryCreated: false
      };
    }
    case CREATING_PRODUCT:
      return {
        ...state,
        creatingProduct: true
      };
    case PRODUCT_CREATED:
      return {
        ...state,
        creatingProduct: false,
        productCreated: true
      };
    case RESET_PRODUCT_CREATED:
      return {
        ...state,
        creatingProduct: false,
        productCreated: false
      };
    case CREATING_PRODUCT_ERROR:
      return {
        ...state,
        creatingProduct: false,
        createProductError: action.payload
      };
    case GET_BRANDS:
      return {
        ...state,
        brands: action.payload
      };
    case ON_CREATE_BRAND_LOAD:
      return {
        ...state,
        creatingBrand: false,
        brandCreated: false,
        brandPayload: null,
        createBrandError: null
      };
    case CREATING_BRAND:
      return {
        ...state,
        creatingBrand: true
      };
    case BRAND_CREATED:
      return {
        ...state,
        creatingBrand: false,
        brandCreated: true,
        createBrandError: null,
        brandPayload: action.payload
      };
    case CREATING_BRAND_ERROR:
      return {
        ...state,
        creatingBrand: false,
        brandCreated: false,
        createBrandError: action.payload
      };
    case GET_GENERIC_PRODUCTS:
      return {
        ...state,
        genericProducts: action.payload
      };
    case GET_GENERIC_PRODUCTS_ERROR:
      return {
        ...state,
        genericProducts: action.payload
      };
    case GET_PRODUCTS_IN_STORE:
      return {
        ...state,
        productsInStore: action.payload
      };
    case GET_PRODUCTS_IN_STORE_ERROR:
      return {
        ...state,
        productsInStore: action.payload
      };
    case GET_PRODUCT_TAB_COUNT: {
      return {
        ...state,
        tabsCount: {
          ...state.tabsCount,
          [action.payload.name]: action.payload.value
        }
      };
    }
    case PRODUCTS_LOADING:
      return {
        ...state,
        dataLoaded: action.payload
      };
    case GET_PRODUCTS_SUBCATEGORIES:
      return {
        ...state,
        subCategories: action.payload
      };
    case GET_REQUESTS_TO_ADMIN:
      return {
        ...state,
        requestsToAdmin: action.payload
      };
    default:
      return state;
  }
};

export default productsReducer;
