import React, { Suspense, lazy, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OrderDetails from './pages/OrderDetails/OrderDetails';
import {
  Admin,
  Store,
  Order,
  Logistics,
  Finance,
  Products
} from './DashboardLayout/Admin';
import Loader from 'components/Common/Loader';
import { FetchLoginAdminDetails } from 'store/actions/users/users';
import './index.css';

// Non-auth pages
const ResetPassword = lazy(() => import('pages/authentication/ResetPassword'));
const ChangePassword = lazy(() =>
  import('pages/authentication/ChangePassword')
);
const SignUp = lazy(() => import('pages/authentication/SignUp'));
const Login = lazy(() => import('pages/authentication/Login'));
const AuthSuccess = lazy(() => import('pages/authentication/AuthSuccess'));

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchLoginAdminDetails());
  }, [dispatch]);

  const { user } = useSelector(state => state.loginReducer);

  const { role, userDepartment } = user;
  const adminPayload = { role, userDepartment };
  localStorage.setItem('adminDetails', JSON.stringify(adminPayload));

  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route
              exact
              path="/"
              render={() => <Redirect to="/admin/dashboard" />}
            />
            <Route path="/admin/orders/:orderId" component={OrderDetails} />
            <Route path="/admin" component={Admin} />
            <Route path="/store" component={Store} />
            <Route path="/order" component={Order} />
            <Route path="/delivery" component={Logistics} />
            <Route path="/finance" component={Finance} />
            <Route path="/products" component={Products} />
            <Route path="/change-password" component={ChangePassword} />
            <Route path="/onboarding" component={SignUp} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/registration-success" component={AuthSuccess} />
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
};

export default App;
