import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSubcat,
  fetchProductsSubCategories
} from 'store/actions/products';
import { SubCategory } from './SubCategory';
import { useLocation, useParams } from 'react-router-dom';
import { StyledSubCategories, AddModal } from './StyledSubCategories';
import styled from 'styled-components';
import fourDots from 'assets/svg/four-dots.svg';

const SubCategories = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { subCategories } = useSelector(state => state.productsReducer);
  const { categoryId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    try {
      dispatch(fetchProductsSubCategories(categoryId));
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, location.key]);

  return subCategories?.length > 0 ? (
    <StyledSubCategories>
      <div className="flex header">
        <button
          className="btn btn-primary pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          Add Sub Category
        </button>
      </div>
      <ul className="flex list">
        {subCategories?.map(subCat => (
          <SubCategory key={subCat._id} subCat={subCat} />
        ))}
      </ul>
      <AddModal isOpen={isOpen} setIsOpen={setIsOpen} categoryId={categoryId} />
    </StyledSubCategories>
  ) : (
    <StyledContainer>
      <div className="card">
        <div className="img-container">
          <img src={fourDots} />
        </div>
        <p>You havent added a subcategory to this Subcategory</p>
        <button
          className="btn btn-primary pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          Add Sub Category
        </button>
      </div>
      <AddModal isOpen={isOpen} setIsOpen={setIsOpen} categoryId={categoryId} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: grid;
  place-items: center;
  height: 80vh;
  padding-block: 10em;
  .card {
    align-self: flex-start;
    display: grid;
    place-items: center;
    gap: 2em;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(193, 193, 193, 0.16);
    border-radius: 5px;
    padding: 3em;
    width: 530px;
  }

  .btn {
    border: 1px solid transparent;
    border-radius: 3px;
    display: inline-block;
    line-height: 24px;
    margin-bottom: 0;
    min-height: 48px;
    padding: 12px 24px;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    white-space: nowrap;
  }

  .btn-primary {
    -webkit-appearance: none;
    appearance: none;
    background: #262262;
    border: 0;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: block;
    outline: 0;
    transition-duration: 0.25s;
    &:hover {
      background: rgba(38, 34, 98, 0.7);
    }
  }
  .img-container {
    display: grid;
    place-items: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: #e7e6fe;

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }
`;

export default SubCategories;
