import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ToggleButton = ({ toggleValue }) => {
  const handleClick = e => {
    if (toggleValue) {
      toggleValue(e.target.checked);
    }
  };
  return (
    <SwitchContainer>
      <label className="switch">
        <input type="checkbox" onClick={handleClick} />
        <span className="slider"></span>
      </label>
    </SwitchContainer>
  );
};

const SwitchContainer = styled.div`
  .switch {
    margin-bottom: 0;
    position: relative;
    display: inline-block;
    width: 80px;
    height: 40px;

    input {
      display: none;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #ccc;
      transition: 0.4s;
      border-radius: 34px;
    }
    .slider:before {
      position: absolute;
      content: '';
      height: 40px;
      width: 40px;
      left: 0;
      bottom: 0;
      background-color: #fff;
      transition: 0.4s;
      border-radius: 50%;
    }
    input:checked + .slider {
      background-color: #262262;
    }
    input:checked + .slider:before {
      transform: translateX(41px);
    }
  }
`;

ToggleButton.propTypes = {
  toggleValue: PropTypes.func
};

export default ToggleButton;
