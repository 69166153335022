import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  min-height: 110vh;
  color: white;
  font-family: 'Poppins', sans-serif;

  .icon-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .logo {
    margin-top: 40px;
  }

  .menu-item {
    font-size: 14px;
    display: flex;
    cursor: pointer;
    margin-bottom: 23px;
    opacity: 0.8;
    color: white;
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }

  .menu-wrapper {
    width: 100%;
    margin-top: 100px;
  }

  .icon {
    margin-left: 24px;
    margin-right: 10px;
  }

  .active-link {
    font-weight: 700;
    opacity: 1;
  }
`;
