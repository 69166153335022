import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  .sidebar-wrapper {
    min-width: 149px;
    background: #262262;
  }

  .main-contents-flex {
    display: flex;
  }

  .main-content {
    width: calc(100% - 280px);
    padding-left: 20px;
    padding-right: 20px;
    height: 100vh;
    overflow: auto;
  }

  .user-data-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    padding-right: 30px;
    padding-top: 30px;
    width: 100%;
    margin-bottom: 50px;
  }

  .user-type {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #262262;
    margin-bottom: 20px;
  }

  .user-name {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #1a171e;
    margin-bottom: 35px;
  }

  .user-address {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #413d45;
    width: 200px;
  }
  .user-email {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #413d45;
    width: 200px;
  }

  .store {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #413d45;
    margin-bottom: 14px;
  }

  .count {
    padding: 8px;
    font-weight: 400;
    font-size: 12px;
    color: #262262;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #262262;
  }
`;
