const comments = [
  {
    name: 'John Doe',
    comment: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
  Nunc mattis enim ut tellus. Commodo ullamcorper a lacus vestibulum sed arcu. Pharetra sit amet aliquam id diam. Massa enim nec dui nunc.
  Tellus elementum sagittis vitae et leo duis ut. Consectetur a erat nam at lectus urna duis convallis convallis. Sit amet commodo nulla facilisi nullam vehicula.
  Enim sed faucibus turpis in. Eleifend mi in nulla posuere. Ac tortor dignissim convallis aenean et`,
    dateTime: {
      day: '1st March 2020',
      time: '2:00PM'
    }
  },
  {
    name: 'Janet Doe',
    comment: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
  Nunc mattis enim ut tellus. Commodo ullamcorper a lacus vestibulum sed arcu. Pharetra sit amet aliquam id diam. Massa enim nec dui nunc.
  Tellus elementum sagittis vitae et leo duis ut. Consectetur a erat nam at lectus urna duis convallis convallis. Sit amet commodo nulla facilisi nullam vehicula.
  Enim sed faucibus turpis in. Eleifend mi in nulla posuere. Ac tortor dignissim convallis aenean et`,
    dateTime: {
      day: '3rd March 2020',
      time: '9:45AM'
    }
  },
  {
    name: 'Janet John',
    comment: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
  Nunc mattis enim ut tellus. Commodo ullamcorper a lacus vestibulum sed arcu. Pharetra sit amet aliquam id diam. Massa enim nec dui nunc.
  Tellus elementum sagittis vitae et leo duis ut. Consectetur a erat nam at lectus urna duis convallis convallis. Sit amet commodo nulla facilisi nullam vehicula.
  Enim sed faucibus turpis in. Eleifend mi in nulla posuere. Ac tortor dignissim convallis aenean et`,
    dateTime: {
      day: '5th March 2020',
      time: '3:23PM'
    }
  }
];
export default comments;
