import React from 'react';
import styled from 'styled-components';

const DotsLoader = () => {
  return (
    <Loader>
      <span></span>
      <span></span>
      <span></span>
    </Loader>
  );
};

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    width: 8px;
    height: 8px;
    margin: 0 1px;
    border-radius: 50%;
    background-color: #ffd957;
    display: inline-block;
    animation-name: dots;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  span:nth-child(2) {
    background-color: #57ffa0;
    animation-delay: 0.2s;
  }
  span:nth-child(3) {
    background-color: #ff8b57;
    animation-delay: 0.5s;
  }

  @keyframes dots {
    50% {
      opacity: 0;
      transform: scale(0.7) translateY(2px);
    }
  }
`;

export default DotsLoader;
