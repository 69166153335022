import React, { useState } from 'react';
import More from '../../assets/more.svg';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  pausePayment,
  releasePayment
} from '../../store/actions/orders/orders';

const PayPause = props => {
  const [open, setOpen] = useState(false);
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const { genericProductId, saleId } = props;

  const pausePaymentFunc = () => {
    dispatch(pausePayment(orderId, genericProductId, saleId));
  };

  const releasePaymentFunc = verdict => {
    dispatch(releasePayment(orderId, genericProductId, saleId, verdict));
  };
  return (
    <div className="pay-pause-container" onClick={() => setOpen(prev => !prev)}>
      <button className="action-btn">
        <img className="more-icon" src={More} alt="dropdown-icon" />
        {open && (
          <div className="pop-up">
            <div className="popup-wrapper">
              <p onClick={pausePaymentFunc}>Pause</p>
              <p onClick={() => releasePaymentFunc('buyer')}>Pay Buyer</p>
              <p onClick={() => releasePaymentFunc('seller')}>Pay Seller</p>
            </div>
          </div>
        )}
      </button>
    </div>
  );
};

PayPause.propTypes = {
  genericProductId: PropTypes.string,
  saleId: PropTypes.string
};

export default PayPause;
