import toastr from 'toastr';
import { axios, _catchAxiosError } from 'services/axios';
import { BASE_URL } from 'utils/constants';
import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_IS_LOADING,
  DELETE_LOGIN_ERROR,
  SET_CURRENT_USER,
  SET_USER_TOKEN,
  REMOVE_USER_TOKEN,
  OPEN_SEARCH
} from '../types';

/**
 * Action to Login a user and return a JWT token
 * @param {*} user - Response object
 * @returns {user} setCurrentUser - to store
 */

const loginSuccess = loginUserData => ({
  type: LOGIN_SUCCESS,
  loginUserData
});

export const isLoading = () => ({
  type: LOGIN_IS_LOADING
});

export const loginError = error => ({
  type: LOGIN_ERROR,
  error
});

export const setCurrentUser = id => ({
  type: SET_CURRENT_USER,
  id
});

export const setUserToken = token => ({
  type: SET_USER_TOKEN,
  token
});

export const deleteLoginErrorMessage = () => ({
  type: DELETE_LOGIN_ERROR
});

export const removeUserToken = () => ({
  type: REMOVE_USER_TOKEN
});

export const openSearch = isDisplay => ({
  type: OPEN_SEARCH,
  isDisplay
});

/**
 * Login a user and return a JWT token
 * @param {*} userData - Response object
 * @param {*} history - Next function
 * @param {*} done - Next function
 * @returns {token} token - JWT token
 */
export const userLoginRequest = (
  userData,
  onError = false
) => async dispatch => {
  try {
    dispatch(isLoading());
    const response = await axios({
      method: 'post',
      url: `${BASE_URL.ADMIN}/login`,
      data: userData
    });
    const { data } = response.data;
    localStorage.setItem('currentUser', data.admin._id);
    localStorage.setItem('token', data.token);

    dispatch(loginSuccess(data));
    dispatch(setCurrentUser(data.admin._id));
    dispatch(setUserToken(data.token));

    toastr.success(`Welcome back ${data.admin.fullName}`);
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    dispatch(loginError(reportError));
    throw error;
  }
};
