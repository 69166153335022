import isEmpty from 'is-empty';
import {
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CONFIRM_RESET_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_IS_LOADING,
  DELETE_CHANGE_PASSWORD_ERROR,
} from '../../actions/types';

const initialState = {
  isNotVerified: true,
  verifiedData: {},
  confirmResetDataError: null,
  changePasswordError: null,
  isSuccessful: false,
  changePasswordLoading: false,
  changePasswordData: {}
}

const changePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIRM_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isNotVerified: isEmpty(action.confirmResetData),
        verifiedData: action.confirmResetData
      }
    case CONFIRM_RESET_PASSWORD_ERROR:
      return {
        ...state,
        isNotVerified: true,
        verifiedData: {},
        confirmResetDataError: action.confirmResetDataError,
      }
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isNotVerified: false,
        verifiedData: {},
        confirmResetDataError: null,
        changePasswordData: action.changePasswordData,
        isSuccessful: !isEmpty(action.changePasswordData),
        changePasswordLoading: false
      }
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isNotVerified: false,
        verifiedData: {},
        confirmResetDataError: null,
        changePasswordData: {},
        isSuccessful: false,
        changePasswordLoading: false,
        changePasswordError: action.changePasswordError,
      }
    case CHANGE_PASSWORD_IS_LOADING:
    return {
      ...state,
      isNotVerified: false,
      verifiedData: {},
      confirmResetDataError: null,
      changePasswordData: {},
      isSuccessful: false,
      changePasswordLoading: true,
      changePasswordError: null,
    }
    case DELETE_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
      isNotVerified: false,
      verifiedData: {},
      confirmResetDataError: null,
      changePasswordData: {},
      isSuccessful: false,
      changePasswordLoading: false,
      changePasswordError: null,
      };
    default:
      return state;
  }
}

export default changePasswordReducer;