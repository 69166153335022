import * as _axios from 'axios';

const token = localStorage.getItem('token');

let axios = _axios.create({});

axios.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${token ? token : ''}`;
  return config;
});

export const _catchAxiosError = (e, onError = false) => {
  let error = {};
  if (e.response === undefined) {
    error.message = 'No Internet Connection';
  } else {
    error = e.response.data.message;
  }
  if (onError) {
    onError(error);
  }
  return error;
};

export { axios };
