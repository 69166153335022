import isEmpty from 'is-empty';
import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_IS_LOADING,
  DELETE_LOGIN_ERROR,
  SET_CURRENT_USER,
  SET_USER_TOKEN,
  REMOVE_USER_TOKEN,
  GET_CURRENT_ADMIN_USER,
  OPEN_SEARCH
} from 'store/actions/types';

const initialState = {
  isAuthenticated: false,
  user: {},
  error: null,
  isLoading: false,
  userId: null,
  token: null,
  isDisplay: false
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_TOKEN:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.token),
        token: action.token,
        isLoading: false
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.loginUserData),
        user: action.loginUserData,
        isLoading: false
      };
    case GET_CURRENT_ADMIN_USER:
      return {
        ...state,
        user: action.adminDetails,
        isLoading: false
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.id),
        userId: action.id,
        isLoading: false
      };
    case LOGIN_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
        error: action.error,
        isLoading: false
      };
    case DELETE_LOGIN_ERROR:
      return {
        error: null
      };
    case LOGIN_IS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case REMOVE_USER_TOKEN:
      return {
        ...state,
        token: null,
        userId: ''
      };
    case OPEN_SEARCH:
      return {
        ...state,
        isDisplay: action.isDisplay
      };
    default:
      return state;
  }
};

export default loginReducer;
