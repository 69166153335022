import {
  GET_ALL_ADMIN_ERROR,
  GET_ALL_ADMIN_SUCCESS,
  GET_ALL_ADMIN_IS_LOADING,
  GET_ADMIN_DETAILS,
} from '../../actions/types';

const initialState = {
  getAllAdminUsers: [],
  isLoading: false,
  getAllAdminError: null,
  getAdminDetails: {},
}

const adminUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ADMIN_SUCCESS:
      return {
        ...state,
        getAllAdminUsers: action.adminUsers,
        isLoading: false
      }
    case GET_ALL_ADMIN_ERROR:
      return {
        ...state,
        isLoading: false,
        getAllAdminError: action.adminUserError,
      }
    case GET_ALL_ADMIN_IS_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case GET_ADMIN_DETAILS:
      return {
        ...state,
        getAdminDetails: action.adminDetails
      }
    default:
      return state;
  }
}

export default adminUserReducer;