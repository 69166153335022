import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 80%;
  border-right: 1px solid #c4c4c4;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 43px;

  .search-input {
    width: 90%;
    height: 94%;
    border: none;
    margin-left: 80px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .parent {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #413d45;
    opacity: 0.7;
    cursor: pointer;
  }

  .current-page {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-left: 14px;
    color: #262262;
  }

  .arrow {
    margin-left: 20px;
  }

  .search-icon {
    margin-left: 40px;
  }
`;
