import { combineReducers } from 'redux';
import signUpReducer from './authReducers/signUp';
import loginReducer from './authReducers/login';
import settingsReducer from './settingsReducer/settings';
import resetPasswordReducer from './authReducers/resetPassword';
import changePasswordReducer from './authReducers/changePassword';
import userReducer from './usersReducer/user';
import ordersReducer from './orders/orders';
import adminUserReducer from './settingsReducer/adminUsers';
import storeReducer from './storesReducer/stores';
import financeReducer from './financeReducer/finance';
import deliveryReducer from './delivery/delivery';
import productsReducer from './productsReducer';

export default combineReducers({
  signUpReducer,
  loginReducer,
  settingsReducer,
  resetPasswordReducer,
  changePasswordReducer,
  userReducer,
  adminUserReducer,
  ordersReducer,
  storeReducer,
  financeReducer,
  deliveryReducer,
  productsReducer
});
