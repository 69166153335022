export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERRORS = 'SIGN_UP_ERRORS';
export const SIGN_UP_IS_LOADING = 'SIGN_UP_IS_LOADING';
export const DELETE_SIGN_UP_ERROR_MESSAGE = 'DELETE_SIGN_UP_ERROR_MESSAGE';
export const OPEN_SEARCH = 'OPEN_SEARCH';

// Login action types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_IS_LOADING = 'LOGIN_IS_LOADING';
export const DELETE_LOGIN_ERROR = 'DELETE_LOGIN_ERROR';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const REMOVE_USER_TOKEN = 'REMOVE_USER_TOKEN';
export const GET_CURRENT_ADMIN_USER = 'GET_CURRENT_ADMIN_USER';

//SETTINGS -- INVITE USER
export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';
export const INVITE_USER_FUFILLED = 'INVITE_USER_FUFILLED';
export const INVITE_USER_REJECTED = 'INVITE_USER_REJECTED';

//SETTINGS -- CHANGE PWD
export const RETREIVE_ADMIN_USER_REQUEST = 'RETREIVE_ADMIN_USER_REQUEST';
export const RETREIVE_ADMIN_USER_FUFILLED = 'RETREIVE_ADMIN_USER_FUFILLED';
export const RETREIVE_ADMIN_USER_REJECTED = 'RETREIVE_ADMIN_USER_REJECTED';
export const GET_ADMIN_DETAILS = 'GET_ADMIN_DETAILS';

// Reset Password Types
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_IS_LOADING = 'RESET_PASSWORD_IS_LOADING';
export const DELETE_RESET_PASSWORD_ERROR = 'DELETE_RESET_PASSWORD_ERROR';

// Confirm Reset Password Codes
export const CONFIRM_RESET_PASSWORD_SUCCESS = 'CONFIRM_RESET_PASSWORD_SUCCESS';
export const CONFIRM_RESET_PASSWORD_ERROR = 'CONFIRM_RESET_PASSWORD_ERROR';

// change password types
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_IS_LOADING = 'CHANGE_PASSWORD_IS_LOADING';
export const DELETE_CHANGE_PASSWORD_ERROR = 'DELETE_CHANGE_PASSWORD_ERROR';

// verify invitation link
export const VERIFY_INVITATION_LINK_SUCCESS = 'VERIFY_INVITATION_LINK_SUCCESS';
export const VERIFY_INVITATION_LINK_ERROR = 'VERIFY_INVITATION_LINK_ERROR';

// Get Users types
export const GET_MERCHANT_USERS_SUCCESS = 'GET_MERCHANT_USERS_SUCCESS';
export const GET_MERCHANT_USERS_ERROR = 'GET_MERCHANT_USERS_ERROR';
export const GET_MERCHANT_USERS_IS_LOADING = 'GET_MERCHANT_USERS_IS_LOADING';
export const GET_DRIVER_USERS_SUCCESS = 'GET_DRIVER_USERS_SUCCESS';
export const GET_DRIVER_USERS_ERROR = 'GET_DRIVER_USERS_ERROR';
export const GET_DRIVER_USERS_IS_LOADING = 'GET_DRIVER_USERS_IS_LOADING';
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';

// Get all admin Users
export const GET_ALL_ADMIN_SUCCESS = 'GET_ALL_ADMIN_SUCCES';
export const GET_ALL_ADMIN_ERROR = 'GET_ALL_ADMIN_ERROR';
export const GET_ALL_ADMIN_IS_LOADING = 'GET_ALL_ADMIN_IS_LOADING';
export const BLOCK_AN_ADMIN = 'BLOCK_AN_ADMIN';

// Get Orders types
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_LOADING = 'GET_ORDERS_LOADING';
export const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR';
export const GET_SINGLE_ORDER = 'GET_SINGLE_ORDER';
export const GET_SINGLE_ORDER_IS_LOADING = 'GET_SINGLE_ORDER_IS_LOADING';

// stores
// Get all stores
export const GET_STORES = 'GET_STORES';
export const GET_STORES_IS_LOADING = 'GET_STORES_IS_LOADING';
export const GET_ADMIN_STORE_REQUEST = 'GET_ADMIN_STORE_REQUEST';
export const GET_ADMIN_STORE_REQUEST_IS_LOADING =
  'GET_ADMIN_STORE_REQUEST_IS_LOADING';

//Get tabs total

//order tabs
export const GET_TABS_TOTAL = 'GET_TABS_TOTAL';

//store tabs
export const GET_STORE_TABS_COUNT = 'GET_STORE_TABS_COUNT';

//Drivers Count
export const GET_DRIVERS_COUNT = 'GET_DRIVERS_COUNT';

//Tasks Management Count
export const GET_TASK_MANAGEMENT_COUNT = 'GET_TASK_MANAGEMENT_COUNT';

//finance tabs count
export const GET_FINANCE_TABS_COUNT = 'GET_FINANCE_TABS_COUNT';

//Newly signup Count
export const GET_NEWLY_SIGNUP_COUNT = 'GET_NEWLY_SIGNUP_COUNT';

// Users count
export const GET_USERS_COUNT = 'GET_USERS_COUNT';

// Get a store products
export const GET_STORE_PRODUCTS = 'GET_STORE_PRODUCTS';
export const GET_STORE_PRODUCTS_IS_LOADING = 'GET_STORE_PRODUCTS_IS_LOADING';

export const GET_SINGLE_STORE_DETAILS = 'GET_SINGLE_STORE_DETAILS';
export const GET_SINGLE_STORE_DETAILS_IS_LOADING =
  'GET_SINGLE_STORE_DETAILS_IS_LOADING';

export const GET_SINGLE_STORE_TRANSACTIONS = 'GET_SINGLE_STORE_TRANSACTIONS';
export const GET_SINGLE_STORE_TRANSACTIONS_IS_LOADING =
  'GET_SINGLE_STORE_TRANSACTIONS_IS_LOADING';

export const GET_AVAILABLE_BALANCE = 'GET_AVAILABLE_BALANCE';
export const GET_AVAILABLE_BALANCE_IS_LOADING =
  'GET_AVAILABLE_BALANCE_IS_LOADING';

export const ACT_ON_STORE = 'ACT_ON_STORE';
export const ACT_ON_STORE_IS_LOADING = 'ACT_ON_STORE_IS_LOADING';
export const ACT_ON_STORE_ERROR = 'ACT_ON_STORE_ERROR';
export const REMOVE_ACT_ON_STORE_ERROR = 'REMOVE_ACT_ON_STORE_ERROR';

//FINANCE -  Get all user wallet types merchant
export const GET_WALLET_MERCHANT_SUCCESS = 'GET_WALLET_MERCHANT_SUCCES';
export const GET_WALLET_MERCHANT_ERROR = 'GET_WALLET_MERCHANT_ERROR';
export const GET_WALLET_MERCHANT_IS_LOADING = 'GET_WALLET_MERCHANT_IS_LOADING';
//FINANCE -  Get all user wallet types driver
export const GET_WALLET_DRIVER_SUCCESS = 'GET_WALLET_DRIVER_SUCCES';
export const GET_WALLET_DRIVER_ERROR = 'GET_WALLET_DRIVER_ERROR';
export const GET_WALLET_DRIVER_IS_LOADING = 'GET_WALLET_DRIVER_IS_LOADING';
//GET WALLET BALANCE MERCHANT
export const GET_WALLET_BALANCE_MERCHANT_SUCCESS =
  'GET_WALLET_BALANCE_MERCHANT_SUCCESS';
export const GET_WALLET_BALANCE_MERCHANT_ERROR =
  'GET_WALLET_BALANCE_MERCHANT_ERROR';
export const GET_WALLET_BALANCE_MERCHANT_IS_LOADING =
  'GET_WALLET_BALANCE_MERCHANT_IS_LOADING';
//COLLCTION WALLET BALANCE
export const COLLECTION_WALLET_BALANCE_SUCCESS =
  'COLLECTION_WALLET_BALANCE_SUCCESS';
export const COLLECTION_WALLET_BALANCE_IS_LOADING =
  'COLLECTION_WALLET_BALANCE_IS_LOADING';
//ORDER TRANSACTION
export const ORDER_TRANSACTION_SUCCESS = 'ORDER_TRANSACTION_SUCCESS';
export const ORDER_TRANSACTION_LOADING = 'ORDER_TRANSACTION_LOADING';
export const GET_TOTAL_ORDERS_SUCCESS = 'GET_TOTAL_ORDERS_SUCCESS';
export const GET_TOTAL_ORDERS_LOADING = 'GET_TOTAL_ORDERS_LOADING';
export const LOAD_ORDER_DETAILS_SUCCESS = 'LOAD_ORDER_DETAILS_SUCCESS';
export const LOAD_ORDER_DETAILS_SUCCESS_IS_LOADING =
  'LOAD_ORDER_DETAILS_SUCCESS_IS_LOADING';
export const PRODUCT_SOLD = 'PRODUCT_SOLD';
export const PRODUCT_SOLD_ISLOADING = 'PRODUCT_SOLD_ISLOADING';
export const FINANCE_METRICS = 'FINANCE_METRICS';
export const PRODUCT_PAYOUT_METRICS = 'PRODUCT_PAYOUT_METRICS';
export const COST_OF_ORDER_CREATED = 'COST_OF_ORDER_CREATED';
export const ORDER_COUNT = 'ORDER_COUNT';
export const GET_TASK_TRANSACTION = 'GET_TASK_TRANSACTION';
export const GET_TASK_TRANSACTION_IS_LOADING =
  'GET_TASK_TRANSACTION_IS_LOADING';
export const FETCH_USER_WALLET_DETAILS = 'FETCH_USER_WALLET_DETAILS';
export const FETCH_USER_WALLET_DETAILS_IS_LOADING =
  'FETCH_USER_WALLET_DETAILS_IS_LOADING';
export const FETCH_USER_WALLET_DETAILS_ERROR =
  'FETCH_USER_WALLET_DETAILS_ERROR';
export const FETCH_USER_TRANSACTION = 'FETCH_USER_TRANSACTION';
export const FETCH_USER_TRANSACTION_IS_LOADING =
  'FETCH_USER_TRANSACTION_IS_LOADING';
export const FETCH_USER_TRANSACTION_ERROR = 'FETCH_USER_TRANSACTION_ERROR';
export const GET_DRIVER_PAYOUT = 'GET_DRIVER_PAYOUT';
export const GET_DRIVER_PAYOUT_IS_LOADING = 'GET_DRIVER_PAYOUT_IS_LOADING';
export const GET_DRIVER_PAYOUT_ERROR = 'GET_DRIVER_PAYOUT_ERROR';
export const DRIVER_RIDE_HISTORY = 'DRIVER_RIDE_HISTORY';
export const DRIVER_RIDE_HISTORY_IS_LOADING = 'DRIVER_RIDE_HISTORY_IS_LOADING';
export const DRIVER_RIDE_HISTORY_ERROR = 'DRIVER_RIDE_HISTORY_ERROR';
export const DRIVER_RIDE_COUNT = 'DRIVER_RIDE_COUNT';
export const DRIVER_RIDE_COUNT_IS_LOADING = 'DRIVER_RIDE_COUNT_IS_LOADING';
export const DRIVER_RIDE_COUNT_ERROR = 'DRIVER_RIDE_COUNT_ERROR';
export const MERCHANT_ORDER_TRANSACTION = 'MERCHANT_ORDER_TRANSACTION';
export const MERCHANT_ORDER_TRANSACTION_IS_LOADING =
  'MERCHANT_ORDER_TRANSACTION_IS_LOADING';
export const MERCHANT_ORDER_TRANSACTION_ERROR =
  'MERCHANT_ORDER_TRANSACTION_ERROR';
export const MERCHANT_SALES = 'MERCHANT_SALES';
export const MERCHANT_SALES_IS_LOADING = 'MERCHANT_SALES_IS_LOADING';
export const MERCHANT_SALES_ERROR = 'MERCHANT_SALES_ERROR';
export const MERCHANT_SUB_TOTAL_COST_ORDER = 'MERCHANT_SUB_TOTAL_COST_ORDER';
export const MERCHANT_SUB_TOTAL_COST_ORDER_IS_LOADING =
  'MERCHANT_SUB_TOTAL_COST_ORDER_IS_LOADING';
export const MERCHANT_SUB_TOTAL_COST_ORDER_ERROR =
  'MERCHANT_SUB_TOTAL_COST_ORDER_ERROR';
export const MERCHANT_COST_OF_SALES = 'MERCHANT_COST_OF_SALES';
export const MERCHANT_COST_OF_SALES_IS_LOADING =
  'MERCHANT_COST_OF_SALES_IS_LOADING';
export const MERCHANT_COST_OF_SALES_ERROR = 'MERCHANT_COST_OF_SALES_ERROR';
export const MERCHANT_ORDER_COST_OF_SALES = 'MERCHANT_ORDER_COST_OF_SALES';
export const MERCHANT_ORDER_TOTAL_DELIVERY_COST =
  'MERCHANT_ORDER_TOTAL_DELIVERY_COST';
export const MERCHANT_ORDER_COUNT = 'MERCHANT_ORDER_COUNT';
export const SELLER_PAYOUT = 'SELLER_PAYOUT';
export const SELLER_COMMISSION = 'SELLER_COMMISSION';
export const SELLER_SALES_HISTORY = 'SELLER_SALES_HISTORY';
export const SELLER_SALES_HISTORY_IS_LOADING =
  'SELLER_SALES_HISTORY_IS_LOADING';
export const SELLER_SALES_HISTORY_ERROR = 'SELLER_SALES_HISTORY_ERROR';

// DELIVERY LOGISTICS
export const BLOCK_DELIVERY_REPS = 'BLOCK_DELIVERY_REPS';
export const BLOCK_DELIVERY_REPS_IS_LOADING = 'BLOCK_DELIVERY_REPS_IS_LOADING';
export const ALL_DRIVERS = 'ALL_DRIVERS';
export const ALL_DRIVERS_IS_LOADING = 'ALL_DRIVERS_IS_LOADING';
export const GET_DRIVERS_TASKS = 'GET_DRIVERS_TASKS';
export const GET_DRIVERS_TASKS_IS_LOADING = 'GET_DRIVERS_TASKS_IS_LOADING';
export const GET_NEWLY_SIGNUP_REPS = 'GET_NEWLY_SIGNUP_REPS';
export const GET_NEWLY_SIGNUP_REPS_IS_LOADING =
  'GET_NEWLY_SIGNUP_REPS_IS_LOADING';
export const GET_A_DELIVERY_REP = 'GET_A_DELIVERY_REP';
export const GET_A_DELIVERY_REP_IS_LOADING = 'GET_A_DELIVERY_REP_IS_LOADING';
export const GET_DRIVER_DOCUMENT = 'GET_DRIVER_DOCUMENT';
export const GET_DRIVER_DOCUMENT_IS_LOADING = 'GET_DRIVER_DOCUMENT_IS_LOADING';
export const DRIVER_WALLET_DETAILS = 'DRIVER_WALLET_DETAILS';
export const DRIVER_WALLET_DETAILS_IS_LOADING =
  'DRIVER_WALLET_DETAILS_IS_LOADING';
export const DRIVER_TRANSACTIONS = 'DRIVER_TRANSACTIONS';
export const DRIVER_TRANSACTIONS_IS_LOADING = 'DRIVER_TRANSACTIONS_IS_LOADING';
export const PENDING_NEWLY_SIGN_DRIVERS = 'PENDING_NEWLY_SIGN_DRIVERS';
export const PENDING_NEWLY_SIGN_DRIVERS_IS_LOADING =
  'PENDING_NEWLY_SIGN_DRIVERS_IS_LOADING';
export const DRIVER_TASK_HISTORY = 'DRIVER_TASK_HISTORY';
export const DRIVER_TASK_HISTORY_IS_LOADING = 'DRIVER_TASK_HISTORY_IS_LOADING';
export const DRIVER_RATINGS = 'DRIVER_RATINGS';
export const DRIVER_RATINGS_IS_LOADING = 'DRIVER_RATINGS_IS_LOADING';
export const ACT_ON_DRIVER = 'ACT_ON_DRIVER';
export const ACT_ON_DRIVER_IS_LOADING = 'ACT_ON_DRIVER_IS_LOADING';
export const ACT_ON_DRIVER_ERROR = 'ACT_ON_DRIVER_ERROR';
export const GET_DRIVER_BLOCK_REQUEST = 'GET_DRIVER_BLOCK_REQUEST';
export const GET_DRIVER_BLOCK_REQUEST_IS_LOADING =
  'GET_DRIVER_BLOCK_REQUEST_IS_LOADING';
export const GET_DRIVER_TASK_COUNT = 'GET_DRIVER_TASK_COUNT';
export const GET_DRIVER_TASK_COUNT_IS_LOADING =
  'GET_DRIVER_TASK_COUNT_IS_LOADING';
export const ADMIN_BLOCK_DELIVERY_REPS = 'ADMIN_BLOCK_DELIVERY_REPS';
export const ADMIN_BLOCK_DELIVERY_REPS_IS_LOADING =
  'ADMIN_BLOCK_DELIVERY_REPS_IS_LOADING';
export const ADMIN_BLOCK_DELIVERY_REPS_ERROR =
  'ADMIN_BLOCK_DELIVERY_REPS_ERROR';
export const DELETE_ADMIN_BLOCK_DELIVERY_REPS_ERROR =
  'DELETE_ADMIN_BLOCK_DELIVERY_REPS_ERROR';
export const POST_BLOCK_REQUEST = 'POST_BLOCK_REQUEST';
export const POST_BLOCK_REQUEST_IS_LOADING = 'POST_BLOCK_REQUEST_IS_LOADING';
export const POST_BLOCK_REQUEST_ERROR = 'POST_BLOCK_REQUEST_ERROR';
export const DELETE_POST_BLOCK_REQUEST_ERROR =
  'DELETE_POST_BLOCK_REQUEST_ERROR';
export const ACT_ON_BLOCK_REQUEST = 'ACT_ON_BLOCK_REQUEST';
export const ACT_ON_BLOCK_REQUEST_IS_LOADING =
  'ACT_ON_BLOCK_REQUEST_IS_LOADING';
export const ACT_ON_BLOCK_REQUEST_ERROR = 'ACT_ON_BLOCK_REQUEST_IS_LOADING';
export const DELETE_DRIVER_DOCUMENT = 'DELETE_DRIVER_DOCUMENT';
export const DELETE_DRIVER_DOCUMENT_IS_LOADING =
  'DELETE_DRIVER_DOCUMENT_IS_LOADING';
export const DELETE_DRIVER_DOCUMENT_ERROR = 'DELETE_DRIVER_DOCUMENT_ERROR';
export const DELETE_DRIVER_DOCUMENT_ERROR_MESSAGE =
  'DELETE_DRIVER_DOCUMENT_ERROR_MESSAGE';
export const GET_TASK_DETAILS = 'GET_TASK_DETAILS';
export const GET_DELIVERY_REP_CURRENT_LOCATION =
  'GET_DELIVERY_REP_CURRENT_LOCATION';

//PRODUCTS SERVICE

export const GET_PRODUCTS_CATEGORIES = 'GET_PRODUCTS_CATEGORIES';
export const GET_PARENT_CATEGORIES = 'GET_PARENT_CATEGORIES';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export const CREATING_CATEGORY = 'CREATING_CATEGORY';
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR';
export const CATEGORY_CREATED = 'CATEGORY_CREATED';
export const SUB_CATEGORY_CREATED = 'SUB_CATEGORY_CREATED';
export const GET_GENERIC_PRODUCTS = 'GET_GENERIC_PRODUCTS';
export const GET_GENERIC_PRODUCTS_ERROR = 'GET_GENERIC_PRODUCTS_ERROR';
export const GET_PRODUCTS_IN_STORE = 'GET_PRODUCTS_IN_STORE';
export const GET_PRODUCTS_IN_STORE_ERROR = 'GET_PRODUCTS_IN_STORE_ERROR';
export const PRODUCTS_LOADING = 'PRODUCTS_LOADING';
export const GET_PRODUCTS_SUBCATEGORIES = 'GET_PRODUCTS_SUBCATEGORIES';
export const GET_REQUESTS_TO_ADMIN = 'GET_REQUESTS_TO_ADMIN';

// BRANDS
export const GET_BRANDS = 'GET_BRANDS';
export const GET_BRANDS_ERROR = 'GET_BRANDS_ERROR';
export const CREATING_BRAND = 'CREATING_BRAND';
export const BRAND_CREATED = 'BRAND_CREATED';
export const CREATING_BRAND_ERROR = 'CREATE_BRAND_MSG';
export const ON_CREATE_BRAND_LOAD = 'ON_CREATE_BRAND_LOAD';

// CREATE PRODUCTS

export const CREATING_PRODUCT = 'CREATING_PRODUCT';
export const PRODUCT_CREATED = 'PRODUCT_CREATED';
export const RESET_PRODUCT_CREATED = 'RESET_PRODUCT_CREATED';
export const CREATING_PRODUCT_ERROR = 'CREATING_PRODUCT_ERROR';
export const GET_PRODUCT_TAB_COUNT = 'GET_PRODUCT_TAB_COUNT';
