import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Row
} from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

import Searchbar from '../Searchbar/Searchbar';

import Notification from '../../assets/images/notification.svg';
import { ReactComponent as Logout } from '../../assets/svg/Logout.svg';
import { logOut } from '../../services/auth';

import { HeaderWrapper } from './style';
import { getSingleStoreRequest } from 'store/actions/stores/stores';
import isEmpty from 'is-empty';
import { ReactComponent as ArrowIcon } from 'assets/svg/leftIcon.svg';

const Header = props => {
  const [dropdownOpen, setOpen] = useState(false);
  // get url from the browser and split it to get the token
  const urlElements = window.location.href.split('/');
  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();
  const storeId = localStorage.getItem('storeId');

  console.log({ urlElements });

  const onLogOutUser = () => {
    dispatch(logOut(props));
    window.location.href = '/login';
  };

  useEffect(() => {
    if (storeId) {
      dispatch(getSingleStoreRequest(storeId));
    }
  }, [dispatch, storeId]);

  const {
    adminName,
    removeSearchBar,
    storeName,
    singleStoreDetailsIsLoading,
    driverDetailsIsLoading,
    driverName,
    merchantName,
    history
  } = props;

  return (
    <HeaderWrapper>
      <pre>{removeSearchBar}</pre>
      {removeSearchBar ? (
        <div className="storeHeaderNameContainer">
          <Row className="ml-2 mt-4">
            <span
              className="ml-5 storeHeaderText"
              style={{ cursor: 'pointer' }}
              onClick={history.goBack}
            >
              {urlElements.slice(3).map(el => (
                <>
                  <span>{el[0].toUpperCase() + el.slice(1)}</span>
                  <ArrowIcon className="ml-2 mr-2" />
                </>
              ))}
            </span>

            <span className="col-sm storeNameHeaderText">
              {urlElements[3] === 'delivery' ? (
                driverDetailsIsLoading ? (
                  <Skeleton width={150} />
                ) : (
                  driverName
                )
              ) : null}
              {urlElements[3] === 'store' ? (
                singleStoreDetailsIsLoading ? (
                  <Skeleton width={150} />
                ) : (
                  storeName
                )
              ) : null}
              {urlElements[3] === 'finance' && merchantName}
            </span>
          </Row>
        </div>
      ) : (
        <Searchbar />
      )}
      <div className="main-menu-wrap">
        <div className="menu-flex">
          <img src={Notification} alt="icon" />
          <div className="user-name">
            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret color="white">
                {adminName || <Skeleton width={100} />}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => onLogOutUser()}>
                  <Logout className="mr-1 logOutIcon" />
                  Sign Out
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  adminName: PropTypes.string,
  removeSearchBar: PropTypes.bool,
  storeName: PropTypes.string,
  singleStoreDetailsIsLoading: PropTypes.bool,
  driverName: PropTypes.any,
  driverDetailsIsLoading: PropTypes.bool,
  merchantName: PropTypes.string,
  history: PropTypes.any
};

const mapStateToProps = state => ({
  adminName: state.loginReducer.user.fullName,
  storeName: isEmpty(state.storeReducer.singleStoreDetails)
    ? 'N/A'
    : state.storeReducer.singleStoreDetails.data.storeName,
  singleStoreDetailsIsLoading:
    state.storeReducer.singleStoreDetailsIsLoading1234,
  driverName: isEmpty(state.deliveryReducer.driverDetails)
    ? 'N/A'
    : state.deliveryReducer.driverDetails.data.fullName,
  driverDetailsIsLoading: state.deliveryReducer.driverDetailsIsLoading,
  merchantName: isEmpty(state.financeReducer.userWalletDetails)
    ? 'N/A'
    : state.financeReducer.userWalletDetails.data.userId.fullName
});

export default withRouter(connect(mapStateToProps, null)(Header));
