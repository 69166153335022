import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { fetchBrands } from 'store/actions/products';
import { useDispatch, useSelector } from 'react-redux';

const isSearched = searchTerm => brand =>
  !searchTerm ||
  brand.brandName.toLowerCase().includes(searchTerm.toLowerCase());

const AddProductDetails = ({ setProductDetails, productInfo }) => {
  const [fields, setFields] = useState({
    productName: '',
    brandId: '',
    length: '',
    width: '',
    height: '',
    weight: '',
    volume: '',
    description: ''
  });
  const [productProperties, setProperties] = useState('');
  const dispatch = useDispatch();
  const { brands } = useSelector(state => state.productsReducer);
  const [responseLimit, setResponseLimit] = useState(50);
  const [searchTerm, setSearchTerm] = useState('');

  function handleChange(e) {
    let { name, value, type } = e.target;
    let stringFormat = /[^a-zA-Z]/g;

    setFields(prev => {
      return {
        ...prev,
        [name]: type === 'text' ? value.replace(stringFormat, '') : value
      };
    });
  }

  const handleDescription = e => {
    setFields(prev => {
      return {
        ...prev,
        description: e.target.value
      };
    });
  };

  const handleProductInfo = e => {
    setProperties(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      };
    });
  };

  useEffect(() => {
    if (productInfo.length > 0) {
      productInfo.forEach(item => {
        setProperties(prev => {
          return {
            ...prev,
            [item.name]: ''
          };
        });
      });
    }
  }, []);

  useEffect(() => {
    dispatch(fetchBrands(responseLimit, '__'));
  }, [dispatch, responseLimit]);

  useEffect(() => {
    if (productProperties !== '') {
      setProductDetails({
        ...fields,
        properties: productProperties
      });
    } else {
      setProductDetails(fields);
    }
  }, [fields, productProperties]);

  return (
    <ProductDetailsContainer>
      <div className="add-product-container">
        <section className="general-info info-wrap">
          <Accordion header="General Information">
            <div className="form-group">
              <label>Product Title</label>

              <input
                name="productName"
                className="form-input"
                type="text"
                onChange={handleChange}
                value={fields.productField}
              />
            </div>
            <div className="form-group">
              <label>Brand</label>
              <div className="search-container">
                <input
                  placeholder="search brands..."
                  onChange={e => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
                <select
                  name="brandId"
                  type="text"
                  className="form-input"
                  value={fields.brand}
                  onChange={handleChange}
                >
                  <option>Choose Brand</option>

                  {brands &&
                    brands.data.filter(isSearched(searchTerm)).map(brand => (
                      <option key={brand.brandId} value={brand.brandId}>
                        {brand.brandName}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="form-group dimension">
              <label style={{ width: '120px' }}>Dimension</label>
              <div className="dimension-group">
                <input
                  name="length"
                  value={fields.length}
                  className="form-input"
                  type="number"
                  min="0"
                  required
                  placeholder="Length"
                  onChange={handleChange}
                />
                <input
                  name="width"
                  value={fields.width}
                  className="form-input"
                  type="number"
                  min="0"
                  required
                  placeholder="Width"
                  onChange={handleChange}
                />
                <input
                  name="height"
                  value={fields.height}
                  className="form-input"
                  type="number"
                  min="0"
                  required
                  placeholder="Height"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group weight-volume-wrap">
              <div className="weight">
                <label>Weight</label>
                <input
                  name="weight"
                  value={fields.weight}
                  className="form-input"
                  type="number"
                  onChange={handleChange}
                />
              </div>
              <div className="volume">
                <label>Volume</label>
                <input
                  name="volume"
                  value={fields.volume}
                  className="form-input"
                  type="number"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                name="description"
                value={fields.description}
                onChange={handleDescription}
                className="form-input"
              />
            </div>
          </Accordion>
        </section>

        <section className="product-information info-wrap">
          <Accordion header="Product Information">
            {productInfo.length > 0 ? (
              <div className="product-info-form-group ">
                {productInfo.map((item, index) => (
                  <div className="form-group" key={index}>
                    <label>{item.name}</label>
                    <input
                      name={item.name}
                      value={productProperties[[item.name]]}
                      className="form-input"
                      type="text"
                      maxLength="5"
                      onChange={handleProductInfo}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="not-available">
                Product Information Not Available for the Category Selected
              </div>
            )}
          </Accordion>
        </section>

        <section className="size-info info-wrap">
          <Accordion header="Size Information"></Accordion>
        </section>
      </div>
    </ProductDetailsContainer>
  );
};

const Accordion = ({ header, children }) => {
  const [active, setActive] = useState(false);

  const activeHandler = () => {
    setActive(!active);
  };
  return (
    <AccordionWrap>
      <div className="header accordion-header" onClick={() => activeHandler()}>
        <h3>{header}</h3>
        <div className="arrrows">
          {active && (
            <span>
              <svg
                width="11"
                height="6"
                viewBox="0 0 11 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.38988 0.3L10.6016 5.3L9.87193 6L5.38988 1.7L0.90784 6L0.178205 5.3L5.38988 0.3Z"
                  fill="#1A171E"
                />
              </svg>
            </span>
          )}

          {!active && (
            <span>
              <svg
                width="11"
                height="6"
                viewBox="0 0 11 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.39137 5.9998L0.179687 
                0.999805L0.909323 0.299805L5.39137 4.5998L9.87341 0.299805L10.603 
                0.999805L5.39137 5.9998Z"
                  fill="#1A171E"
                />
              </svg>
            </span>
          )}
        </div>
      </div>
      <div className={`form accordion-body ${active ? 'active' : ''}`}>
        {children}
      </div>
    </AccordionWrap>
  );
};

const ProductDetailsContainer = styled.div`
  .add-product-container {
    max-width: 900px;
    background: #fff;
    margin: 0 auto;
    padding: 20px 60px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .info-wrap {
    border-top: 1px solid #e0e0e0;
    padding-top: 10px;
    h3 {
      font-size: 18px;
    }
  }
  .justify-between {
    justify-content: space-between;
  }

  .form {
  }

  .form-group {
    display: flex;
    align-items: center;
  }
  .form-group.weight-volume-wrap {
    justify-content: space-between;
  }
  .product-info-form-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 70px;
  }
  label {
    width: 120px;
    display: inline-block;
  }
  input,
  select {
    padding: 10px 5px;
  }
  input:focus,
  textarea:focus {
    outline: none;
  }
  .input-group {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .form-input {
    flex: 1;
    border: 1px solid #a1a4b1;
    border-radius: 5px;
  }

  .form {
    margin-top: 20px;
  }

  .dimension-group {
    display: flex;
    flex: 1;

    input:not(:first-child) {
      margin-left: 10px;
    }
  }
  .not-available {
    text-align: center;
    padding: 30px;
  }
  .error {
    border: 1px solid red;
    border-radius: 5px;
  }
  .error-message {
    color: red;
    font-size: 10px;
  }
  .search-container {
    display: flex;
    justify-content: space-around;
    gap: 1rem;
  }
  .search-container > select {
    width: 30em;
  }
  .search-container > input {
    width: 10em;
  }
  .hide {
    opacity: 0;
  }
`;

const AccordionWrap = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .accordion-body {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
  .accordion-body.active {
    max-height: 500px;
  }
`;

Accordion.propTypes = {
  header: PropTypes.string,
  children: PropTypes.any
};
AddProductDetails.propTypes = {
  setProductDetails: PropTypes.func,
  productInfo: PropTypes.array
};

export default AddProductDetails;
