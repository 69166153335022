import React from 'react';
import '../../assets/styles/loader.css';

const Loader = () => {
  return (
    <div className="loader-container">
      <span className="loading-spinner" />
    </div>
  );
};

export default Loader;
