// Add commas to Money
export const addCommasToMoney = x => {
  const filterAmount = x ? x : '';
  var parts = filterAmount.toString().split('.');
  return (
    parts[0].replace(/\B(?=(\d{3})+(?=$))/g, ',') +
    (parts[1] ? '.' + parts[1] : '')
  );
};

export const formatMoney = amount => {
  let formattedAmount = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 2
  }).format(amount);

  return formattedAmount;
};

export const currentTime = () => {
  const date = new Date();
  const [month, day, year] = [
    date.getMonth(),
    date.getDate(),
    date.getFullYear()
  ];
  const [hour, minutes, seconds] = [
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  ];
  return `${day}${month}${year}${hour}${minutes}${seconds}`;
};
