import toastr from 'toastr';
import { axios, _catchAxiosError } from 'services/axios';
import { BASE_URL } from 'utils/constants';
import {
  GET_MERCHANT_USERS_SUCCESS,
  GET_MERCHANT_USERS_ERROR,
  GET_MERCHANT_USERS_IS_LOADING,
  GET_DRIVER_USERS_SUCCESS,
  GET_DRIVER_USERS_ERROR,
  GET_DRIVER_USERS_IS_LOADING,
  GET_ADMIN_DETAILS,
  GET_USERS_COUNT,
  GET_SEARCH_RESULTS
} from '../types';

export const getAllMerchants = merchantData => ({
  type: GET_MERCHANT_USERS_SUCCESS,
  merchantData
});

export const getAllMerchantError = merchantError => ({
  type: GET_MERCHANT_USERS_ERROR,
  merchantError
});

export const getAllMerchantIsLoading = () => ({
  type: GET_MERCHANT_USERS_IS_LOADING
});

export const getAllDrivers = driverData => ({
  type: GET_DRIVER_USERS_SUCCESS,
  driverData
});

export const getAllDriversError = driverError => ({
  type: GET_DRIVER_USERS_ERROR,
  driverError
});

export const getAllDriverIsLoading = () => ({
  type: GET_DRIVER_USERS_IS_LOADING
});

export const getLoginAdminDetails = adminDetails => ({
  type: GET_ADMIN_DETAILS,
  adminDetails
});

export const getUserModuleSearchResults = searchResults => ({
  type: GET_SEARCH_RESULTS,
  searchResults
});

export const getUsersCount = () => async dispatch => {
  try {
    const {
      data: {
        meta: { total: merchants }
      }
    } = await axios.get(`${BASE_URL.USER}/allMerchants`);

    dispatch({
      type: GET_USERS_COUNT,
      payload: {
        name: 'merchants',
        value: merchants
      }
    });
  } catch (error) {
    dispatch({
      type: GET_USERS_COUNT,
      payload: {
        name: 'merchants',
        value: 0
      }
    });
  }

  try {
    const {
      data: {
        meta: { total: drivers }
      }
    } = await axios.get(`${BASE_URL.USER}/allDrivers`);

    dispatch({
      type: GET_USERS_COUNT,
      payload: {
        name: 'drivers',
        value: drivers
      }
    });
  } catch (error) {
    dispatch({
      type: GET_USERS_COUNT,
      payload: {
        name: 'drivers',
        value: 0
      }
    });
  }
};

export const getAllMerchantRequest = (
  query,
  currentPage,
  pageLimitCount,
  dateFilters,
  onError = false
) => async dispatch => {
  const { specificDate, startDate, endDate } = dateFilters;
  let params = {
    page: currentPage,
    limit: pageLimitCount,
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate })
  };
  try {
    dispatch(getAllMerchantIsLoading());

    const response = await axios.get(`${BASE_URL.USER}/allMerchants`, {
      params
    });

    const { data } = response;

    dispatch(getAllMerchants(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    dispatch(getAllMerchantError(reportError));
    toastr.error(reportError);
    throw error;
  }
};

export const getAllDriverRequest = (
  query,
  currentPage,
  pageLimitCount,
  dateFilters,
  onError = false
) => async dispatch => {
  const { specificDate, startDate, endDate } = dateFilters;
  let params = {
    page: currentPage,
    limit: pageLimitCount,
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate })
  };
  try {
    dispatch(getAllDriverIsLoading());
    const response = await axios.get(`${BASE_URL.USER}/allDrivers`, { params });
    const { data } = response;
    dispatch(getAllDrivers(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    dispatch(getAllDriversError(reportError));
    toastr.error(reportError);
    throw error;
  }
};

export const FetchLoginAdminDetails = (
  token,
  onError = false
) => async dispatch => {
  try {
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.ADMIN}/admin`
    });
    const { data } = response.data;
    dispatch(getLoginAdminDetails(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const searchUserModule = (
  q,
  index = 'service_storesearch'
) => async dispatch => {
  console.log('Q: ', q);
  try {
    const response = await axios({
      // method: 'get',
      url: `${BASE_URL.SEARCH}/search`,
      params: {
        q,
        index
      }
    });
    console.log('###: ', response);
    const {
      data: {
        body: {
          data: { docs }
        }
      }
    } = response;
    dispatch(getUserModuleSearchResults(docs));
  } catch (err) {
    console.log(err);
  }
};
