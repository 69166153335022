import {
  GET_MERCHANT_USERS_SUCCESS,
  GET_MERCHANT_USERS_ERROR,
  GET_MERCHANT_USERS_IS_LOADING,
  GET_DRIVER_USERS_SUCCESS,
  GET_DRIVER_USERS_ERROR,
  GET_DRIVER_USERS_IS_LOADING,
  GET_USERS_COUNT,
  GET_SEARCH_RESULTS
} from '../../actions/types';

const initialState = {
  getAllMerchantData: null,
  getAllMerchantIsLoading: false,
  getAllMerchantError: null,
  getAllDriverData: {},
  getAllDriverIsLoading: false,
  getAllDriverError: null,
  getUsersCount: {},
  getUserModuleSearchResults: []
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MERCHANT_USERS_SUCCESS:
      return {
        ...state,
        getAllMerchantData: action.merchantData,
        getAllMerchantIsLoading: false,
        getAllMerchantError: null
      };
    case GET_MERCHANT_USERS_ERROR:
      return {
        ...state,
        getAllMerchantData: [],
        getAllMerchantIsLoading: false,
        getAllMerchantError: action.merchantError
      };
    case GET_MERCHANT_USERS_IS_LOADING:
      return {
        ...state,
        getAllMerchantData: [],
        getAllMerchantIsLoading: true,
        getAllMerchantError: null
      };
    case GET_DRIVER_USERS_SUCCESS:
      return {
        ...state,
        getAllDriverData: action.driverData,
        getAllDriverIsLoading: false,
        getAllDriverError: null
      };
    case GET_DRIVER_USERS_ERROR:
      return {
        ...state,
        getAllDriverIsLoading: false,
        getAllDriverError: action.driverError
      };
    case GET_DRIVER_USERS_IS_LOADING:
      return {
        ...state,
        getAllDriverIsLoading: true
      };
    case GET_USERS_COUNT: {
      return {
        ...state,
        getUsersCount: {
          ...state.getUsersCount,
          [action.payload.name]: action.payload.value
        }
      };
    }
    case GET_SEARCH_RESULTS: {
      console.log('&&: ', action);
      return {
        ...state,
        getUserModuleSearchResults: action.searchResults
      };
    }
    default:
      return state;
  }
};

export default userReducer;
