import toastr from 'toastr';
import { axios, _catchAxiosError } from 'services/axios';
import {
  GET_ORDERS_ERROR,
  GET_ORDERS_LOADING,
  GET_ORDERS_SUCCESS,
  GET_SINGLE_ORDER,
  GET_SINGLE_ORDER_IS_LOADING,
  GET_TABS_TOTAL
} from '../types';
import { BASE_URL } from 'utils/constants';

export const getAllOrders = ordersData => ({
  type: GET_ORDERS_SUCCESS,
  ordersData
});

export const getAllOrdersError = ordersError => ({
  type: GET_ORDERS_ERROR,
  ordersError
});

export const isLoading = () => ({
  type: GET_ORDERS_LOADING
});

export const getOrderDetails = orderDetails => ({
  type: GET_SINGLE_ORDER,
  orderDetails
});

export const getOrderDetailsIsLoading = () => ({
  type: GET_SINGLE_ORDER_IS_LOADING
});

export const getTabsTotal = () => async dispatch => {
  try {
    const all = await axios.get(
      `${BASE_URL.ORDER}/admin_order?status=all&page=1&limit=10`
    );
    dispatch({
      type: GET_TABS_TOTAL,
      payload: {
        name: 'all',
        value: all.data.meta.total
      }
    });
  } catch (error) {
    dispatch({
      type: GET_TABS_TOTAL,
      payload: {
        name: 'all',
        value: 0
      }
    });
  }
  try {
    const pending = await axios.get(
      `${BASE_URL.ORDER}/admin_order?status=pending&page=1&limit=10`
    );
    dispatch({
      type: GET_TABS_TOTAL,
      payload: {
        name: 'pending',
        value: pending.data.meta.total
      }
    });
  } catch (error) {
    dispatch({
      type: GET_TABS_TOTAL,
      payload: {
        name: 'pending',
        value: 0
      }
    });
  }
  try {
    const accepted = await axios.get(
      `${BASE_URL.ORDER}/admin_order?status=confirmed&page=1&limit=10`
    );
    dispatch({
      type: GET_TABS_TOTAL,
      payload: {
        name: 'accepted',
        value: accepted.data.meta.total
      }
    });
  } catch (error) {
    dispatch({
      type: GET_TABS_TOTAL,
      payload: {
        name: 'accepted',
        value: 0
      }
    });
  }
  try {
    const pickedUp = await axios.get(
      `${BASE_URL.ORDER}/admin_order?status=in-transit&page=1&limit=10`
    );
    dispatch({
      type: GET_TABS_TOTAL,
      payload: {
        name: 'pickedUp',
        value: pickedUp.data.meta.total
      }
    });
  } catch (error) {
    dispatch({
      type: GET_TABS_TOTAL,
      payload: {
        name: 'pickedUp',
        value: 0
      }
    });
  }
  try {
    const completed = await axios.get(
      `${BASE_URL.ORDER}/admin_order?status=delivered&page=1&limit=10`
    );
    dispatch({
      type: GET_TABS_TOTAL,
      payload: {
        name: 'completed',
        value: completed.data.meta.total
      }
    });
  } catch (error) {
    dispatch({
      type: GET_TABS_TOTAL,
      payload: {
        name: 'completed',
        value: 0
      }
    });
  }
  try {
    const cancelled = await axios.get(
      `${BASE_URL.ORDER}/admin_order?status=cancelled&page=1&limit=10`
    );
    dispatch({
      type: GET_TABS_TOTAL,
      payload: {
        name: 'cancelled',
        value: cancelled.data.meta.total
      }
    });
  } catch (error) {
    dispatch({
      type: GET_TABS_TOTAL,
      payload: {
        name: 'cancelled',
        value: 0
      }
    });
  }
};

export const getOrdersRequest = (
  queryStatus,
  currentPage,
  pageLimitCount,
  dateFilter,
  onError = false
) => async dispatch => {
  try {
    dispatch(isLoading());
    //const baseUrl = `${BASE_URL.ORDER}/admin_order?status=${queryStatus}&page=${currentPage}&limit=${pageLimitCount}&specificDate=${specificDay}&startDate=${startDate}&lastDate=${lastDate}`;
    // const response = await axios({
    //   method: 'get',
    //   url: baseUrl,
    // });
    const { specificDate, startDate, endDate } = dateFilter;
    let params = {
      status: queryStatus,
      page: currentPage,
      limit: pageLimitCount,
      ...(specificDate !== '' && { specificDate }),
      ...(startDate !== '' && { startDate }),
      ...(endDate !== '' && { endDate })
    };

    const response = await axios.get(`${BASE_URL.ORDER}/admin_order`, {
      params
    });
    const { data } = response;

    dispatch(getAllOrders(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    dispatch(getAllOrdersError(reportError));
    //throw error;
  }
};

export const fetchOrderDetails = (
  orderId,
  onError = false
) => async dispatch => {
  try {
    dispatch(getOrderDetailsIsLoading());
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.ORDER}/view_admin_order/${orderId}`
    });
    const { data } = response;
    // console.log(`data`, data)
    dispatch(getOrderDetails(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const pausePayment = async (orderId, genericProductId, saleId) => {
  try {
    await axios({
      method: 'put',
      url: `${BASE_URL.ORDER}/pause-sale-payment`,
      data: { orderId, genericProductId, saleId }
    });
  } catch (error) {
    return error;
  }
};
export const releasePayment = (
  orderId,
  genericProductId,
  saleId,
  verdict
) => async dispatch => {
  try {
    await axios({
      method: 'put',
      url: `${BASE_URL.ORDER}/release-sale-payment`,
      data: { orderId, genericProductId, saleId, verdict }
    });
  } catch (error) {
    return error;
  }
};
