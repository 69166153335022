import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { createSubcategory } from 'store/actions/products';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import DotsLoader from 'components/Loaders/DotsLoader';
import toastr from 'toastr';

export function AddModal({ isOpen, setIsOpen, categoryId }) {
  const [isDragOver, setIsDragOver] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [files, setFiles] = useState([]);
  const [imgSrc, setSrc] = useState([]);
  const dropRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { creatingCategory } = useSelector(state => state.productsReducer);
  let formData = new FormData();
  const isDisabled = categoryName === '' || files.length === 0;
  console.log({ isDisabled });
  const handleFile = file => {
    console.log('**: ', file);
  };
  const previewChosenFile = file => {
    let reader = new FileReader();
    console.log('fi: ', file.target.files);
    if (file.target.files.length > 0) {
      reader.readAsDataURL(file.target.files[0]);
    } else {
      toastr.error('You did not choose a file');
      return;
    }
    reader.onloadend = function() {
      console.log('here ');
      let result = reader.result;
      setSrc(prev => [...prev, result]);
      setFiles(files => [...files, file.target.files[0]]);
    };
  };
  const previewDropFile = file => {
    let reader = new FileReader();
    reader.readAsDataURL(file.getAsFile());
    reader.onloadend = function() {
      console.log('here ');
      let result = reader.result;
      setSrc(prev => [...prev, result]);
      setFiles(files => [...files, result]);
    };
  };

  const handleDrop = ev => {
    ev.preventDefault();
    const { items } = ev.dataTransfer;
    // [...items].forEach(previewFile);
    if (items) {
      [...items].forEach((item, i) => {
        console.log('**: ', item.kind);
        if (item.kind === 'file') {
          const file = item.getAsFile();
          previewDropFile(item);
          console.log(`… file[${i}].name = ${file.name}`);
        }
      });
    } else {
      [...ev.dataTransfer.files].forEach((file, i) => {
        console.log(`$$ file[${i}].name = ${file.name}`);
      });
    }
  };

  const handleDragOver = ev => {
    ev.preventDefault();
    setIsDragOver(true);
  };

  const handleCreate = async () => {
    formData.append('categoryName', categoryName);
    formData.append('parentId', categoryId);
    formData.append('image', files[0]);
    formData.append('properties', JSON.stringify([]));
    formData.append('isVisibleToAll', false);

    await dispatch(createSubcategory(formData));
    history.push(`/products/${categoryId}/subcategories`);
    setIsOpen(false);
  };

  if (!isOpen) return null;
  return createPortal(
    <StyledOverlay>
      <div className="grid main">
        <StyledModal className="">
          <Close
            className="grid pointer ex"
            onClick={() => setIsOpen(!isOpen)}
          />
          <div className="subcat-container">
            <div className="heading">
              <h5>Add Sub Category</h5>
            </div>
            <div className="flex box">
              <div className="outer">
                <div className="flex inner">
                  <label htmlFor="categoryName">Sub Category:</label>
                  <input
                    name="categoryName"
                    value={categoryName}
                    placeholder="Sub Category Name"
                    onChange={e => setCategoryName(e.target.value)}
                  />
                </div>
              </div>
              <div className="outer">
                <div className="inner">
                  <p>Upload Image</p>
                  <div
                    className={`${isDragOver ? 'on' : 'off'}`}
                    onDrop={ev => handleDrop(ev)}
                    onDragOver={ev => handleDragOver(ev)}
                    onDragLeave={() => setIsDragOver(false)}
                  >
                    <div ref={dropRef} id="drop_zone">
                      <input
                        type="file"
                        onChange={previewChosenFile}
                        name="avatar"
                        id="avatar"
                        accept=".jpeg,.png"
                      />
                      <p>
                        Drag one or more files to this <i>drop zone</i>.
                      </p>
                      <div className="flex gallery">
                        {imgSrc?.map(file => (
                          <div key={file} className="upload">
                            <img src={file} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="action-btn">
                <button
                  className="btn btn-primary pointer"
                  onClick={handleCreate}
                  disabled={isDisabled}
                >
                  {creatingCategory && <DotsLoader />}
                  <span>
                    {creatingCategory ? 'Creating...' : 'Add Sub Category'}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </StyledModal>
      </div>
    </StyledOverlay>,
    document.getElementById('portal')
  );
}
const StyledOverlay = styled.div.attrs(() => {})`
  .pointer {
    cursor: pointer;
  }
  .grid {
    display: grid;
  }
  .flex {
    display: flex;
  }
  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  position: fixed;
  overflow: hidden;
  overscroll-behavior: contain;
  inset: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  &::after {
  }

  .main {
    position: relative;
    height: 100%;
    width: 100%;
    place-items: center;
  }
`;
const Close = ({ ...props }) => {
  return (
    <div {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 6L6 18M6 6L18 18"
          stroke="#fff"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
export const StyledSubCategories = styled.div`
  height: calc(100vh - 86px);
  background-color: #e5e5e5;
  overflow: auto;
  color: #413d45;
  font-size: 14px;
  padding-bottom: 10px;
  padding-inline: 4em;
  padding-block: 2em;

  ul {
    padding: 0;
  }

  li {
    list-style: none;
  }

  .flex {
    display: flex;
  }
  .pointer {
    cursor: pointer;
  }

  .btn {
    border: 1px solid transparent;
    border-radius: 3px;
    display: inline-block;
    line-height: 24px;
    margin-bottom: 0;
    min-height: 48px;
    padding: 12px 24px;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    white-space: nowrap;
  }

  .btn-primary {
    -webkit-appearance: none;
    appearance: none;
    background: #262262;
    border: 0;
    border-radius: 5px;
    color: #fff;
    outline: 0;
    transition-duration: 0.25s;
    &:hover {
      background: rgba(38, 34, 98, 0.7);
    }
  }

  .header {
    justify-content: flex-end;
  }

  .list {
    flex-wrap: wrap;
    gap: 3em;
    margin-top: 2em;
    align-items: center;
  }
`;
const StyledModal = styled.div`
  position: fixed;
  width: 40%;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  margin: 0 auto;
  /* padding-block: 1em; */
  z-index: 9;
  transition: transform 300ms;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(193, 193, 193, 0.16);
  /* transform: translateY(150%); */
  animation: 0.6s slide-up;
  text-align: left;
  height: min(90vh, 90%);

  .subcat-container {
    overflow: auto;
    height: 100%;
  }

  .btn {
    border: 1px solid transparent;
    border-radius: 3px;
    display: inline-block;
    line-height: 24px;
    margin-bottom: 0;
    min-height: 48px;
    padding: 12px 24px;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    white-space: nowrap;
  }

  .btn-primary {
    -webkit-appearance: none;
    appearance: none;
    background: #262262;
    border: 0;
    border-radius: 5px;
    color: #fff;
    outline: 0;
    transition-duration: 0.25s;
    &:hover {
      background: rgba(38, 34, 98, 0.7);
    }
  }

  .heading {
    padding: 1em 2em 0;
  }
  h5 {
    color: #767575;
    font-weight: 600;
    /* padding-left: 2.5em; */
  }

  label,
  p {
    font-size: 14px;
    font-weight: 400;
    color: #413d45;
    line-height: 21px;
    font-style: normal;
  }

  .box {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    > *:not(:last-child) {
      border-bottom: 0.3px solid #c4c4c4;
    }
    .outer {
      padding: 2em 0;
    }
    .inner {
      padding: 0 2em;
      align-items: center;
      width: 100%;
      gap: 2em;

      input:not([type='file']) {
        width: 60%;
        font-size: 14px;
        height: 48px;
        outline: transparent solid 2px;
        border: 1px solid #d6d6d6;
        border-radius: 4px;
        padding-inline: 0.7em;
      }
    }
  }

  .ex {
    position: absolute;
    right: -1%;
    top: -2%;
    z-index: 9999;
    place-items: center;
    background-color: #262262;
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
  }

  .action-btn {
    display: grid;
    place-items: center;
    padding: 2em 0;
  }

  .on {
    border: 2px dashed red;
    padding: 3em;
  }
  .off {
    border: 1px dashed #8d8d8d;
    padding: 3em;
  }
  .upload {
    width: 100px;
    height: 100px;
    object-fit: cover;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;
AddModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  categoryId: PropTypes.string
};
