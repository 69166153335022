import toastr from 'toastr';
import { axios, _catchAxiosError } from 'services/axios';
import { BASE_URL } from 'utils/constants';
import {
  GET_STORES,
  GET_ADMIN_STORE_REQUEST,
  GET_ADMIN_STORE_REQUEST_IS_LOADING,
  GET_STORES_IS_LOADING,
  GET_STORE_PRODUCTS,
  GET_STORE_PRODUCTS_IS_LOADING,
  GET_SINGLE_STORE_DETAILS,
  GET_SINGLE_STORE_DETAILS_IS_LOADING,
  GET_SINGLE_STORE_TRANSACTIONS,
  GET_SINGLE_STORE_TRANSACTIONS_IS_LOADING,
  GET_AVAILABLE_BALANCE,
  GET_AVAILABLE_BALANCE_IS_LOADING,
  ACT_ON_STORE,
  ACT_ON_STORE_ERROR,
  ACT_ON_STORE_IS_LOADING,
  REMOVE_ACT_ON_STORE_ERROR,
  GET_STORE_TABS_COUNT
} from '../types';

export const getStores = storeData => ({
  type: GET_STORES,
  storeData
});

export const getActiveStoreIsLoading = () => ({
  type: GET_STORES_IS_LOADING
});

export const getStoreAdminRequest = storeAdminRequestData => ({
  type: GET_ADMIN_STORE_REQUEST,
  storeAdminRequestData
});

export const getAdminStoreRequestIsLoading = () => ({
  type: GET_ADMIN_STORE_REQUEST_IS_LOADING
});

export const getStoreProducts = storeProductData => ({
  type: GET_STORE_PRODUCTS,
  storeProductData
});

export const getStoreProductIsLoading = () => ({
  type: GET_STORE_PRODUCTS_IS_LOADING
});

export const getSingleStore = singleStoreData => ({
  type: GET_SINGLE_STORE_DETAILS,
  singleStoreData
});

export const getSingleStoreIsLoading = () => ({
  type: GET_SINGLE_STORE_DETAILS_IS_LOADING
});

export const getStoreTransactions = storeTransactionData => ({
  type: GET_SINGLE_STORE_TRANSACTIONS,
  storeTransactionData
});

export const getStoreTransactionsIsLoading = () => ({
  type: GET_SINGLE_STORE_TRANSACTIONS_IS_LOADING
});

export const getStoreAvailableBalance = availableBalance => ({
  type: GET_AVAILABLE_BALANCE,
  availableBalance
});

export const getStoreAvailableBalanceIsLoading = () => ({
  type: GET_AVAILABLE_BALANCE_IS_LOADING
});

export const actOnStore = actOnStoreData => ({
  type: ACT_ON_STORE,
  actOnStoreData
});

export const actOnStoreError = actOnStoreError => ({
  type: ACT_ON_STORE_ERROR,
  actOnStoreError
});

export const actOnStoreIsLoading = () => ({
  type: ACT_ON_STORE_IS_LOADING
});

export const removeActOnStoreError = () => ({
  type: REMOVE_ACT_ON_STORE_ERROR
});

//store tabs count

export const getStoreTabsCount = () => async dispatch => {
  try {
    const {
      data: {
        meta: { total: approved }
      }
    } = await axios.get(`${BASE_URL.STORE}/stores?status=approved`);

    dispatch({
      type: GET_STORE_TABS_COUNT,
      payload: {
        name: 'approved',
        value: approved
      }
    });
  } catch (error) {
    dispatch({
      type: GET_STORE_TABS_COUNT,
      payload: {
        name: 'approved',
        value: 0
      }
    });
  }

  try {
    const {
      data: {
        meta: { total: blocked }
      }
    } = await axios.get(`${BASE_URL.STORE}/stores?status=blocked`);

    dispatch({
      type: GET_STORE_TABS_COUNT,
      payload: {
        name: 'blocked',
        value: blocked
      }
    });
  } catch (error) {
    dispatch({
      type: GET_STORE_TABS_COUNT,
      payload: {
        name: 'blocked',
        value: 0
      }
    });
  }

  try {
    const {
      data: {
        meta: { total: disapproved }
      }
    } = await axios.get(`${BASE_URL.STORE}/stores?status=disapproved`);

    dispatch({
      type: GET_STORE_TABS_COUNT,
      payload: {
        name: 'disapproved',
        value: disapproved
      }
    });
  } catch (error) {
    dispatch({
      type: GET_STORE_TABS_COUNT,
      payload: {
        name: 'disapproved',
        value: 0
      }
    });
  }

  try {
    const {
      data: {
        meta: { total: adminRequests }
      }
    } = await axios.get(`${BASE_URL.STORE}/store-requests`);
    dispatch({
      type: GET_STORE_TABS_COUNT,
      payload: {
        name: 'adminRequests',
        value: adminRequests
      }
    });
  } catch (error) {
    dispatch({
      type: GET_STORE_TABS_COUNT,
      payload: {
        name: 'adminRequests',
        value: 0
      }
    });
  }

  try {
    const {
      data: {
        meta: { total: pending }
      }
    } = await axios.get(`${BASE_URL.STORE}/stores?status=pending`);

    dispatch({
      type: GET_STORE_TABS_COUNT,
      payload: {
        name: 'pending',
        value: pending
      }
    });
  } catch (error) {
    dispatch({
      type: GET_STORE_TABS_COUNT,
      payload: {
        name: 'pending',
        value: 0
      }
    });
  }
};

// function that handles get all stores
export const getAllStores = (
  queryPayload,
  onError = false
) => async dispatch => {
  let {
    query,
    queryType,
    currentPage,
    pageLimitCount,
    type,
    dateFilter
  } = queryPayload;

  const { specificDate, startDate, endDate } = dateFilter;

  let params = {
    status: query,
    page: currentPage,
    limit: pageLimitCount,
    type,
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate })
  };

  try {
    dispatch(getActiveStoreIsLoading());

    const response = await axios.get(`${BASE_URL.STORE}/stores`, { params });
    dispatch(getStores(response.data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

// function that handles get all admin store requests
export const getAllAdminStoreRequest = (
  queryPayload,
  onError = false
) => async dispatch => {
  let {
    queryType,
    searchType,
    searchParams,
    currentPage,
    pageLimitCount = localStorage.getItem('paginationSetValue'),
    type
  } = queryPayload;
  searchParams =
    type === 'specificDate'
      ? localStorage.getItem('specificDate')
      : localStorage.getItem('startDate');
  try {
    dispatch(getAdminStoreRequestIsLoading());
    const response = await axios({
      method: 'get',
      url: `${
        BASE_URL.STORE
      }/store-requests?page=${currentPage}&limit=${pageLimitCount}&type=${queryType}&${searchType}=${searchParams ||
        undefined}`
    });
    const { data } = response;
    dispatch(getStoreAdminRequest(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const getStoreProductsRequest = (
  storeId,
  currentPage,
  pageLimitCount = localStorage.getItem('paginationSetValue'),
  onError = false
) => async dispatch => {
  try {
    dispatch(getStoreProductIsLoading());
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.PRODUCT}/v1/storeproducts/user/${storeId}?limit=${pageLimitCount}&page=${currentPage}`
    });
    const { data } = response;
    dispatch(getStoreProducts(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const getSingleStoreRequest = (
  storeId,
  onError = false
) => async dispatch => {
  try {
    dispatch(getSingleStoreIsLoading());
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.STORE}/store/${storeId}`
    });
    const { data } = response;
    dispatch(getSingleStore(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const getStoreTransactionRequest = (
  payload,
  onError = false
) => async dispatch => {
  try {
    dispatch(getStoreTransactionsIsLoading());
    const { userId, typeQuery } = payload;
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/user/transactions/${userId}?type=${typeQuery}&limit=10&page=1`
    });
    const { data } = response;
    dispatch(getStoreTransactions(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const getStoreAvailableBalanceRequest = (
  userId,
  onError = false
) => async dispatch => {
  try {
    dispatch(getStoreAvailableBalanceIsLoading());
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/user/admin/wallet/${userId}`
    });
    const { data } = response;
    dispatch(getStoreAvailableBalance(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const actOnStoreRequest = (
  payload,
  onError = false
) => async dispatch => {
  const { storeId } = payload;
  try {
    dispatch(actOnStoreIsLoading());
    const response = await axios({
      method: 'post',
      url: `${BASE_URL.STORE}/act-on-store/${storeId}`,
      data: payload
    });
    const { data } = response;
    dispatch(actOnStore(data));
  } catch (error) {
    console.log(error);
    const reportError = await _catchAxiosError(error, onError);
    dispatch(actOnStoreError(reportError));
    toastr.error(reportError);
    //throw error;
  }
};
