/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logOut } from 'services/auth';

const PrivateRoutes = (ComposedComponent) => {
  /**
 * @class Authenticate
 */
  class Authenticate extends Component {
    // eslint-disable-next-line require-jsdoc
    componentDidMount() {
      const {
        isAuthenticated,
        isAuthenticatedLogin,
        history
      } = this.props;
      if (!isAuthenticated && !isAuthenticatedLogin) {
        const { Logout } = this.props;
        Logout()
        history.push('/login');
      }
    }

    // eslint-disable-next-line require-jsdoc
    componentDidUpdate(nextProps) {
      // eslint-disable-next-line react/prop-types
      const {
        history,
      } = this.props;
      if (!nextProps.isAuthenticated && !nextProps.isAuthenticatedLogin) {
        const { Logout } = this.props;
        Logout()
        history.push('/login');
      }
    }

    /**
   *
   * @returns {*} - render
   */
    render() {
      return (
        <ComposedComponent {...this.props} />
      );
    }
  }

  Authenticate.propsTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isAuthenticatedLogin: PropTypes.bool,
    match: PropTypes.shape({}).isRequired,
    userSignUpId: PropTypes.string,
    userLoginId: PropTypes.string,
    Logout: PropTypes.func,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  Authenticate.contextTypes = {
    router: PropTypes.shape({})
  };

  const mapStateToProps = state => ({
    isAuthenticated: state.signUpReducer.isAuthenticated,
    isAuthenticatedLogin: state.loginReducer.isAuthenticated,
  });

  const mapDispatchToProps = dispatch => ({
    Logout: () => dispatch(logOut())
  });

  return connect(mapStateToProps, mapDispatchToProps)(Authenticate);
};

export default PrivateRoutes;
