import toastr from 'toastr';
import { axios, _catchAxiosError } from 'services/axios';
import { BASE_URL } from 'utils/constants';
import {
  BLOCK_DELIVERY_REPS,
  BLOCK_DELIVERY_REPS_IS_LOADING,
  ALL_DRIVERS,
  ALL_DRIVERS_IS_LOADING,
  GET_DRIVERS_TASKS,
  GET_DRIVERS_TASKS_IS_LOADING,
  GET_NEWLY_SIGNUP_REPS,
  GET_NEWLY_SIGNUP_REPS_IS_LOADING,
  GET_A_DELIVERY_REP,
  GET_A_DELIVERY_REP_IS_LOADING,
  GET_DRIVER_DOCUMENT,
  GET_DRIVER_DOCUMENT_IS_LOADING,
  DRIVER_WALLET_DETAILS,
  DRIVER_WALLET_DETAILS_IS_LOADING,
  DRIVER_TRANSACTIONS,
  DRIVER_TRANSACTIONS_IS_LOADING,
  PENDING_NEWLY_SIGN_DRIVERS,
  PENDING_NEWLY_SIGN_DRIVERS_IS_LOADING,
  DRIVER_TASK_HISTORY,
  DRIVER_TASK_HISTORY_IS_LOADING,
  DRIVER_RATINGS,
  DRIVER_RATINGS_IS_LOADING,
  ACT_ON_DRIVER,
  ACT_ON_DRIVER_IS_LOADING,
  ACT_ON_DRIVER_ERROR,
  GET_DRIVER_BLOCK_REQUEST,
  GET_DRIVER_BLOCK_REQUEST_IS_LOADING,
  GET_DRIVER_TASK_COUNT,
  GET_DRIVER_TASK_COUNT_IS_LOADING,
  ADMIN_BLOCK_DELIVERY_REPS,
  ADMIN_BLOCK_DELIVERY_REPS_IS_LOADING,
  ADMIN_BLOCK_DELIVERY_REPS_ERROR,
  DELETE_ADMIN_BLOCK_DELIVERY_REPS_ERROR,
  POST_BLOCK_REQUEST,
  POST_BLOCK_REQUEST_IS_LOADING,
  POST_BLOCK_REQUEST_ERROR,
  DELETE_POST_BLOCK_REQUEST_ERROR,
  ACT_ON_BLOCK_REQUEST,
  ACT_ON_BLOCK_REQUEST_IS_LOADING,
  // DELETE_DRIVER_DOCUMENT,
  DELETE_DRIVER_DOCUMENT_IS_LOADING,
  DELETE_DRIVER_DOCUMENT_ERROR,
  DELETE_DRIVER_DOCUMENT_ERROR_MESSAGE,
  GET_DRIVERS_COUNT,
  GET_TASK_MANAGEMENT_COUNT,
  GET_NEWLY_SIGNUP_COUNT,
  GET_TASK_DETAILS,
  GET_DELIVERY_REP_CURRENT_LOCATION
} from '../types';

export const getBlockedDrivers = blockedDrivers => ({
  type: BLOCK_DELIVERY_REPS,
  blockedDrivers
});

export const getBlockedDriversIsLoading = () => ({
  type: BLOCK_DELIVERY_REPS_IS_LOADING
});

export const getAllDrivers = allDrivers => ({
  type: ALL_DRIVERS,
  allDrivers
});

export const getAllDriverIsLoading = () => ({
  type: ALL_DRIVERS_IS_LOADING
});

export const getDriverTasks = taskData => ({
  type: GET_DRIVERS_TASKS,
  taskData
});

export const getDriverTaskIsLoading = () => ({
  type: GET_DRIVERS_TASKS_IS_LOADING
});

export const getNewlySignUpReps = driversData => ({
  type: GET_NEWLY_SIGNUP_REPS,
  driversData
});

export const getNewlySignUpRepsIsLoading = () => ({
  type: GET_NEWLY_SIGNUP_REPS_IS_LOADING
});

export const getADeliveryRep = driverDetails => ({
  type: GET_A_DELIVERY_REP,
  driverDetails
});

export const getADeliveryRepIsLoading = () => ({
  type: GET_A_DELIVERY_REP_IS_LOADING
});

export const getDriverDocuments = driverDocuments => ({
  type: GET_DRIVER_DOCUMENT,
  driverDocuments
});

export const getDriverDocumentsIsLoading = () => ({
  type: GET_DRIVER_DOCUMENT_IS_LOADING
});

export const getDriverWalletDetails = driverWalletDetails => ({
  type: DRIVER_WALLET_DETAILS,
  driverWalletDetails
});

export const getDriverWalletDetailsIsLoading = () => ({
  type: DRIVER_WALLET_DETAILS_IS_LOADING
});

export const getDriverTransaction = driverTransactions => ({
  type: DRIVER_TRANSACTIONS,
  driverTransactions
});

export const getDriverTransactionIsLoading = () => ({
  type: DRIVER_TRANSACTIONS_IS_LOADING
});

export const getPendingNewlySignedDrivers = pendingDrivers => ({
  type: PENDING_NEWLY_SIGN_DRIVERS,
  pendingDrivers
});

export const getPendingNewlySignedDriversIsLoading = () => ({
  type: PENDING_NEWLY_SIGN_DRIVERS_IS_LOADING
});

export const getDriverTaskHistory = taskHistory => ({
  type: DRIVER_TASK_HISTORY,
  taskHistory
});

export const getDriverTaskHistoryIsLoading = () => ({
  type: DRIVER_TASK_HISTORY_IS_LOADING
});

export const getDriverRating = driverRatings => ({
  type: DRIVER_RATINGS,
  driverRatings
});

export const getDriverRatingIsLoading = () => ({
  type: DRIVER_RATINGS_IS_LOADING
});

export const actOnDriver = actedDriverData => ({
  type: ACT_ON_DRIVER,
  actedDriverData
});

export const actOnDriverIsLoading = () => ({
  type: ACT_ON_DRIVER_IS_LOADING
});

export const actOnDriverError = actedDriverDataError => ({
  type: ACT_ON_DRIVER_ERROR,
  actedDriverDataError
});

export const getBlockDriverRequest = blockedDrivers => ({
  type: GET_DRIVER_BLOCK_REQUEST,
  blockedDrivers
});

export const getBlockDriverRequestIsLoading = () => ({
  type: GET_DRIVER_BLOCK_REQUEST_IS_LOADING
});

export const getDriverTaskCount = taskCount => ({
  type: GET_DRIVER_TASK_COUNT,
  taskCount
});

export const getDriverTaskCountIsLoading = () => ({
  type: GET_DRIVER_TASK_COUNT_IS_LOADING
});

export const adminBlockDriver = blockDetails => ({
  type: ADMIN_BLOCK_DELIVERY_REPS,
  blockDetails
});

export const adminBlockDriverIsLoading = () => ({
  type: ADMIN_BLOCK_DELIVERY_REPS_IS_LOADING
});

export const adminBlockDriverError = blockDetailsError => ({
  type: ADMIN_BLOCK_DELIVERY_REPS_ERROR,
  blockDetailsError
});

export const sendBlockRequest = blockRequestDetails => ({
  type: POST_BLOCK_REQUEST,
  blockRequestDetails
});

export const sendBlockRequestIsLoading = () => ({
  type: POST_BLOCK_REQUEST_IS_LOADING
});

export const sendBlockRequestError = blockRequestError => ({
  type: POST_BLOCK_REQUEST_ERROR,
  blockRequestError
});

export const deleteSendBlockRequestError = () => ({
  type: DELETE_POST_BLOCK_REQUEST_ERROR
});

export const deleteAdminBlockDriverError = () => ({
  type: DELETE_ADMIN_BLOCK_DELIVERY_REPS_ERROR
});

export const actOnBlockRequest = actOnBlockResponse => ({
  type: ACT_ON_BLOCK_REQUEST,
  actOnBlockResponse
});

export const actOnBlockRequestIsLoading = () => ({
  type: ACT_ON_BLOCK_REQUEST_IS_LOADING
});

export const delDriverDocumentIsLoading = () => ({
  type: DELETE_DRIVER_DOCUMENT_IS_LOADING
});

export const delDriverDocumentError = delDocumentError => ({
  type: DELETE_DRIVER_DOCUMENT_ERROR,
  delDocumentError
});

export const deleteDriverDocumentError = () => ({
  type: DELETE_DRIVER_DOCUMENT_ERROR_MESSAGE
});

const getCurrentDeliveryRepLocation = data => ({
  type: GET_DELIVERY_REP_CURRENT_LOCATION,
  delRepCurrentLocation: data
});

export const fetchCurrentDeliveryRepLocation = (
  id,
  onError = false
) => async dispatch => {
  try {
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.LOGISTICS}/delivery-reps/${id}`
    });
    const { data } = response;
    dispatch(getCurrentDeliveryRepLocation(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const fetchDriversCounts = () => async dispatch => {
  try {
    const {
      data: {
        meta: { total: allDrivers }
      }
    } = await axios.get(`${BASE_URL.LOGISTICS}/delivery-reps?status=approved`);

    dispatch({
      type: GET_DRIVERS_COUNT,
      payload: {
        name: 'allDrivers',
        value: allDrivers
      }
    });
  } catch (error) {
    dispatch({
      type: GET_DRIVERS_COUNT,
      payload: {
        name: 'allDrivers',
        value: 0
      }
    });
  }

  try {
    const {
      data: {
        meta: { total: blockedRequests }
      }
    } = await axios.get(`${BASE_URL.LOGISTICS}/delivery-rep/blocked/requests`);

    dispatch({
      type: GET_DRIVERS_COUNT,
      payload: {
        name: 'blockedRequests',
        value: blockedRequests
      }
    });
  } catch (error) {
    dispatch({
      type: GET_DRIVERS_COUNT,
      payload: {
        name: 'blockedRequests',
        value: 0
      }
    });
  }

  try {
    const {
      data: {
        meta: { total: blockedDrivers }
      }
    } = await axios.get(
      `${BASE_URL.LOGISTICS}/blocked/delivery-reps?blocked=true`
    );
    dispatch({
      type: GET_DRIVERS_COUNT,
      payload: {
        name: 'blockedDrivers',
        value: blockedDrivers
      }
    });
  } catch (error) {
    dispatch({
      type: GET_DRIVERS_COUNT,
      payload: {
        name: 'blockedDrivers',
        value: 0
      }
    });
  }

  try {
    const { data } = await axios.get(
      `${BASE_URL.LOGISTICS}/delivery-reps?status=approved&endDate=2021-04-20&startDate=2021-03-29&type=filter`
    );
  } catch (error) {
    console.error(error);
  }
};

export const fetchTaskManagementCount = () => async dispatch => {
  try {
    const {
      data: {
        meta: { total: all }
      }
    } = await axios.get(`${BASE_URL.LOGISTICS}/all-tasks?status=ALL`);
    const {
      data: {
        meta: { total: pending }
      }
    } = await axios.get(`${BASE_URL.LOGISTICS}/all-tasks?status=PENDING`);
    const {
      data: {
        meta: { total: accepted }
      }
    } = await axios.get(`${BASE_URL.LOGISTICS}/all-tasks?status=ACCEPTED`);
    const {
      data: {
        meta: { total: completed }
      }
    } = await axios.get(`${BASE_URL.LOGISTICS}/all-tasks?status=COMPLETED`);
    const {
      data: {
        meta: { total: cancelled }
      }
    } = await axios.get(`${BASE_URL.LOGISTICS}/all-tasks?status=CANCELLED`);

    dispatch({
      type: GET_TASK_MANAGEMENT_COUNT,
      payload: {
        all,
        pending,
        accepted,
        completed,
        cancelled
      }
    });
  } catch (error) {
    return error;
  }
};

export const fetchNewlySignupCount = () => async dispatch => {
  try {
    const {
      data: {
        meta: { total: approved }
      }
    } = await axios.get(`${BASE_URL.LOGISTICS}/delivery-reps?status=approved`);
    const {
      data: {
        meta: { total: disapproved }
      }
    } = await axios.get(
      `${BASE_URL.LOGISTICS}/delivery-reps?status=disapproved`
    );
    const {
      data: {
        meta: { total: pending }
      }
    } = await axios.get(`${BASE_URL.LOGISTICS}/delivery-reps?status=pending`);

    dispatch({
      type: GET_NEWLY_SIGNUP_COUNT,
      payload: {
        approved,
        disapproved,
        pending
      }
    });
  } catch (error) {
    return error;
  }
};

export const fetchBlockedDrivers = (
  currentPage,
  pageLimitCount,
  dateFilters,
  onError = false
) => async dispatch => {
  const { specificDate, startDate, endDate } = dateFilters;

  let params = {
    blocked: 'true',
    page: currentPage,
    limit: pageLimitCount,
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate }),
    type: 'filter'
  };
  try {
    dispatch(getBlockedDriversIsLoading());
    const response = await axios.get(
      `${BASE_URL.LOGISTICS}/blocked/delivery-reps`,
      { params }
    );
    const { data } = response;
    dispatch(getBlockedDrivers(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const fetchAllDrivers = (
  currentPage,
  pageLimitCount,
  onError = false
) => async dispatch => {
  try {
    dispatch(getAllDriverIsLoading());
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.LOGISTICS}/approved-delivery-rep?page=${currentPage}&limit=${pageLimitCount}`
    });
    const { data } = response;
    dispatch(getAllDrivers(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const fetchDriverTasks = (
  status,
  currentPage,
  pageLimitCount,
  dateFilters,
  onError = false
) => async dispatch => {
  const { specificDate, startDate, endDate } = dateFilters;
  let params = {
    status,
    page: currentPage,
    limit: pageLimitCount,
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate }),
    type: 'filter'
  };

  try {
    dispatch(getDriverTaskIsLoading());
    const response = await axios(`${BASE_URL.LOGISTICS}/all-tasks`, { params });
    const { data } = response;
    dispatch(getDriverTasks(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const fetchNewlySignDrivers = (
  queryPayload,
  onError = false
) => async dispatch => {
  let { currentPage, pageLimitCount, query, dateFilters } = queryPayload;
  const { specificDate, startDate, endDate } = dateFilters;
  let params = {
    status: query,
    page: currentPage,
    limit: pageLimitCount,
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate }),
    type: 'filter'
  };

  try {
    dispatch(getNewlySignUpRepsIsLoading());
    const response = await axios.get(`${BASE_URL.LOGISTICS}/delivery-reps`, {
      params
    });
    const { data } = response;
    dispatch(getNewlySignUpReps(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const fetchPendingNewlySignedDrivers = (
  queryPayload,
  onError = false
) => async dispatch => {
  let { currentPage, pageLimitCount, query, dateFilters } = queryPayload;
  const { specificDate, startDate, endDate } = dateFilters;
  let params = {
    status: query,
    page: currentPage,
    limit: pageLimitCount,
    ...(specificDate !== '' && { specificDate }),
    ...(startDate !== '' && { startDate }),
    ...(endDate !== '' && { endDate }),
    type: 'filter'
  };
  try {
    dispatch(getPendingNewlySignedDriversIsLoading());
    const response = await axios.get(`${BASE_URL.LOGISTICS}/delivery-reps`, {
      params
    });
    const { data } = response;
    dispatch(getPendingNewlySignedDrivers(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const fetchDriverDetails = (
  driverId,
  onError = false
) => async dispatch => {
  dispatch(getADeliveryRepIsLoading());
  try {
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.LOGISTICS}/delivery-reps/${driverId}`
    });
    const { data } = response;
    dispatch(getADeliveryRep(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const fetchDriverDocuments = (
  driverId,
  onError = false
) => async dispatch => {
  try {
    dispatch(getDriverDocumentsIsLoading());
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.LOGISTICS}/delivery-rep-documents/${driverId}`
    });
    const { data } = response.data;
    dispatch(getDriverDocuments(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const fetchDriverWalletDetails = (
  driverId,
  onError = false
) => async dispatch => {
  try {
    dispatch(getDriverWalletDetailsIsLoading());
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/user/wallet/details?userId=${driverId}`
    });
    const { data } = response.data;
    dispatch(getDriverWalletDetails(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const fetchDriverTransactions = (
  payload,
  onError = false
) => async dispatch => {
  const { driverId, query } = payload;
  try {
    dispatch(getDriverTransactionIsLoading());
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.TRANSACTION}/dashboard/user/transactions/${driverId}?type=${query}`
    });
    const { data } = response;
    dispatch(getDriverTransaction(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const fetchDriverTransactionDetails = (
  payload,
  onError = false
) => async dispatch => {
  try {
    dispatch(getDriverTaskHistoryIsLoading());
    const { currentPage, pageLimitCount, driverId } = payload;
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.LOGISTICS}/get-task-history/${driverId}?page=${currentPage}&limit=${pageLimitCount}`
    });
    const { data } = response;
    dispatch(getDriverTaskHistory(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const fetchDriverRatings = (
  driverId,
  onError = false
) => async dispatch => {
  try {
    dispatch(getDriverRatingIsLoading());
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.LOGISTICS}/delivery-rep-ratings/${driverId}`
    });
    const { data } = response;
    dispatch(getDriverRating(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const approveDisapproveDriver = (
  payload,
  onError = false
) => async dispatch => {
  try {
    dispatch(actOnDriverIsLoading());
    const { driverId } = payload;
    const response = await axios({
      method: 'post',
      url: `${BASE_URL.LOGISTICS}/approve-disapprove/delivery-rep/${driverId}`,
      data: payload
    });
    const { data } = response;
    dispatch(actOnDriver(data));
    toastr.success(data.message);
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    dispatch(actOnDriverError(reportError));
    toastr.error(reportError);
    throw error;
  }
};

export const getDriverBlockRequest = (onError = false) => async dispatch => {
  dispatch(getBlockDriverRequestIsLoading());
  try {
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.LOGISTICS}/delivery-rep/blocked/requests`
    });
    const { data } = response;
    dispatch(getBlockDriverRequest(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const fetchDriverTaskCount = (
  driverId,
  onError = false
) => async dispatch => {
  dispatch(getDriverTaskCountIsLoading());
  try {
    const response = await axios({
      method: 'get',
      url: `${BASE_URL.LOGISTICS}/get-driver-ride-count/${driverId}`
    });
    const { data } = response;
    dispatch(getDriverTaskCount(data));
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const postBlockDriver = (payload, onError = false) => async dispatch => {
  dispatch(adminBlockDriverIsLoading());
  try {
    const { driverId } = payload;

    const response = await axios({
      method: 'post',
      url: `${BASE_URL.LOGISTICS}/block-delivery-reps/${driverId}`,
      data: payload
    });
    const { data } = response;
    dispatch(adminBlockDriver(data));
    toastr.success(data.message);
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    dispatch(adminBlockDriverError(reportError));
    toastr.error(reportError);
    throw error;
  }
};

export const postBlockRequest = (
  payload,
  onError = false
) => async dispatch => {
  dispatch(sendBlockRequestIsLoading());
  try {
    const { driverId } = payload;
    const response = await axios({
      method: 'post',
      url: `${BASE_URL.LOGISTICS}/delivery-rep/send-block-request/${driverId}`,
      data: payload
    });
    const { data } = response;
    dispatch(sendBlockRequest(data));
    toastr.success(data.message);
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    dispatch(sendBlockRequestError(reportError));
    toastr.error(reportError);
    throw error;
  }
};

export const postActOnBlockRequest = (
  driverId,
  onError = false
) => async dispatch => {
  dispatch(actOnBlockRequestIsLoading());
  try {
    const response = await axios({
      method: 'patch',
      url: `${BASE_URL.LOGISTICS}/delivery-rep/block-request/${driverId}`
    });
    const { data } = response;
    dispatch(actOnBlockRequest(data));
    toastr.success(data.message);
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    toastr.error(reportError);
    throw error;
  }
};

export const postDeleteDriverDocumentRequest = (
  payload,
  onError = false
) => async dispatch => {
  dispatch(delDriverDocumentIsLoading());
  const { driverId, docType } = payload;
  try {
    const response = await axios({
      method: 'patch',
      url: `${BASE_URL.LOGISTICS}/delete-driver-document/${driverId}?documentType=${docType}`,
      data: payload
    });
    const { data } = response;
    toastr.success(data.message);
  } catch (error) {
    const reportError = await _catchAxiosError(error, onError);
    dispatch(delDriverDocumentError(reportError));
    toastr.error(reportError);
    throw error;
  }
};

export const fetchTaskDetails = taskId => async dispatch => {
  try {
    const { data } = await axios.get(
      `${BASE_URL.LOGISTICS}/get-pick-up-details/${taskId}`
    );
    dispatch({
      type: GET_TASK_DETAILS,
      payload: {
        name: 'pickupDetails',
        value: data
      }
    });
  } catch (error) {
    dispatch({
      type: GET_TASK_DETAILS,
      payload: {
        name: 'pickupDetails',
        value: 'cannot retrieve pickup details'
      }
    });
  }
  try {
    const { data } = await axios.get(
      `${BASE_URL.LOGISTICS}/get-drop-off-details/${taskId}`
    );
    dispatch({
      type: GET_TASK_DETAILS,
      payload: {
        name: 'dropoffDetails',
        value: data
      }
    });
  } catch (error) {
    dispatch({
      type: GET_TASK_DETAILS,
      payload: {
        name: 'dropoffDetails',
        value: 'cannot retrieve dropoff details'
      }
    });
  }
  try {
    const { data } = await axios.get(
      `${BASE_URL.LOGISTICS}/get-single-task/${taskId}`
    );
    dispatch({
      type: GET_TASK_DETAILS,
      payload: {
        name: 'taskDetails',
        value: data
      }
    });
  } catch (error) {
    dispatch({
      type: GET_TASK_DETAILS,
      payload: {
        name: 'taskDetails',
        value: 'cannot retrieve task details'
      }
    });
  }
};
