import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'toastr/build/toastr.css';
import { Provider } from 'react-redux';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store from 'store/reducers';
import './assets/styles/index.css';
import { setCurrentUser, setUserToken } from 'store/actions/auth/login';
import { getCurrentAdminDetails } from 'services/auth';

/**
 * @file ODA DASHBOARD DOCUMENTATION<br>
 * Oda is an e-commerce platform where buyers and sellers can make money<br>
 * Oda dashboard is built to keep track of activities and information about drivers, merchants, sales and products on the oda app.<br>
 * The dasboard currently has 7 modules. <b>The Orders Module, Store Module, Product Module, Delivery Module, Finance Module, Users Module and Settings Module.</br>
 * The Authentication module has four different components that handles different kind of authentication.
 * SignUp components handles registering a new user for the admin dashboard. Login Component handles Signing in an already registered user
 * ChangePassword component handles changing user password incase forgotten.
 * @author Paul Oluyege, Valentine Ezeh, Samuel Offem
 *
 */

const CurrentUserId = localStorage.getItem('currentUser');
const token = localStorage.getItem('token');

if (CurrentUserId) {
  store.dispatch(setCurrentUser(CurrentUserId));
  store.dispatch(setUserToken(token));
  store.dispatch(getCurrentAdminDetails(token));
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
