import styled from 'styled-components';

export const SearchWrapper = styled.div`
  width: 80%;
  border-right: 1px solid #c4c4c4;
  position: relative;

  .search-input {
    width: 90%;
    height: 94%;
    border: none;
    margin-left: 80px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    outline: none;
  }

  .icon-wrap {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .search-icon {
    margin-left: 40px;
  }
`;
