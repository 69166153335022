import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 86px;
  border-bottom: 1px solid #c4c4c4;

  .main-menu-wrap {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-flex {
    display: flex;
    align-items: center;
  }

  .user-name {
    margin-left: 20px;
    display: flex;
    margin-top: 33px;
  }

  .expand {
    cursor: pointer;
  }
  .dropDown {
    background: white;
    border: none;
  }
  .logOutIcon {
    height: 50px;
    font-family: 'Poppins';
  }
`;
