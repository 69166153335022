import AddImageCard from 'components/AddImageCard';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const AddProductImages = ({ getProductImages }) => {
  const [images, addImage] = useState([]);

  const readImg = img => {
    addImage(prev => [...prev, img]);
  };

  useEffect(() => {
    getProductImages(images);
  }, [images]);

  return (
    <Container>
      <AddImageCard readImgToParent={readImg} />
      <AddImageCard readImgToParent={readImg} />
      <AddImageCard readImgToParent={readImg} />
      <AddImageCard readImgToParent={readImg} />
      <AddImageCard readImgToParent={readImg} />
      <AddImageCard readImgToParent={readImg} />
      <AddImageCard readImgToParent={readImg} />
      <AddImageCard readImgToParent={readImg} />
    </Container>
  );
};

AddProductImages.propTypes = {
  getProductImages: PropTypes.func
};

const Container = styled.div`
  padding: 50px;
  display: grid;
  grid-template-columns: repeat(4, 250px);
  gap: 3rem;
  justify-content: center;
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

export default AddProductImages;
