// This Objection contains all the base url for the various micro-services
export const BASE_URL = {
  ADMIN: process.env.REACT_APP_ADMIN_SERVICE,
  USER: process.env.REACT_APP_USER_SERVICE,
  STORE: process.env.REACT_APP_STORE_SERVICE,
  ORDER: process.env.REACT_APP_ORDER_SERVICE,
  PRODUCT: process.env.REACT_APP_PRODUCT_SERVICE,
  TRANSACTION: process.env.REACT_APP_TRANSACTION_SERVICE,
  LOGISTICS: process.env.REACT_APP_LOGISTICS_SERVICE,
  SEARCH: process.env.REACT_APP_SEARCH_SERVICE
};

export const googleApiKey = 'AIzaSyBPCPnZmcjwWf2zWV9D3Ui-_CRprosxxb8';
