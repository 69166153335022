import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

import DetailNavBar from '../DetailNavBar/DetailNavBar';

import Notification from '../../../assets/images/notification.svg';
import { ReactComponent as Logout } from '../../../assets/svg/Logout.svg';
import { logOut } from '../../../services/auth';

import { HeaderWrapper } from './style';

const Header = props => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();

  const onLogOutUser = () => {
    dispatch(logOut(props));
  };

  const { adminName, history } = props;
  return (
    <HeaderWrapper>
      <DetailNavBar history={history} />
      <div className="main-menu-wrap">
        <div className="menu-flex">
          <img src={Notification} alt="icon" />
          <div className="user-name">
            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret color="white">
                {adminName || <Skeleton width={100} />}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => onLogOutUser()}>
                  <Logout className="mr-1 logOutIcon" />
                  Sign Out
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  adminName: PropTypes.string,
  history: PropTypes.any
};

const mapStateToProps = state => ({
  adminName: state.loginReducer.user.fullName
});

export default withRouter(connect(mapStateToProps, null)(Header));
