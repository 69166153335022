import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './style';
import Next from '../../../assets/images/next.svg';

const DetailNavBar = ({ history }) => {
  return (
    <Wrapper>
      <div className="parent"
        onClick={history.goBack}
        style={{ cursor: "pointer" }}
      >
        Order
      </div>
      <img className="arrow" src={Next} alt="next-icon" />
      <div className="current-page">Order Details</div>
    </Wrapper>
  );
};

DetailNavBar.propTypes = {
  history: PropTypes.any
}

export default DetailNavBar;
