import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Sidebar from '../components/Sidebar/Sidebar';

//styles imported
import { Container } from './style';

import dashboardRoutes, { moduleRoutes } from './routes';
import Header from '../components/Header/Header';

const switchRoutes = (
  <Switch>
    {// console.log(typeof dashboardRoutes)
    dashboardRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.to} key={key} />;

      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }

      if (prop.layout === '/store') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return prop;
    })}
  </Switch>
);

const switchModuleRoutes = (
  <Switch>
    {moduleRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.to} key={key} />;
      if (prop.layout === '/store') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.layout === '/order') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.layout === '/delivery') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.layout === '/finance') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.layout === '/products') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return prop;
    })}
  </Switch>
);

class Admin extends Component {
  render() {
    const { ...rest } = this.props;
    return (
      <Container className="admin-wrapper">
        <div className="sidebar-wrapper">
          <Sidebar menuList={dashboardRoutes} {...rest} />
        </div>
        <div className="content-wrapper">
          <Header />
          <div className="menu-content-wrapper">{switchRoutes}</div>
        </div>
      </Container>
    );
  }
}

class Store extends Component {
  render() {
    const { ...rest } = this.props;
    return (
      <Container className="admin-wrapper">
        <div className="sidebar-wrapper">
          <Sidebar menuList={dashboardRoutes} {...rest} />
        </div>
        <div className="content-wrapper">
          <Header removeSearchBar />
          <div className="menu-content-wrapper">{switchModuleRoutes}</div>
        </div>
      </Container>
    );
  }
}

class Order extends Component {
  render() {
    const { ...rest } = this.props;
    return (
      <Container className="admin-wrapper">
        <div className="sidebar-wrapper">
          <Sidebar menuList={dashboardRoutes} {...rest} />
        </div>
        <div className="content-wrapper">
          {/* <Header removeSearchBar={false} /> */}
          <div className="menu-content-wrapper">{switchModuleRoutes}</div>
        </div>
      </Container>
    );
  }
}

class Logistics extends Component {
  render() {
    const { ...rest } = this.props;
    return (
      <Container className="admin-wrapper">
        <div className="sidebar-wrapper">
          <Sidebar menuList={dashboardRoutes} {...rest} />
        </div>
        <div className="content-wrapper">
          <Header removeSearchBar />
          <div className="menu-content-wrapper">{switchModuleRoutes}</div>
        </div>
      </Container>
    );
  }
}

class Finance extends Component {
  render() {
    const { ...rest } = this.props;
    return (
      <Container className="admin-wrapper">
        <div className="sidebar-wrapper">
          <Sidebar menuList={dashboardRoutes} {...rest} />
        </div>
        <div className="content-wrapper">
          <Header removeSearchBar />
          <div className="menu-content-wrapper">{switchModuleRoutes}</div>
        </div>
      </Container>
    );
  }
}
class Products extends Component {
  render() {
    const { ...rest } = this.props;
    return (
      <Container className="admin-wrapper">
        <div className="sidebar-wrapper">
          <Sidebar menuList={dashboardRoutes} {...rest} />
        </div>
        <div className="content-wrapper">
          <Header removeSearchBar />
          <div className="menu-content-wrapper">{switchModuleRoutes}</div>
        </div>
      </Container>
    );
  }
}

export { Admin, Store, Order, Logistics, Finance, Products };
