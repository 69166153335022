import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { SidebarWrapper } from './style';

import Logo from '../../assets/images/ODA.svg';

import dashboard from '../../assets/images/dasboard-icons/dashboard.svg';

class Sidebar extends Component {
  activeRoute = routeName => {
    let re = /s$/;
    let { pathname } = this.props.location;
    let [a, b, exactRoute] = routeName.split('/');

    if (exactRoute) exactRoute = exactRoute.replace(re, '');

    return pathname.indexOf(routeName) > -1 || pathname.includes(exactRoute)
      ? true
      : false;
  };

  render() {
    const { menuList } = this.props;
    let adminRoles = JSON.parse(localStorage.getItem('adminDetails'));

    let menus;
    if (adminRoles.role === 'root') {
      menus = menuList.filter(menu => menu.path !== '/');
    } else {
      menus = menuList.filter(
        menu =>
          menu.userDepartment === adminRoles.userDepartment &&
          menu.role === adminRoles.role
      );
      menus = [menuList[0], ...menus, menuList[5]];
    }

    return (
      <SidebarWrapper>
        <div className="icon-wrapper">
          <img src={Logo} alt="logo" className="logo" />
        </div>

        <div className="menu-wrapper">
          {menus ? (
            menus.map((menu, index) => (
              <NavLink
                to={menu.layout + menu.path}
                className={classnames(
                  'menu-item',
                  this.activeRoute(`${menu.layout}${menu.path}`) &&
                    'active-link'
                )}
                key={index}
              >
                <img src={menu.icon} alt="icon" className="icon" />
                <div>{menu.name}</div>
              </NavLink>
            ))
          ) : (
            <div className="menu-item">
              <img src={dashboard} alt="icon" className="icon" />
              <div>Title</div>
            </div>
          )}
        </div>
      </SidebarWrapper>
    );
  }
}

Sidebar.propTypes = {
  location: PropTypes.any,
  menuList: PropTypes.any
};

export default Sidebar;
