import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SearchWrapper } from './style';
import SearchIcon from '../../assets/images/search.svg';
import { withRouter, useLocation } from 'react-router-dom';
import {
  getAllMerchantRequest,
  getAllDriverRequest
} from 'store/actions/users/users';
import {
  getAllStores,
  getAllAdminStoreRequest
} from 'store/actions/stores/stores';
import { fetchNewlySignDrivers } from 'store/actions/delivery/delivery';
import { openSearch } from 'store/actions/auth/login';

const Searchbar = props => {
  const [searchParams, setSearchParams] = useState('');

  const location = useLocation();
  const dispatch = useDispatch();
  const activeTab = localStorage.getItem('activeTab');
  const activeSubTab = localStorage.getItem('activeSubTab');

  const onSubmit = async () => {
    dispatch(openSearch(true));
    let paramCheck;
    if (searchParams.match(/^\d/)) paramCheck = true;
    const storeSearchType = paramCheck ? 'storeId' : 'storeName';
    const deliverySearchType = paramCheck ? 'driverId' : 'driverName';

    if (
      location.pathname === '/admin/delivery' &&
      activeTab === 'Drivers' &&
      activeSubTab === 'All Drivers'
    ) {
      const queryPayload = {
        query: 'approved',
        queryType: 'search',
        searchType: deliverySearchType,
        searchParams
      };
      await dispatch(fetchNewlySignDrivers(queryPayload));
    }

    if (location.pathname === '/admin/users' && activeTab === 'Merchants') {
      await dispatch(getAllMerchantRequest(searchParams));
    }
    if (location.pathname === '/admin/users' && activeTab === 'Drivers') {
      await dispatch(getAllDriverRequest(searchParams));
    }
    if (
      location.pathname === '/admin/stores' &&
      activeTab === 'All Stores' &&
      activeSubTab === 'Active Stores'
    ) {
      const queryPayload = {
        query: 'approved',
        queryType: 'search',
        searchType: storeSearchType,
        searchParams
      };
      await dispatch(getAllStores(queryPayload));
    }
    if (
      location.pathname === '/admin/stores' &&
      activeTab === 'All Stores' &&
      activeSubTab === 'Blocked Stores'
    ) {
      const queryPayload = {
        query: 'blocked',
        queryType: 'search',
        searchType: storeSearchType,
        searchParams
      };
      await dispatch(getAllStores(queryPayload));
    }
    if (
      location.pathname === '/admin/stores' &&
      activeTab === 'Approvals' &&
      activeSubTab === 'Approved'
    ) {
      const queryPayload = {
        query: 'approved',
        queryType: 'search',
        searchType: storeSearchType,
        searchParams
      };
      await dispatch(getAllStores(queryPayload));
    }
    if (
      location.pathname === '/admin/stores' &&
      activeTab === 'Approvals' &&
      activeSubTab === 'Disapproved'
    ) {
      const queryPayload = {
        query: 'disapproved',
        queryType: 'search',
        searchType: storeSearchType,
        searchParams
      };
      await dispatch(getAllStores(queryPayload));
    }
    if (
      location.pathname === '/admin/stores' &&
      activeTab === 'Approvals' &&
      activeSubTab === 'Pending'
    ) {
      const queryPayload = {
        query: 'pending',
        queryType: 'search',
        searchType: storeSearchType,
        searchParams
      };
      await dispatch(getAllStores(queryPayload));
    }
    if (
      location.pathname === '/admin/stores' &&
      activeTab === 'Request from Admin'
    ) {
      const queryPayload = {
        queryType: 'search',
        searchType: storeSearchType,
        searchParams
      };
      await dispatch(getAllAdminStoreRequest(queryPayload));
    }
  };

  return (
    <SearchWrapper>
      {location.pathname === '/admin/stores' ||
      location.pathname === '/admin/users' ||
      location.pathname === '/admin/orders' ||
      location.pathname === '/admin/delivery' ||
      location.pathname === '/admin/products' ? (
        <>
          <div className="icon-wrap" onClick={onSubmit}>
            <img
              src={SearchIcon}
              alt="icon"
              className="search-icon"
              style={{ cursor: 'pointer' }}
            />
          </div>
          <input
            type="text"
            placeholder="Search"
            className="search-input"
            name="search"
            value={searchParams}
            onChange={e => setSearchParams(e.target.value)}
          />
        </>
      ) : null}
    </SearchWrapper>
  );
};

export default withRouter(Searchbar);
