import {
  BLOCK_DELIVERY_REPS,
  BLOCK_DELIVERY_REPS_IS_LOADING,
  ALL_DRIVERS,
  ALL_DRIVERS_IS_LOADING,
  GET_DRIVERS_TASKS,
  GET_DRIVERS_TASKS_IS_LOADING,
  GET_NEWLY_SIGNUP_REPS,
  GET_NEWLY_SIGNUP_REPS_IS_LOADING,
  GET_A_DELIVERY_REP,
  GET_A_DELIVERY_REP_IS_LOADING,
  GET_DRIVER_DOCUMENT,
  GET_DRIVER_DOCUMENT_IS_LOADING,
  DRIVER_WALLET_DETAILS,
  DRIVER_WALLET_DETAILS_IS_LOADING,
  DRIVER_TRANSACTIONS,
  DRIVER_TRANSACTIONS_IS_LOADING,
  PENDING_NEWLY_SIGN_DRIVERS,
  PENDING_NEWLY_SIGN_DRIVERS_IS_LOADING,
  DRIVER_TASK_HISTORY,
  DRIVER_TASK_HISTORY_IS_LOADING,
  DRIVER_RATINGS,
  DRIVER_RATINGS_IS_LOADING,
  ACT_ON_DRIVER_IS_LOADING,
  ACT_ON_DRIVER,
  ACT_ON_DRIVER_ERROR,
  GET_DRIVER_BLOCK_REQUEST,
  GET_DRIVER_BLOCK_REQUEST_IS_LOADING,
  GET_DRIVER_TASK_COUNT,
  GET_DRIVER_TASK_COUNT_IS_LOADING,
  ADMIN_BLOCK_DELIVERY_REPS,
  ADMIN_BLOCK_DELIVERY_REPS_IS_LOADING,
  ADMIN_BLOCK_DELIVERY_REPS_ERROR,
  DELETE_ADMIN_BLOCK_DELIVERY_REPS_ERROR,
  POST_BLOCK_REQUEST,
  POST_BLOCK_REQUEST_IS_LOADING,
  POST_BLOCK_REQUEST_ERROR,
  DELETE_POST_BLOCK_REQUEST_ERROR,
  ACT_ON_BLOCK_REQUEST,
  ACT_ON_BLOCK_REQUEST_IS_LOADING,
  DELETE_DRIVER_DOCUMENT,
  DELETE_DRIVER_DOCUMENT_IS_LOADING,
  DELETE_DRIVER_DOCUMENT_ERROR,
  DELETE_DRIVER_DOCUMENT_ERROR_MESSAGE,
  GET_DRIVERS_COUNT,
  GET_TASK_MANAGEMENT_COUNT,
  GET_NEWLY_SIGNUP_COUNT,
  GET_TASK_DETAILS,
  GET_DELIVERY_REP_CURRENT_LOCATION,
} from 'store/actions/types';

const initialState = {
  blockedDrivers: {},
  blockedDriverIsLoading: false,
  allDrivers: {},
  allDriversIsLoading: false,
  allTasks: {},
  allTaskIsLoading: false,
  newlySignDrivers: {},
  newlySignDriversIsLoading: false,
  driverDetails: {},
  driverDetailsIsLoading: false,
  driverDocuments: {},
  driverDocumentsIsLoading: false,
  driverWalletDetails: {},
  driverWalletDetailsIsLoading: false,
  driverTransactions: {},
  driverTransactionsIsLoading: false,
  pendingNewlySignedDrivers: {},
  pendingNewlySignedDriversIsLoading: false,
  driverTaskHistory: {},
  driverTaskHistoryIsLoading: false,
  driverRatings: {},
  driverRatingIsLoading: false,
  approveDisapproveDriver: {},
  approveDisapproveDriverIsLoading: false,
  approveDisapproveDriverIsSuccessful: false,
  approveDisapproveDriverError: {},
  blockDriverRequest: {},
  blockDriverRequestIsLoading: false,
  driverCountTask: {},
  driverCountTaskIsLoading: false,
  adminBlockDetails: null,
  adminBlockDetailsIsLoading: false,
  adminBlockDetailsError: null,
  blockDriverIsSuccessful: false,
  sendBlockRequestDetails: null,
  sendBlockRequestDetailsIsLoading: false,
  sendBlockRequestIsSuccessful: false,
  sendBlockRequestDetailsError: null,
  actOnBlockRequestDetails: null,
  actOnBlockRequestDetailsIsLoading: false,
  deleteDriverDocumentIsLoading: false,
  deleteDriverDocumentErrorMessage: null,
  deleteDriverDocumentIsSuccessful: false,
  driversCount: {},
  taskManagementCount: {},
  newlySignupCount: {},
  taskDetails: {},
  delRepCurrentLocation: null
};

const deliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DELIVERY_REP_CURRENT_LOCATION:
      return {
        ...state,
        delRepCurrentLocation: action.delRepCurrentLocation
      }
    case BLOCK_DELIVERY_REPS:
      return {
        ...state,
        blockedDrivers: action.blockedDrivers,
        blockedDriverIsLoading: false
      };
    case BLOCK_DELIVERY_REPS_IS_LOADING:
      return {
        ...state,
        blockedDriverIsLoading: true
      };
    case ALL_DRIVERS:
      return {
        ...state,
        allDrivers: action.allDrivers,
        allDriversIsLoading: false
      };
    case ALL_DRIVERS_IS_LOADING:
      return {
        ...state,
        allDriversIsLoading: true
      };
    case GET_DRIVERS_TASKS:
      return {
        ...state,
        allTasks: action.taskData,
        allTaskIsLoading: false
      };
    case GET_DRIVERS_TASKS_IS_LOADING:
      return {
        ...state,
        allTaskIsLoading: true
      };
    case GET_NEWLY_SIGNUP_REPS:
      return {
        ...state,
        newlySignDrivers: action.driversData,
        newlySignDriversIsLoading: false
      };
    case PENDING_NEWLY_SIGN_DRIVERS:
      return {
        ...state,
        pendingNewlySignedDrivers: action.pendingDrivers,
        pendingNewlySignedDriversIsLoading: false
      };
    case GET_NEWLY_SIGNUP_REPS_IS_LOADING:
      return {
        ...state,
        newlySignDriversIsLoading: true
      };
    case PENDING_NEWLY_SIGN_DRIVERS_IS_LOADING:
      return {
        ...state,
        pendingNewlySignedDriversIsLoading: true
      };
    case GET_A_DELIVERY_REP:
      return {
        ...state,
        driverDetails: action.driverDetails,
        driverDetailsIsLoading: false
      };
    case GET_A_DELIVERY_REP_IS_LOADING:
      return {
        ...state,
        driverDetailsIsLoading: true
      };
    case GET_DRIVER_DOCUMENT:
      return {
        ...state,
        driverDocuments: action.driverDocuments,
        driverDocumentsIsLoading: false
      };
    case GET_DRIVER_DOCUMENT_IS_LOADING:
      return {
        ...state,
        driverDocumentsIsLoading: true
      };
    case DRIVER_WALLET_DETAILS:
      return {
        ...state,
        driverWalletDetails: action.driverWalletDetails,
        driverWalletDetailsIsLoading: false
      };
    case DRIVER_WALLET_DETAILS_IS_LOADING:
      return {
        ...state,
        driverWalletDetailsIsLoading: true
      };
    case DRIVER_TRANSACTIONS:
      return {
        ...state,
        driverTransactions: action.driverTransactions,
        driverTransactionsIsLoading: false
      };
    case DRIVER_TRANSACTIONS_IS_LOADING:
      return {
        ...state,
        driverTransactionsIsLoading: true
      };
    case DRIVER_TASK_HISTORY:
      return {
        ...state,
        driverTaskHistory: action.taskHistory,
        driverTaskHistoryIsLoading: false
      };
    case DRIVER_TASK_HISTORY_IS_LOADING:
      return {
        ...state,
        driverTaskHistoryIsLoading: true
      };
    case DRIVER_RATINGS:
      return {
        ...state,
        driverRatings: action.driverRatings,
        driverRatingIsLoading: false
      };
    case DRIVER_RATINGS_IS_LOADING:
      return {
        ...state,
        driverRatingIsLoading: true
      };
    case ACT_ON_DRIVER:
      return {
        ...state,
        approveDisapproveDriver: action.actedDriverData,
        approveDisapproveDriverIsLoading: false,
        approveDisapproveDriverIsSuccessful: true
      };
    case ACT_ON_DRIVER_IS_LOADING:
      return {
        ...state,
        approveDisapproveDriverIsLoading: true
      };
    case ACT_ON_DRIVER_ERROR:
      return {
        ...state,
        approveDisapproveDriverIsLoading: false,
        approveDisapproveDriverError: action.actedDriverDataError,
        approveDisapproveDriverIsSuccessful: false
      };
    case GET_DRIVER_BLOCK_REQUEST:
      return {
        ...state,
        blockDriverRequestIsLoading: false,
        blockDriverRequest: action.blockedDrivers
      };
    case GET_DRIVER_BLOCK_REQUEST_IS_LOADING:
      return {
        ...state,
        blockDriverRequestIsLoading: true
      };
    case GET_DRIVER_TASK_COUNT:
      return {
        ...state,
        driverCountTask: action.taskCount,
        driverCountTaskIsLoading: false
      };
    case GET_DRIVER_TASK_COUNT_IS_LOADING:
      return {
        ...state,
        driverCountTaskIsLoading: true
      };
    case ADMIN_BLOCK_DELIVERY_REPS:
      return {
        ...state,
        adminBlockDetails: action.blockDetails,
        adminBlockDetailsIsLoading: false,
        blockDriverIsSuccessful: true
      };
    case ADMIN_BLOCK_DELIVERY_REPS_IS_LOADING:
      return {
        ...state,
        adminBlockDetailsIsLoading: true,
        blockDriverIsSuccessful: false
      };
    case ADMIN_BLOCK_DELIVERY_REPS_ERROR:
      return {
        ...state,
        adminBlockDetailsError: action.blockDetailsError,
        adminBlockDetailsIsLoading: false,
        blockDriverIsSuccessful: false
      };
    case DELETE_ADMIN_BLOCK_DELIVERY_REPS_ERROR:
      return {
        ...state,
        adminBlockDetailsError: null,
        blockDriverIsSuccessful: false
      };
    case POST_BLOCK_REQUEST:
      return {
        ...state,
        sendBlockRequestDetails: action.blockRequestDetails,
        sendBlockRequestDetailsIsLoading: false,
        sendBlockRequestIsSuccessful: true
      };
    case POST_BLOCK_REQUEST_IS_LOADING:
      return {
        ...state,
        sendBlockRequestDetailsIsLoading: true
      };
    case POST_BLOCK_REQUEST_ERROR:
      return {
        ...state,
        sendBlockRequestDetailsIsLoading: false,
        sendBlockRequestIsSuccessful: false,
        sendBlockRequestDetailsError: action.blockRequestError
      };
    case DELETE_POST_BLOCK_REQUEST_ERROR:
      return {
        ...state,
        sendBlockRequestDetailsError: null
      };
    case ACT_ON_BLOCK_REQUEST:
      return {
        ...state,
        actOnBlockRequestDetails: action.actOnBlockResponse,
        actOnBlockRequestDetailsIsLoading: false
      };
    case ACT_ON_BLOCK_REQUEST_IS_LOADING:
      return {
        ...state,
        actOnBlockRequestDetailsIsLoading: true
      };
    case DELETE_DRIVER_DOCUMENT:
      return {
        ...state,
        deleteDriverDocumentIsSuccessful: true
      };
    case DELETE_DRIVER_DOCUMENT_IS_LOADING:
      return {
        ...state,
        deleteDriverDocumentIsLoading: true,
        deleteDriverDocumentIsSuccessful: false
      };
    case DELETE_DRIVER_DOCUMENT_ERROR:
      return {
        ...state,
        deleteDriverDocumentIsLoading: false,
        deleteDriverDocumentErrorMessage: action.delDocumentError,
        deleteDriverDocumentIsSuccessful: false
      };
    case DELETE_DRIVER_DOCUMENT_ERROR_MESSAGE:
      return {
        ...state,
        deleteDriverDocumentErrorMessage: null,
        deleteDriverDocumentIsLoading: false,
        deleteDriverDocumentIsSuccessful: false
      };
    case GET_DRIVERS_COUNT:
      return {
        ...state,
        driversCount: {
          ...state.driversCount,
          [action.payload.name]: action.payload.value
        }
      };
    case GET_TASK_MANAGEMENT_COUNT: {
      const { all, pending, accepted, completed, cancelled } = action.payload;

      return {
        ...state,
        taskManagementCount: {
          all,
          pending,
          accepted,
          completed,
          cancelled
        }
      };
    }

    case GET_NEWLY_SIGNUP_COUNT: {
      const { approved, disapproved, pending } = action.payload;

      return {
        ...state,
        newlySignupCount: {
          approved,
          disapproved,
          pending
        }
      };
    }
    case GET_TASK_DETAILS: {
      return {
        ...state,
        taskDetails: {
          ...state.taskDetails,
          [action.payload.name]: action.payload.value
        }
      };
    }
    default:
      return state;
  }
};

export default deliveryReducer;
