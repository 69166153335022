import isEmpty from 'is-empty';
import {
  SIGN_UP_SUCCESS,
  SIGN_UP_ERRORS,
  SIGN_UP_IS_LOADING,
  DELETE_SIGN_UP_ERROR_MESSAGE,
  VERIFY_INVITATION_LINK_SUCCESS,
  VERIFY_INVITATION_LINK_ERROR,
  GET_CURRENT_ADMIN_USER,
} from '../../actions/types';

const initialState = {
  isAuthenticated: false,
  signUpUser: {},
  error: null,
  isLoading: false,
  isNotInvited: true,
  isInvitedError: null,
  invitedUserDetails: {},
};

const signUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.user),
        signUpUser: action.user,
        isLoading: false
      };
      case GET_CURRENT_ADMIN_USER:
      return {
        ...state,
        signUpUser: action.adminDetails
      }
    case SIGN_UP_ERRORS:
      return {
        ...state,
        isAuthenticated: false,
        signUpUser: {},
        error: action.error,
        isLoading: false
      };
    case DELETE_SIGN_UP_ERROR_MESSAGE:
      return {
        error: null
      };
    case SIGN_UP_IS_LOADING:
    return {
      ...state,
      isLoading: true
    };
    case VERIFY_INVITATION_LINK_SUCCESS:
      return {
        ...state,
        isNotInvited: isEmpty(action.inviteSuccess),
        isInvitedError: null,
        invitedUserDetails: action.inviteSuccess
      }
    case VERIFY_INVITATION_LINK_ERROR:
      return {
        ...state,
        isNotInvited: true,
        isInvitedError: action.inviteError,
        invitedUserDetails: {}
      }
    default:
      return state;
  }
}

export default signUpReducer;