// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  Col,
  Input,
  Row
} from 'reactstrap';
import datetime from 'node-datetime';
import Calendar from 'react-calendar';
import { useDispatch } from 'react-redux';
import {
  getAllStores,
  getAllAdminStoreRequest
} from 'store/actions/stores/stores';
import { ReactComponent as FilterIcon } from '../../assets/svg/filter.svg';
import '../../assets/styles/tab.css';
import 'react-calendar/dist/Calendar.css';
import { withRouter } from 'react-router-dom';
import isEmpty from 'is-empty';
import { getOrdersRequest } from 'store/actions/orders/orders';
import {
  getAllDriverRequest,
  getAllMerchantRequest
} from 'store/actions/users/users';
import {
  fetchDriverTasks,
  fetchNewlySignDrivers,
  fetchBlockedDrivers
} from 'store/actions/delivery/delivery';
import {
  getOrderTransactions,
  getProductSold,
  getRideTransactions,
  getAllwalletsForMerchants,
  getAllwalletsForDrivers
} from 'store/actions/finance/finance';

const Filter = props => {
  const {
    dropDownItems,
    subFilterItems,
    activeSubTab,
    activeTab,
    setDateFilters
  } = props;
  const [dropdownOpen, setOpen] = useState(false);
  const [dropdownOpenSecond, setOpenSecond] = useState(false);
  const [i, setI] = useState('');
  const [activeFilter, setActiveFilter] = useState('');
  const [activeSubFilterItem, setActiveSubFilterItem] = useState('');
  const [value, setSpecificDateValue] = useState('');
  const [mainDate, setMainDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [reformatStartDate, setReformatStartDate] = useState('');
  const [reformatEndDate, setReformatEndDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showStartDateCalendar, setShowStartDateCalendar] = useState(false);
  const [showEndDateCalendar, setShowEndDateCalendar] = useState(false);
  const [customDateError, setCustomDateError] = useState('');
  const [onCalendar, setOnCalendar] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();

  const onDrop = i => {
    if (activeFilter !== i) setActiveFilter(i);
    setOpenSecond(!dropdownOpenSecond);
    setI(i);
  };

  const onDropSubFilterItems = i => {
    setActiveSubFilterItem(i);
  };

  //const activeTab = localStorage.getItem('activeTab');

  const hideCalendar = () => {
    setOnCalendar(!onCalendar);
  };

  const onShowCalendarStartDate = () => {
    setShowStartDateCalendar(!showStartDateCalendar);
    setShowEndDateCalendar(false);
  };

  const onShowCalendarEndDate = () => {
    setShowEndDateCalendar(!showEndDateCalendar);
    setShowStartDateCalendar(false);
    if (isEmpty(reformatStartDate)) {
      setCustomDateError('Kindly choose a start date');
    } else {
      setCustomDateError('');
      return null;
    }
  };

  // if (activeSubFilterItem === 'All') {
  //   let keysToRemove = ['specificDate', 'startDate', 'endDate'];
  //   keysToRemove.forEach(k => localStorage.removeItem(k));
  // }
  const [tempCustomDate, setTempCustomDate] = useState({
    startDate: '',
    endDate: ''
  });
  const handleSpecificDay = e => {
    const date = new Date(e);
    if (setDateFilters) {
      setDateFilters({
        startDate: '',
        endDate: '',
        specificDate: `${date.getFullYear()}-${date.getMonth() +
          1}-${date.getDate()}`
      });
      setTempCustomDate({
        startDate: '',
        endDate: ''
      });
    }
  };

  const handleStartDate = e => {
    const date = new Date(e);
    setTempCustomDate(prev => {
      return {
        ...prev,
        startDate: `${date.getFullYear()}-${date.getMonth() +
          1}-${date.getDate()}`
      };
    });
  };
  const handleEndDate = e => {
    const date = new Date(e);
    setTempCustomDate(prev => {
      return {
        ...prev,
        endDate: `${date.getFullYear()}-${date.getMonth() +
          1}-${date.getDate()}`
      };
    });
  };

  useEffect(() => {
    if (tempCustomDate.startDate !== '' && tempCustomDate.endDate !== '') {
      if (setDateFilters) {
        setDateFilters({
          specificDate: '',
          startDate: tempCustomDate.startDate,
          endDate: tempCustomDate.endDate
        });
      }
    }
  }, [tempCustomDate]);

  useEffect(() => {
    setTempCustomDate({
      startDate: '',
      endDate: ''
    });
    setI('');
    setActiveFilter('');
    setActiveSubFilterItem('');
  }, [activeTab, activeSubTab]);

  useEffect(() => {
    if (activeSubFilterItem === 'All' && setDateFilters) {
      setDateFilters({
        specificDate: '',
        startDate: '',
        endDate: ''
      });
    }
    if (activeSubFilterItem === 'Today Date' && setDateFilters) {
      let date = new Date();
      setDateFilters({
        startDate: '',
        endDate: '',
        specificDate: `${date.getFullYear()}-${date.getMonth() +
          1}-${date.getDate()}`
      });
    }
  }, [activeSubFilterItem]);

  return (
    <>
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className="ml-2">
        <DropdownToggle className="filter-icon-wrap" color="white">
          <FilterIcon />
          <span className="filterText ml-2">Filter</span>
        </DropdownToggle>
        <div style={{ position: 'absolute', right: '150px' }}>
          <DropdownMenu>
            {dropDownItems.map((item, index) => {
              return (
                <div key={index}>
                  <Col
                    className={`filterDropDownItems mb-3 mt-2 ${activeFilter ===
                      item && 'filterDropDownItemsActive'}`}
                    onClick={() => onDrop(item)}
                  >
                    {item}
                  </Col>
                  {dropdownOpenSecond && item === i
                    ? subFilterItems[item].map((entry, index) => {
                        return (
                          <div className="mt-3 mb-3 ml-3 h-100" key={index}>
                            <span
                              className={`filterSubItems ${activeSubFilterItem ===
                                entry && 'filterSubItemsActive'}`}
                              onClick={() => onDropSubFilterItems(entry)}
                            >
                              {entry}

                              {activeSubFilterItem === 'Custom Date' &&
                                entry === 'Custom Date' && (
                                  <Row>
                                    <Col
                                      className="custom-date"
                                      onClick={onShowCalendarStartDate}
                                    >
                                      <Input
                                        className="custom-date-input"
                                        placeholder="Start Date"
                                        //onChange={onFilterCustomStartDate}
                                        value={tempCustomDate.startDate}
                                        disabled={true}
                                      />
                                    </Col>
                                    <Col
                                      className="mr-3 custom-date"
                                      onClick={onShowCalendarEndDate}
                                    >
                                      <Input
                                        className="custom-date-input"
                                        placeholder="End Date"
                                        //onChange={onFilterCustomEndDate}
                                        value={tempCustomDate.endDate}
                                        disabled={true}
                                      />
                                    </Col>
                                  </Row>
                                )}
                            </span>
                          </div>
                        );
                      })
                    : null}
                </div>
              );
            })}
            {i === 'Date' && activeSubFilterItem === 'Specific Date' && (
              <Col className="custom-date" onClick={hideCalendar}>
                <Input
                  placeholder="Select Date"
                  className="custom-date-input"
                  //onChange={onFilterSpecificDate}
                  value={mainDate}
                  disabled={true}
                />
              </Col>
            )}
            {onCalendar &&
              i === 'Date' &&
              activeSubFilterItem === 'Specific Date' && (
                <div className="filter-calendar">
                  {/* <Calendar onChange={onFilterSpecificDate} value={value} /> */}
                  <Calendar onChange={handleSpecificDay} />
                </div>
              )}
            {i === 'Date' &&
              activeSubFilterItem === 'Custom Date' &&
              showStartDateCalendar && (
                <div className="filter-calendar">
                  {/* <Calendar
                    onChange={onFilterCustomStartDate}
                    value={startDate}
                  /> */}
                  <Calendar
                    onChange={handleStartDate}
                    //value={startDate}
                  />
                </div>
              )}
            {i === 'Date' &&
              activeSubFilterItem === 'Custom Date' &&
              showEndDateCalendar && (
                <div className="filter-calendar">
                  {/* <Calendar onChange={onFilterCustomEndDate} value={endDate} /> */}
                  <Calendar onChange={handleEndDate} />
                </div>
              )}
          </DropdownMenu>
        </div>
      </ButtonDropdown>
    </>
  );
};

Filter.propTypes = {
  dropDownItems: PropTypes.array,
  subFilterItems: PropTypes.object,
  activeSubTab: PropTypes.string,
  setDateFilters: PropTypes.func,
  activeTab: PropTypes.string
};

export default withRouter(Filter);
